import { isString } from 'lodash';
import React from 'react';
import { useFormatters } from './useFormatters';

const DEFAULT_FN = item => item;

export const useFormatterFunction = () => {
  const formatters = useFormatters();

  return React.useCallback(
    path => {
      switch (typeof path.formatter) {
        case 'function':
          return path?.formatter;
        case 'object': {
          const fn = path?.formatter?.fn || DEFAULT_FN;
          return isString(fn) ? formatters[fn] : fn;
        }
        case 'string':
          return formatters[path?.formatter];
        default:
          return DEFAULT_FN;
      }
    },
    [formatters]
  );
};
