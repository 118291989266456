import React from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useLocalizationContext } from '../LocalizationProvider';

export const Currency = ({ value, currency }) => <FormattedNumber value={value} style="currency" currency={currency} />;

export const useCurrency = () => {
  const { formatNumber } = useIntl();
  const { userLocale } = useLocalizationContext();

  const getCurrencyParts = React.useCallback(
    ({ currency, locale }) => {
      const value = 1234.56;
      const numberFormat = new Intl.NumberFormat(locale || userLocale, { style: 'currency', currency });

      const parts = numberFormat.formatToParts(value);

      const partsWeCareAbout = {
        currency: 'currencySymbol',
        group: 'thousandsSeparator',
        decimal: 'decimalSeparator',
      };

      return parts.reduce((acc, part) => {
        const partType = partsWeCareAbout[part.type];
        if (partType) {
          acc[partType] = part.value;
        }
        return acc;
      }, {});
    },
    [userLocale]
  );

  // TODO: currency should come from entity settings.
  const getCurrency = React.useCallback(
    (value, currency) => formatNumber(value, { style: 'currency', currency }),
    [formatNumber]
  );

  return { Currency, getCurrency, getCurrencyParts };
};
