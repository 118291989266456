import { Drawer as AntDrawer } from 'antd';
import React, { useContext } from 'react';
import { Translate } from '../localization';

const DrawerContext = React.createContext();

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error(`useDrawer cannot be rendered outside of the Drawer context provider`);
  }
  return context;
};

const defaultCloseIcon = (
  <span role="img" aria-label="close" className="anticon anticon-close" data-testid="close-drawer-icon">
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="close"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
    </svg>
  </span>
);

const Drawer = () => {
  const {
    visible,
    title,
    titleStringId,
    width,
    placement,
    onClose,
    component,
    closeDrawer,
    closable,
    closeIcon = defaultCloseIcon,
  } = useDrawerContext();

  const handleClose = React.useCallback(() => {
    closeDrawer();
    onClose?.();
  }, [closeDrawer, onClose]);

  return (
    <AntDrawer
      visible={visible}
      title={title}
      placement={placement}
      onClose={handleClose}
      width={width}
      closable={closable}
      destroyOnClose
      getContainer={false}
      closeIcon={closeIcon}
    >
      {component}
    </AntDrawer>
  );
};

export const DrawerProvider = ({ children }) => {
  const [visible, setVisible] = React.useState(false);
  const [titleStringId, setTitleStringId] = React.useState(null);
  const [placement, setPlacement] = React.useState('right');
  const [onClose, setOnClose] = React.useState(null);
  const [component, setComponent] = React.useState(null);
  const [width, setWidth] = React.useState(null);
  const [closable, setCloseable] = React.useState(true);
  const [getContainer, setGetContainer] = React.useState(undefined);

  const title = React.useMemo(() => {
    if (!titleStringId) return undefined;

    if (titleStringId.stringId && titleStringId.values) {
      return <Translate stringId={titleStringId.stringId} values={titleStringId.values} />;
    }

    return <Translate stringId={titleStringId} />;
  }, [titleStringId]);

  const openDrawer = React.useCallback(
    ({
      titleStringId,
      onClose,
      placement = 'right',
      component,
      width = 500,
      closable = true,
      getContainer = undefined,
    }) => {
      setTitleStringId(titleStringId);
      setOnClose(() => onClose);
      setPlacement(placement);
      setVisible(true);
      setComponent(() => component);
      setWidth(width);
      setCloseable(closable);
      setGetContainer(getContainer);
    },
    []
  );

  const closeDrawer = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <DrawerContext.Provider
      value={{ visible, title, titleStringId, placement, onClose, component, openDrawer, closeDrawer, width, closable }}
    >
      {children}
      <Drawer />
    </DrawerContext.Provider>
  );
};
