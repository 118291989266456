import { css } from 'styled-components';

export default css`
  &&& {
    width: 100%;
    transition: all 0.3s;
    ${({ $multiline }) => ($multiline ? '' : 'height: 35px')};
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;

    background-color: var(--color-background-input);
    color: var(--color-text-input);
    border-color: var(--color-border-input);

    &:focus {
      border-color: var(--color-border-input-focus);
    }

    &.input-disabled {
      background-color: var(--color-background-input-disabled);
      color: var(--color-text-input-disabled);
      border-color: var(--color-border-input-disabled);
    }

    &.number-input-wrapper {
      width: 100%;
      transition: all 0.3s;
      display: flex;
      .number-display {
        outline: none;
        width: 100%;
        padding: 4px 11px;
        border-radius: 4px;
        height: 35px;
        cursor: pointer;
        border: 1px solid var(--color-border-input);
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        //line-height: 16.41px;

        background-color: var(--color-background-input);
        color: var(--color-text-input);

        &.disabled {
          color: var(--color-text-select-disabled);
          border-color: var(--color-border-select-disabled);
          background-color: var(--color-background-select-disabled);
          cursor: not-allowed;     
        }
      }
      //.number-input {
      //  outline: none;
      //  width: 100%;
      //  padding: 4px 11px;
      //  border-radius: 4px;
      //  height: 35px;
      //
      //  border: 1px solid var(--color-border-input);
      //
      //  font-size: 14px;
      //  font-weight: 400;
      //  //line-height: 16.41px;
      //
      //  background-color: var(--color-background-input);
      //  color: var(--color-text-input);
      //}

      &.addon {
        .number-display {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .number-input-addon {
        border: 1px solid;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0 11px;
        height: 35px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        transition: all 0.3s;
        background-color: var(--color-background-input);
        border-color: var(--color-border-input);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
        &:focus {
          border-color: var(--color-border-input-focus);
        }
        &.disabled {
          color: var(--color-text-select-disabled);
          border-color: var(--color-border-select-disabled);
        }
      }
    }
  }
`;
