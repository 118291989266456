// French
export default {
  newVersionAvailable: 'Nouvelle version disponible',
  versionInfo: 'Une nouvelle version de {appName} est disponible. Veuillez recharger la page pour mettre à jour vers la version {version}.',
  reload: 'Recharger',
  later: 'Plus tard',
  intl_addr_country: 'Pays',
  intl_addr_address1: 'Adresse',
  intl_addr_address2: 'Adresse 2',
  intl_addr_address3: 'Adresse 3',
  intl_addr_address4: 'Adresse 4',
  intl_addr_city: 'Ville',
  intl_addr_state: 'État',
  intl_addr_zipCode: 'Code postal',
  intl_addr_longitude: 'Longitude',
  intl_addr_latitude: 'Latitude',
  intl_addr_department: 'Département',
  intl_addr_suburb: 'Banlieue',
  intl_addr_postalCode: 'Code postal',
  intl_addr_province: 'Province',
  intl_addr_neighborhood: 'Quartier',
};
