import cn from 'classnames';
import { isFunction } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Column } from '../../layout';
import { useListAndDetailContext } from './ListAndDetailProvider';

const Styled = styled(Column)``;

export const Detail = ({ onFinally }) => {
  const navigate = useNavigate();
  const { Editor, updateQuery, createQuery, deleteQuery, ...context } = useListAndDetailContext();

  if (!updateQuery || !createQuery) {
    console.error('updateQuery and createQuery are required.');
  }

  const handleFinally = React.useCallback(() => {
    if (isFunction(onFinally)) {
      onFinally();
    } else {
      navigate('..');
    }
  }, [navigate, onFinally]);

  return (
    <Styled className={cn('detail')}>
      <Editor
        updateQuery={updateQuery}
        createQuery={createQuery}
        deleteQuery={deleteQuery}
        onFinally={handleFinally}
        context={context}
      />
    </Styled>
  );
};
