export default {
  intl_addr_country: 'Kraj',
  intl_addr_address1: 'Adres',
  intl_addr_address2: 'Adres 2',
  intl_addr_address3: 'Adres 3',
  intl_addr_address4: 'Adres 4',
  intl_addr_city: 'Miasto',
  intl_addr_state: 'Stan',
  intl_addr_zipCode: 'Kod pocztowy',
  intl_addr_longitude: 'Długość geograficzna',
  intl_addr_latitude: 'Szerokość geograficzna',
  intl_addr_department: 'Dział',
  intl_addr_suburb: 'Przedmieście',
  intl_addr_postalCode: 'Kod pocztowy',
  intl_addr_province: 'Prowincja',
  intl_addr_neighborhood: 'Sąsiedztwo',
};
