import React, { useRef } from 'react';
import { InputAdornment } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { normalizeText } from './searchFilter.helpers';

import { SearchBarStyled, SearchContainer } from './styles';
import { Translate, useTranslateMessage } from '../../localization';

const SearchFilter = ({ onSearchChange, querySearch, disabled, resultsCount, fullWidth }) => {
  const searchRef = useRef(null);
  const translateMessage = useTranslateMessage();
  const handleInputChange = e => {
    const inputValue = e.target?.value;
    if (inputValue.length <= 50) {
      onSearchChange(normalizeText(inputValue));
    }
  };

  const handleInputClear = () => onSearchChange('');

  return (
    <SearchContainer>
      <SearchBarStyled
        inputRef={searchRef}
        fullWidth={fullWidth}
        variant="outlined"
        placeholder={translateMessage('activeUser_searchFilterPlaceholder')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search onClick={() => searchRef.current.focus()} sx={{ fontSize: 24 }} />
            </InputAdornment>
          ),
          endAdornment: querySearch && (
            <InputAdornment position="end">
              <Close onClick={handleInputClear} sx={{ fontSize: 24, cursor: 'pointer' }} />
            </InputAdornment>
          ),
        }}
        inputProps={{
          'data-testid': 'search-bar',
        }}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            searchRef.current.blur();
          }
        }}
        disabled={disabled}
        onChange={handleInputChange}
        value={querySearch}
      />
      {resultsCount ? (
        <Translate
          stringId="searchFilter_resultsCount"
          data-testid="row-count"
          values={{
            count: resultsCount,
          }}
        />
      ) : null}
    </SearchContainer>
  );
};

export default SearchFilter;
