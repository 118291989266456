/**
 *
 * @type {{font_weight: string, font_size_small: string, font_weight_bold: string, radius_small: string, font_size: string, font_weight_light: string, radius_large: string, font_size_large: string, color_1: string, color_2: string}}
 */
export const common = {
  pure_white: '#FFFFFF',
  pure_black: '#000000',

  color_1: '#3D3D3D',
  color_2: '#FDFDFD',

  radius_small: '4px',
  radius_large: '8px',

  font_size_small: '12px',
  font_size: '16px',
  font_size_large: '20px',
  font_size_x_large: '24px',
  font_size_xx_large: '32px',

  font_weight_bold: '700',
  font_weight: '400',
  font_weight_light: '300',
};
