import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import mutuallyExclusive from '../../../util/validation/mutuallyExclusive';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

export const DayOfWeek = ({ date, long = false, short = false }) => {
  mutuallyExclusive({ long, short });

  const { timeZone } = useLocalizationContext();

  const dateTime = React.useMemo(() => toDateTime(date, timeZone), [date, timeZone]);

  return <FormattedDate value={dateTime} weekday={long ? 'long' : 'short'} timeZone={timeZone} />;
};

export const useDayOfWeek = () => {
  const { formatDate } = useIntl();
  const { timeZone } = useLocalizationContext();

  const getDayOfWeek = React.useCallback(
    (date, long = false) => {
      const dateTime = toDateTime(date, timeZone);
      return formatDate(dateTime.toISO(), { timeZone, weekday: long ? 'long' : 'short' });
    },
    [formatDate, timeZone]
  );

  return { getDayOfWeek, DayOfWeek };
};
