import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../components/user';
import { useEntityContext } from '../../util/entity-provider/context';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { useAppData } from '../app-data/AppData';
import { usePostMessageListener } from '../entity-provider/usePostMessageListener';
import { getPermittedMenuConfig } from '../getPermittedMenuConfig';
import { AppContext } from './context';
import { API } from '../../components/api';
import { Spinner } from '../../components/core';
import { useMediaQuery } from '@mui/material';

const reducer = (state, action) =>
  Object.keys(action).reduce(
    (acc, key) => {
      acc[key] = action[key];
      return acc;
    },
    { ...state }
  );

const AppContextProvider = ({ children }) => {
  const [currentFeature, setCurrentFeature] = React.useState();
  const [state, dispatch] = React.useReducer(reducer, {});
  const { appRef, appConfig } = state;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  useState(() => {
    setIsSidebarOpen(isMobile ? isSidebarOpen : false);
  }, [isMobile]);

  const isEntityPickerRequired = React.useMemo(() => {
    return appRef && appConfig?.isEntitySpecific === true;
  }, [appConfig, appRef]);

  const appConfigListener = React.useCallback(message => {
    const { type, ...rest } = message;
    if (type === 'app-config') {
      dispatch({ appRef: message?.appConfig?.crn, ...rest });
    }
  }, []);

  usePostMessageListener(appConfigListener);

  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    sendMessage({ type: 'req-app-config' });
  }, [sendMessage]);

  const setAppEntities = React.useCallback(value => {
    dispatch({ appEntities: value });
  }, []);

  const setHeaderText = React.useCallback(value => {
    dispatch({ headerText: value });
  }, []);

  const { menuConfig, appId } = useAppData();
  const userContext = useUserContext();
  const entityContext = useEntityContext();
  const permittedMenuConfig = React.useMemo(() => {
    // Temporary workaround to support both menuConfig shapes until everyone has changed to the new format.
    return getPermittedMenuConfig({ menuConfig: menuConfig.sidebarOptions || menuConfig, userContext, entityContext });
  }, [menuConfig, userContext, entityContext]);
  const [appEntityToken, setAppEntityToken] = React.useState(API.getAppEntityToken());

  useEffect(() => {
    const listen = () => {
      API.addListener(event => {
        setAppEntityToken(API.getAppEntityToken());
      });
    };
    API.addListener(listen);
    return () => {
      API.removeListener(listen);
    };
  }, []);

  if (appId !== 'mt' && isEntityPickerRequired === undefined) {
    return null;
  }

  return (
    <AppContext.Provider
      value={{
        setAppEntities,
        isEntityPickerRequired,
        menuConfig: permittedMenuConfig,
        currentFeature,
        setCurrentFeature,
        setHeaderText,
        isSidebarOpen,
        toggleSidebar,
        ...state,
      }}
    >
      <Spinner spin={!appEntityToken && isEntityPickerRequired}>{children}</Spinner>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
