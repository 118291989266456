import React from 'react';

export const useEscape = (isOpen, handler) => {
  React.useEffect(() => {
    const handleEvent = e => {
      if (isOpen && e.key === 'Escape') {
        handler();
      }
    };
    window.addEventListener('keydown', handleEvent);
    return () => {
      window.removeEventListener('keydown', handleEvent);
    };
  }, [handler, isOpen]);
};
