import { get } from 'lodash';
import React from 'react';

export const useSelection = ({ config, options, value }) => {
  const [selectedItem, setSelectedItem] = React.useState();

  const path = config?.[0]?.path;

  const handleSelectedItemChange = React.useCallback(({ option }) => {
    setSelectedItem(option);
  }, []);

  const selectedItems = React.useMemo(() => {
    return get(value, path, []);
  }, [path, value]);

  return { handleSelectedItemChange, selectedItem, selectedItems };
};
