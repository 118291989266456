import colors from '../colors';

const background = {
  light: {
    1: `linear-gradient(0deg, rgba(103, 80, 164, 0.05) 0%, rgba(103, 80, 164, 0.05)), ${colors.neutral[99]}`,
    2: `linear-gradient(0deg, rgba(103, 80, 164, 0.08) 0%, rgba(103, 80, 164, 0.08) 100%), ${colors.neutral[99]}`,
    3: `linear-gradient(0deg, rgba(103, 80, 164, 0.11) 0%, rgba(103, 80, 164, 0.11) 100%), ${colors.neutral[99]}`,
    4: `linear-gradient(0deg, rgba(103, 80, 164, 0.12) 0%, rgba(103, 80, 164, 0.12) 100%), ${colors.neutral[99]}`,
    5: `linear-gradient(0deg, rgba(103, 80, 164, 0.14) 0%, rgba(103, 80, 164, 0.14) 100%), ${colors.neutral[99]}`,
    6: `linear-gradient(0deg, rgba(103, 80, 164, 0.14) 0%, rgba(103, 80, 164, 0.14) 100%), ${colors.neutral[99]}`,
    tableHF: '#F8FAFD',
  },
  dark: {
    1: `linear-gradient(0deg, rgba(208, 188, 255, 0.05) 0%, rgba(208, 188, 255, 0.05) 100%), ${colors.neutral[10]}`,
    2: `linear-gradient(0deg, rgba(208, 188, 255, 0.08) 0%, rgba(208, 188, 255, 0.08) 100%), ${colors.neutral[10]}`,
    3: `linear-gradient(0deg, rgba(208, 188, 255, 0.11) 0%, rgba(208, 188, 255, 0.11) 100%), ${colors.neutral[10]}`,
    4: `linear-gradient(0deg, rgba(208, 188, 255, 0.12) 0%, rgba(208, 188, 255, 0.12) 100%), ${colors.neutral[10]}`,
    5: `linear-gradient(0deg, rgba(208, 188, 255, 0.14) 0%, rgba(208, 188, 255, 0.14) 100%), ${colors.neutral[10]}`,
    6: `linear-gradient(0deg, rgba(208, 188, 255, 0.20) 0%, rgba(208, 188, 255, 0.20) 100%), ${colors.neutral[10]}`,
    tableHF: colors.neutral[30],
  },
};

export default background;
