import React from 'react';
import { Drawer, Form } from 'connex-cds';
import { View } from './View';

export const InvitedUsers = () => {
  return (
    <Drawer.DrawerProvider>
      <View />
    </Drawer.DrawerProvider>
  );
};
