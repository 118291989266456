export const FIELD_TYPES = {
  string: 'string',
  boolean: 'boolean',
  number: 'number',
  array: 'array',
  quantity: 'quantity',
  time: 'time',
  touchTime: 'touchTime',
  object: 'object',
  timeZone: 'timeZone',
};
