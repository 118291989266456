// IT - Italian
export default {
  intl_addr_country: 'Paese',
  intl_addr_address1: 'Indirizzo',
  intl_addr_address2: 'Indirizzo 2',
  intl_addr_address3: 'Indirizzo 3',
  intl_addr_address4: 'Indirizzo 4',
  intl_addr_city: 'Città',
  intl_addr_state: 'Stato',
  intl_addr_zipCode: 'Codice postale',
  intl_addr_longitude: 'Longitudine',
  intl_addr_latitude: 'Latitudine',
  intl_addr_department: 'Dipartimento',
  intl_addr_suburb: 'Sobborgo',
  intl_addr_postalCode: 'Codice postale',
  intl_addr_province: 'Provincia',
  intl_addr_neighborhood: 'Quartiere',
};
