import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { DropdownMenu } from '../../core';
import { PLACEMENT, TRIGGER } from '../../core/dropdown-menu/DropdownMenu';
import { Translate } from '../../localization';
import { useThemeContext } from '../ThemeProvider';
import style from './style.js';

const Styled = styled.div`
  ${style}
`;

const ThemeSwitcher = ({ className }) => {
  const { themes, setThemeName, themeName } = useThemeContext();

  const handleMenuClick = React.useCallback(
    themeName => {
      setThemeName(themeName);
    },
    [setThemeName]
  );

  return (
    <Styled className={cn('theme-switcher', className)}>
      <DropdownMenu
        trigger={[TRIGGER.CLICK]}
        placement={PLACEMENT.TOP_LEFT}
        menuItems={Object.keys(themes).map(key => ({ id: key, stringId: key }))}
        onClick={handleMenuClick}
        activeId={themeName}
        toggleWhenTwo
      >
        <div className="dropdown-content">
          <div className="icon">
            <i className="fas fa-adjust" />
          </div>
          <div className="label">
            <Translate stringId={themeName} data-testid="theme-switcher-label" />
          </div>
        </div>
      </DropdownMenu>
    </Styled>
  );
};

export default ThemeSwitcher;
