import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const option = ({ theme }) => css`
  position: relative;
  font-size: 28px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: ${theme.validateMode({
    light: theme.colorsCA.neutral[40],
    dark: theme.colorsCA.neutral[95],
  })};

  &:hover {
    background-color: ${theme.validateMode({
      light: theme.colorsCA.darkBlue[95],
      dark: theme.colorsCA.neutral[30],
    })};
  }

  &.active {
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    color: ${theme.colorsCA.neutral[95]};
    background-color: ${theme.validateMode({
      light: theme.colorsCA.darkBlue[70],
      dark: theme.colorsCA.darkBlue[60],
    })};
  }

  &.menu-icon-secondary {
    &.active {
      box-shadow: none;
      pointer-events: none;
      background-color: ${theme.validateMode({
        light: theme.colorsCA.darkBlue[50],
        dark: theme.colorsCA.darkBlue[60],
      })};
    }
  }
`;

export const Styled = styled.div`
  ${option}
`;
