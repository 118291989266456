import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem, getCachedItem } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import messages from './i18n';
import menuConfig from './menu-config';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { Users } from './views/users/Users';
import { InvitedUsers } from './views/invited-users/InvitedUsers';
import { CancelReasons } from './views/cancel-reasons/CancelReasons';
import { RedirectToEntity } from './views/RedirectToEntity';
import { Setup } from './views/setup/Setup';
import { CompanySetup, Webhooks, Notifications } from './views/setup/views';

API.setSource('cxp');
API.setApiKey(getCachedItem('apiKey'), '');

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem menuConfig={menuConfig} messages={messages} appId="cxp-admin">
            <Routes>
              <Route element={<AuthenticatedRoutes />}>
                <Route path="" element={<RedirectToEntity />}>
                  <Route path=":entityRef">
                    <Route path="users/*" element={<Users />} />
                    <Route path="invited-users/*" element={<InvitedUsers />} />
                    <Route path="cancel-reasons/*" element={<CancelReasons />} />
                    <Route path="setup/*">
                      <Route path="" element={<Setup />} exact />
                      <Route path="company-setup" element={<CompanySetup />} />
                      <Route path="webhooks" element={<Webhooks />} />
                      <Route path="notifications" element={<Notifications />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
