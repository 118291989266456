import React from 'react';
import { useAppContext } from './app-provider/context';

export const useHeaderText = () => {
  const { headerText, setHeaderText } = useAppContext();

  React.useEffect(() => {
    return () => {
      setHeaderText('');
    };
  }, [setHeaderText]);

  return { headerText, setHeaderText };
};
