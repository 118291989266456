import React from 'react';
import { Select } from '../../select/Select';

export const Zone = ({ options, ...props }) => {
  return (
    <div className="zone">
      <Select {...props} displayPath="name" valuePath="name" options={options} keyPath="name" />
    </div>
  );
};

export default Zone;
