import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div``;
export const FixedSidebar = props => {
  return (
    <Styled className={cn('fixed-sidebar')} >
      Fixed
    </Styled>
  );
};
