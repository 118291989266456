import React from 'react';
import { FormattedNumber, useIntl } from 'react-intl';

export const Number = ({ value, decimalPlaces = 0 }) => {
  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };

  return <FormattedNumber value={value} {...options} />;
};

export const useNumber = () => {
  const { formatNumber } = useIntl();

  const getNumber = React.useCallback(
    (value, options = {}) => {
      const preparedOptions = {
        minimumFractionDigits: options.decimalPlaces,
        maximumFractionDigits: options.decimalPlaces,
      };
      return formatNumber(value, preparedOptions);
    },
    [formatNumber]
  );

  return { getNumber, Number };
};
