// English
export default {
  newVersionAvailable: 'New version available',
  versionInfo: 'A new version of {appName} is available. Please reload the page to update to version {version}.',
  reload: 'Reload',
  later: 'Later',
  intl_addr_country: 'Country',
  intl_addr_address1: 'Address',
  intl_addr_address2: 'Address 2',
  intl_addr_address3: 'Address 3',
  intl_addr_address4: 'Address 4',
  intl_addr_city: 'City',
  intl_addr_state: 'State',
  intl_addr_zipCode: 'Zip Code',
  intl_addr_longitude: 'Longitude',
  intl_addr_latitude: 'Latitude',
  intl_addr_department: 'Department',
  intl_addr_suburb: 'Suburb',
  intl_addr_postalCode: 'PostalCode',
  intl_addr_province: 'Province',
  intl_addr_neighborhood: 'Neighborhood',
};
