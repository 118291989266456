import { css } from 'styled-components';

const CURRENT_ITEM_HEIGHT = 24;
const TOP_MARGIN = 20;
const BOTTOM_MARGIN = 20;
const GAP = 10;
const LINE_HEIGHT = 36;
const MAX_HEIGHT = 300;

const calculateExpandedHeight = lineCount => {
  return Math.min(lineCount * LINE_HEIGHT + TOP_MARGIN + GAP, MAX_HEIGHT);
};

export default css`
  z-index: 10;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  margin-top: 7px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  border-radius: 10px;
  border: 1px solid transparent;
  height: 28px;
  transition: all 0.3s;
  background-color: var(--primary-background-color);

  .current-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    height: ${CURRENT_ITEM_HEIGHT}px;
    line-height: 24px;
    font-weight: 400;
    .icon {
      font-size: 20px;
    }

    .arrow {
      font-size: 16px;
      transition: all 0.3s;
    }
  }

  .items {
    .options {
      transition: opacity 0.3s;
      opacity: 0;
    }
  }
  
  &.open {
    border: var(--mini-profile-border);
    background-color: var(--sidebar-background-color);
    padding: 20px;
    height: ${({ $lineCount }) => `${calculateExpandedHeight($lineCount)}px`}; //300px;
    box-shadow: var(--mini-profile-box-shadow);
    .current-item {
      .arrow {
        transform: rotate(180deg);
      }
    }

    .items {
      .options {
        height: 215px;
        overflow: auto;
        opacity: 1;
      }
    }
  }

  .overlay {
    &:not(.open) {
      display: none;
    }
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
