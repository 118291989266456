import React, { useContext } from 'react';
import { useListConfig } from './useListConfig';

const ListAndDetail = React.createContext();

export const useListAndDetailContext = () => useContext(ListAndDetail);

const ListAndDetailProvider = ({ children, listQuery, config, ...props }) => {
  const { data, isLoading, formConfig } = useListConfig({ listQuery, config });

  const parentContext = useListAndDetailContext();

  return (
    <ListAndDetail.Provider value={{ data, isLoading, formConfig, config, parentContext, ...props }}>
      {children}
    </ListAndDetail.Provider>
  );
};

export default ListAndDetailProvider;
