import { omit } from 'lodash';

export default config => {
  const groups = config?.groups || [{ fields: config?.fields }];

  const flattened = groups.reduce((acc, group) => {
    acc = acc.concat(group.fields);
    return acc;
  }, []);

  const gridCompatible = flattened.map(field => ({ ...omit(field, ['path']), key: field.path }));

  return gridCompatible;
};
