import { flattenMessages } from './flattenMessages';

export const consolidateMessages = ({ messages }) => {
  try {
    // stub out object with a key for each locale found in messages object.
    const consolidatedMessages = Object.keys(messages).reduce((acc, locale) => {
      acc[locale] = {};
      return acc;
    }, {});

    // Flatten messages
    Object.keys(messages).forEach(key => {
      if (consolidatedMessages[key]) {
        consolidatedMessages[key] = flattenMessages(messages[key]);
      }
    });

    return consolidatedMessages;
  } catch (e) {
    console.log('consolidateMessages', e.message);
  }
};
