// Swedish

export default {
  newVersionAvailable: 'Ny version tillgänglig',
  versionInfo: 'En ny version av {appName} är tillgänglig. Vänligen ladda om sidan för att uppdatera till version {version}.',
  reload: 'Ladda om',
  later: 'Senare',
  intl_addr_country: 'Land',
  intl_addr_address1: 'Adress 1',
  intl_addr_address2: 'Adress 2',
  intl_addr_address3: 'Adress 3',
  intl_addr_address4: 'Adress 4',
  intl_addr_city: 'Stad',
  intl_addr_state: 'Stat',
  intl_addr_zipCode: 'Postnummer',
  intl_addr_longitude: 'Längdgrad',
  intl_addr_latitude: 'Breddgrad',
  intl_addr_department: 'Avdelning',
  intl_addr_suburb: 'Förort',
  intl_addr_postalCode: 'Postnummer',
  intl_addr_province: 'Provins',
  intl_addr_neighborhood: 'Grannskap',
};
