import { find, get, omit, isUndefined, isObject, isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Select as CoreSelect } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { FIELD_TYPES } from '../fieldTypes';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from '../useIsFieldAllowed';
import { ValidationText } from '../validation-text/ValidationText';
import { Checkbox } from './Checkbox';
import { handleMultiSelectChange, handleStandardSelectChange } from './handleChange';
import style from './style';
import { useOptions } from './useOptions';

const Styled = styled.div`
  ${style}
`;

export const Select = React.forwardRef((_props, ref) => {
  const formContext = useFormContext();
  const { disabled: formDisabled, isSubmitting, setFieldValue, values, handleBlur } = formContext;
  const isFieldAllowed = useIsFieldAllowed();

  const { fieldConfig, options, isRequired, validationText = true, ...props } = _props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };

  const prerequisitesMet = React.useMemo(() => {
    if (fieldConfig?.prerequisites) {
      return fieldConfig.prerequisites.every(item => !!values[item]);
    }
    return true;
  }, [fieldConfig.prerequisites, values]);

  const [busy, setBusy] = React.useState(false);
  const [_options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (options instanceof Promise) {
      setBusy(true);
      options.then(setOptions).finally(() => {
        setBusy(false);
      });
    } else {
      setOptions(options || fieldConfig?.listValues || []);
    }
  }, [fieldConfig?.listValues, options]);

  const disabled = React.useMemo(() => {
    return isSubmitting || busy || props.busy || !prerequisitesMet || props.disabled;
  }, [busy, isSubmitting, prerequisitesMet, props.busy, props.disabled]);

  const value = React.useMemo(() => {
    // The CoreSelect component expects a primitive value.
    const path = fieldConfig.path
      .split('.')
      .concat([fieldConfig.valuePath])
      .filter(x => !isUndefined(x));

    return get(values, path);
  }, [fieldConfig.path, fieldConfig.valuePath, values]);

  const mappedOptions = useOptions({ options: _options, fieldConfig, value: values[fieldConfig.path] });

  const handleChange = React.useCallback(
    value => {
      if (Array.isArray(value)) {
        handleMultiSelectChange({ value, options: _options, fieldConfig, setFieldValue });
        return;
      }

      handleStandardSelectChange({
        value: isNil(value) ? '' : value,
        options: _options,
        fieldConfig,
        setFieldValue,
      });
    },

    [_options, fieldConfig, setFieldValue]
  );

  const Component = React.useMemo(() => {
    return fieldConfig.inferFromListValue ? Checkbox : CoreSelect;
  }, [fieldConfig.inferFromListValue]);

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.fieldLabelStringId || fieldConfig.labelStringId}
        isRequired={isRequired}
        disabled={formDisabled || disabled}
        testId={fieldConfig.testId}
        busy={busy || props.busy}
      />

      <Component
        innerRef={ref}
        {...props}
        name={fieldConfig.path}
        id={fieldConfig.path}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
        disabled={formDisabled || disabled}
        options={mappedOptions}
        testId={fieldConfig.testId}
        mode={fieldConfig.dataType === FIELD_TYPES.array ? 'multiple' : undefined}
        inferFromListValue={{ [fieldConfig.listValuePath || 'id']: fieldConfig.inferFromListValue }}
        allowClear={fieldConfig.allowClear || false}
      />

      {validationText && <ValidationText path={fieldConfig.path} name={fieldConfig.name} />}
    </Styled>
  );
});
