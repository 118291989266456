const darkBlue = {
  10: '#002B54',
  20: '#023C6C',
  30: '#094678',
  40: '#164F84',
  50: '#1F578C',
  60: '#496E9A',
  70: '#6B88AB',
  80: '#94A9C3',
  90: '#BECBDB',
  95: '#E5EAF0',
  100: '#FFFFFF',
};

export default darkBlue;
