import { css } from 'styled-components';

export default css`
  .notification-will-call-confirmation-notification-toggle {
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 25px 15px;
    vertical-align: middle;
    width: 50%;
  }
`;
