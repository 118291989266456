import React from 'react';

export const usePostMessageHandler = handler => {

  React.useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);


  return {};
};
