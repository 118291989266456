import React from 'react';

import { CompanyProfileStyled } from './styles.js';
import { MaterialIcon } from '../../../components/icons/MaterialIcon.js';

export const CompanyProfile = ({ onClick, entity }) => {
  return (
    <CompanyProfileStyled
      alt={entity?.name || ''}
      className="company"
      onClick={onClick}
      src={entity?.entityImages?.logoDefault?.data}
    >
      <MaterialIcon iconName="BusinessOutlined" />
    </CompanyProfileStyled>
  );
};
