import { find, get, pick, set } from 'lodash';
import React from 'react';

export const useNext = ({ config, previousSelectedItem, value }) => {
  const next = React.useMemo(() => {
    // Remove the first element.
    const nextConfig = [...config];
    nextConfig?.shift?.();

    if (!previousSelectedItem) {
      return { nextConfig };
    }

    // Since the first element was removed above, we know the next config is the first in the array.
    const nextPath = nextConfig?.[0]?.path;

    // Depends on the selected item
    const nextOptionsValue = get(previousSelectedItem, nextPath);

    const nextOptions = nextOptionsValue && !!previousSelectedItem ? set({}, nextPath, nextOptionsValue) : undefined;

    if (!nextConfig) return {};

    const targetValue = find(value, { id: previousSelectedItem.id });
    const nextValue = pick(targetValue, nextPath);

    return { nextConfig, nextOptions, nextValue };
  }, [config, previousSelectedItem, value]);

  return next;
};
