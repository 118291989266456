import React, { useMemo, useCallback } from 'react';

import { isEmpty, isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { Translate } from '../Translate';

export const Uom = ({ uom, short = false, suffix = false, testId }) => {
  const uomType = useMemo(() => {
    let result = '';
    if (short) result += '_short';
    if (suffix) result += '_suffix';
    return result;
  }, [suffix, short]);

  if (isEmpty(uom) || !uom?.uomCode || isNil(uom?.value)) return '';

  return (
    <Translate
      stringId={`${uom?.uomCode}${uomType}`}
      values={{ value: uom?.value }}
      data-testid={testId}
    />
  );
};

export const useUom = () => {
  const { formatMessage } = useIntl();

  const getUom = useCallback(
    (uom, short = false, suffix = false) => {
      let uomType = '';

      if (short) uomType += '_short';
      if (suffix) uomType += '_suffix';

      if (isEmpty(uom) || !uom?.uomCode || isNil(uom?.value)) return '';
      return formatMessage({ id: `${uom?.uomCode}${uomType}` }, { value: uom?.value });
    },
    [formatMessage]
  );

  return { getUom, Uom };
};
