import React from 'react';
import { API } from '../components/api';
import { useAppData } from '../util/app-data/AppData';
import { VersionDialog } from './version-dialog/VersionDialog';

export const VersionHandler = ({ children }) => {
  const { clientVersion } = useAppData();
  const [versionInfo, setVersionInfo] = React.useState(null);
  const handleClose = React.useCallback(() => {
    setVersionInfo(null);
  }, []);

  React.useEffect(() => {
    const removeHandler = API.setVersionHandler({
      meta: 'Default version',
      clientVersion,
      callback: version => {
        setVersionInfo({ clientVersion, version });
        console.log(`Default: New version available: ${clientVersion} -> ${version}`);
      },
    });
    return () => {
      removeHandler();
    };
  }, [clientVersion]);

  return (
    <>
      <VersionDialog open={!!versionInfo} onClose={handleClose} versionInfo={versionInfo} />
      {children}
    </>
  );
};
