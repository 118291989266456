import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'invited-users',
  labelStringId: 'invitedUsers',
  testId: 'invitedUsers',
  keyPath: 'username',
  validationSchema: Yup.object().shape({
    username: Yup.string(),
    createDate: Yup.string(),
  }),

  fields: [
    {
      path: 'username',
      labelStringId: 'username',
      testId: 'username',
      dataType: Form.FIELD_TYPES.string,
    },
    {
      path: 'createDate',
      labelStringId: 'inviteDate',
      testId: 'inviteDate',
      dataType: Form.FIELD_TYPES.string,
    },
  ],
};
