import { find, get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import timeZones from '../../core/time-zone-picker/zone/zones';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

export const getTimeZoneString = ({ dateTime, offset, timeZone, messages, userLocale, formatMessage, fullName }) => {
  if (!dateTime) return null;

  if (offset) return dateTime.toFormat('ZZZ');
  const tzRecord = find(timeZones, { name: timeZone });
  const defaultTz = dateTime.setLocale(tzRecord?.locale).toFormat('ZZZZ');
  const timeZoneMessages = get(messages, `${userLocale}.timeZones.${dateTime.zoneName.replace('/', '.')}`);

  const shortTimeZone = timeZoneMessages
    ? formatMessage({
        id: `timeZones.${dateTime.zoneName.replace('/', '.')}.${dateTime.isInDST ? 'dst' : 'standard'}`,
      })
    : defaultTz;

  return fullName ? timeZone : shortTimeZone;
};

export const useTimeZone = ({ date, fullName, offset, namedOffset, local }) => {
  const { userLocale, timeZone, messages } = useLocalizationContext();
  const { formatMessage } = useIntl();

  const dateTime = React.useMemo(() => toDateTime(date, timeZone, local), [date, local, timeZone]);

  const tz = React.useMemo(
    () => getTimeZoneString({ dateTime, offset, timeZone, messages, userLocale, formatMessage, fullName }),
    [dateTime, formatMessage, fullName, messages, offset, timeZone, userLocale]
  );

  if (!date) return null;

  if ((offset || namedOffset) && !date)
    throw new Error(`Date is required. ${date}, ${offset}, ${namedOffset} ${fullName}`);

  return tz;
};

export const useGetTimeZone = () => {
  const { userLocale, timeZone, messages } = useLocalizationContext();
  const { formatMessage } = useIntl();

  const getTimeZone = React.useCallback(
    ({ date, fullName, offset, namedOffset, local }) => {
      const dateTime = toDateTime(date, timeZone, local);

      const tz = getTimeZoneString({ dateTime, offset, timeZone, messages, userLocale, formatMessage, fullName });

      if (!date) return null;

      if ((offset || namedOffset) && !date)
        throw new Error(`Date is required. ${date}, ${offset}, ${namedOffset} ${fullName}`);

      return tz;
    },
    [formatMessage, messages, timeZone, userLocale]
  );

  return getTimeZone;
};

export const TimeZone = props => {
  const tz = useTimeZone(props);

  return tz;
};
