import darkBlue from './darkBlue';
import blue from './blue';
import neutral from './neutral';
import aqua from './aqua';
import green from './green';
import red from './red';
import orange from './orange';
import yellow from './yellow';
import surface from './surface';

// Exports all Global Library colors
const colors = {
  darkBlue: darkBlue,
  blue: blue,
  neutral: neutral,
  aqua: aqua,
  green: green,
  red: red,
  orange: orange,
  yellow: yellow,
  surface: surface,
};

export default colors;
