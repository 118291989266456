import React from 'react';
import { useLocalizationContext } from '../../localization';
import { useModalContext } from '../../modal';
import { parse } from './number-input/parse';

function sanitize(str, decimalSeparator, initialValue) {
  if (!str || initialValue === 0 || initialValue === '') return '';

  const regex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');

  // Ensure only numbers and decimal separator
  let numbers = str.replace(regex, '');

  // remove leading zeros except when preceding the decimal separator.
  if (numbers?.length > 1 && numbers?.charAt?.(0) === '0' && numbers?.charAt?.(1) !== decimalSeparator) {
    numbers = numbers.substring(1);
  }

  // Remove all but first decimal separator
  return numbers.split(decimalSeparator).reduce((prev, curr, i) => prev + (i === 1 ? decimalSeparator : '') + curr);
}

export const useKeypad = ({ value, onChange }) => {
  const { userLocale } = useLocalizationContext();
  const decimalSeparator = React.useMemo(() => {
    const number = 1.1;
    const separator = Intl.NumberFormat(userLocale)
      .formatToParts(number)
      .find(part => part.type === 'decimal').value;

    return separator;
  }, [userLocale]);

  const [_value, setValue] = React.useState(
    sanitize(Intl.NumberFormat(userLocale).format(value), decimalSeparator, value) || ''
  );

  const handleChange = React.useCallback(
    key => {
      if (key === 'Backspace') {
        const strValue = String(_value);
        setValue(strValue.slice(0, -1));
        return;
      }

      if (key === 'decimalSeparator') {
        setValue(sanitize(`${_value}${decimalSeparator}`, decimalSeparator));
        return;
      }

      const strValue = `${_value}${key}`;

      const sanitizedValue = sanitize(strValue, decimalSeparator);

      setValue(sanitizedValue);
    },
    [_value, decimalSeparator]
  );

  const { closeModal } = useModalContext();

  const handleCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleSave = React.useCallback(() => {
    const newValue = parse(userLocale, sanitize(_value, decimalSeparator));
    onChange(newValue);
    closeModal();
  }, [_value, closeModal, decimalSeparator, onChange, userLocale]);

  return { handleSave, handleChange, decimalSeparator, displayValue: _value, handleCancel };
};
