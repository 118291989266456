import React from 'react';
import { LongDate, useLongDate } from './LongDate';
import { Time, useTime } from './Time';

export const LongDateTime = ({ date, hideTimeZone = false, withDayOfWeek = false }) => {
  return (
    <>
      <LongDate date={date} withDayOfWeek={withDayOfWeek} /> <Time date={date} hideTimeZone={hideTimeZone} />
    </>
  );
};

export const useLongDateTime = () => {
  const { getLongDate } = useLongDate();
  const { getTime } = useTime();

  const getLongDateTime = React.useCallback(
    (date, withDayOfWeek = false, hideTimeZone = false) => {
      const longDate = getLongDate(date, withDayOfWeek);
      const time = getTime(date, hideTimeZone);

      return `${longDate} ${time}`;
    },
    [getLongDate, getTime]
  );

  return { getLongDateTime, LongDateTime };
};
