import cn from 'classnames';
import { Core, usePostMessageContext, User } from 'connex-cds';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import style from './style';
import { FormattedMessage } from 'react-intl';

const Styled = styled.div`
  ${style}
`;

export const Setup = () => {
  const navigate = useNavigate();
  const { hostname } = window.location;

  const { pathname } = useLocation();
  const { sendMessage } = usePostMessageContext();
  const { isPlatformAdmin, isPlatformSupport } = User.usePermissions();
  const [isDevelop, setIsDevelop] = React.useState(false);
  const [iconList, setIconList] = React.useState([]);

  React.useEffect(() => {
    sendMessage({ locationChange: pathname });
  }, [pathname, sendMessage]);

  const handleClick = React.useCallback(
    item => {
      navigate(`${item.key}`);
    },
    [navigate]
  );

  React.useEffect(() => {
    hostname.includes('develop') || hostname.includes('localhost') ? setIsDevelop(true) : setIsDevelop(false);

    const tempIconList = [
      {
        key: 'company-setup',
        icon: <i className="icon fa-thin fa-industry-windows" />,
        translatedLabel: 'companySetup',
      },
      {
        key: 'webhooks',
        icon: <i className="icon fa-thin fa-spider-web" />,
        translatedLabel: 'webhooks',
        platformOnly: true,
      },
      {
        key: 'notifications',
        icon: <i className="icon fa-thin fa-bell-on"></i>,
        translatedLabel: 'notifications',
      },
    ];

    setIconList([...tempIconList]);
  }, [hostname, isDevelop]);

  return (
    <Core.Spinner>
      <Styled className={cn('setup')}>
        <div className="layout-grid">
          {iconList.map(item => {
            if (!item?.platformOnly || (item?.platformOnly && (isPlatformAdmin || isPlatformSupport))) {
              return (
                <div onClick={() => handleClick(item)} key={item.key} data-testid={item.key}>
                  {item?.icon}
                  <span data-stringid={item.key} data-testid={`${item.key}-label`}>
                    <FormattedMessage id={item.translatedLabel} />
                  </span>
                </div>
              );
            }
          })}
        </div>
      </Styled>
    </Core.Spinner>
  );
};
