import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import getPath from '../../../util/getPath';
import { Input as CoreInput } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from '../useIsFieldAllowed';
import { coerceValue } from '../util';
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Input = React.forwardRef((props, ref) => {
  const formContext = useFormContext();
  const isFieldAllowed = useIsFieldAllowed();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values } = formContext;
  const { fieldConfig, isRequired, rows, multiline, validationText = true, ...otherProps } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };

  const handleInputChange = React.useCallback(
    value => {
      const transformedValue = fieldConfig?.transformOut?.(value);
      setFieldValue(fieldConfig.path, transformedValue || coerceValue({ type: fieldConfig.dataType, value }));
    },

    [fieldConfig, setFieldValue]
  );

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    const incomingValue = get(values, path);
    return fieldConfig?.transformIn ? fieldConfig?.transformIn?.(incomingValue) : incomingValue;
  }, [fieldConfig, values]);

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.labelStringId}
        data-testid={fieldConfig.testId}
        isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
        disabled={disabled || isSubmitting}
      />
      <CoreInput
        name={fieldConfig.path}
        ref={ref}
        value={value}
        onBlur={handleBlur}
        disabled={disabled || isSubmitting}
        type={fieldConfig.password ? 'password' : 'text'}
        testId={fieldConfig.testId}
        rows={rows}
        multiline={multiline}
        {...otherProps}
        onChange={handleInputChange}
      />
      {validationText && <ValidationText path={fieldConfig.path} name={fieldConfig.name} />}
    </Styled>
  );
});
