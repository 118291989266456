import { useNavigate } from 'react-router';
import { useWebhooks } from '../../../query-hooks/webhooks';
import { useCallback } from 'react';
import { Card, Divider, Result, Space } from 'antd';
import { Core, Modal } from 'connex-cds';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import style from './style';
import Meta from 'antd/lib/card/Meta';
import WebhookDataModal from './webhooks-data-modal/WebhookDataModal';

export const Webhooks = () => {
  const navigate = useNavigate();
  const { isFetching, isError, data } = useWebhooks();
  const { closeModal, openModal } = Modal.useModalContext();
  const ACTIVE_STATUS = 'ACTIVE';
  const TYPE_ID_ORDER = 'order';
  const TYPE_ID_TICKET = 'ticket';
  const TYPE_ID_TICKET_EVENT = 'ticket-event';
  const VALIDS_TYPES_IDS = [TYPE_ID_ORDER, TYPE_ID_TICKET, TYPE_ID_TICKET_EVENT];

  const Styled = styled.div`
    ${style}
  `;

  const goBack = useCallback(() => {
    navigate('..');
  }, [navigate]);

  const openModalData = useCallback(
    data => {
      openModal({
        titleStringId: 'webhookData',
        component: <WebhookDataModal data={data} onCancel={closeModal} />,
        width: '800px',
      });
    },
    [openModal, closeModal]
  );

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Core.Button size="small" type="primary" stringId="back" data-testid="back-button" onClick={goBack} />
        <Divider orientation="left">
          <FormattedMessage id={'webhooksTitle'} defaultMessage={'Available Webhooks'} />
        </Divider>
        <p>
          <FormattedMessage id={'webhooksDesc'} defaultMessage={'Description'} />
        </p>

        <Core.Spinner spin={isFetching}>
          {isError && (
            <Result
              status="error"
              title={<FormattedMessage id={'error'} defaultMessage={'Error'} />}
              subTitle={<FormattedMessage id={'compSetupErrorMessage'} defaultMessage={'Error has occurred'} />}
            />
          )}
          <Styled>
            <div className="layout-grid">
              {data &&
                data.length > 0 &&
                data.map(item => {
                  return (
                    <Card
                      key={item.crn}
                      data-testid={item.crn}
                      className="webhooks-card"
                      hoverable={true}
                      onClick={() => openModalData(item)}
                      title={item?.name ? item?.name : 'NO TITLE'}
                      actions={[<i className="fa-solid fa-code" />]}
                    >
                      {VALIDS_TYPES_IDS.includes(item.itemTypeId) ? (
                        <p className="item-type-id">
                          {item.itemTypeId === TYPE_ID_ORDER && (
                            <FormattedMessage id={'itemTypeIdOrder'} defaultMessage={'itemTypeIdOrder'} />
                          )}
                          {item.itemTypeId === TYPE_ID_TICKET && (
                            <FormattedMessage id={'itemTypeIdTicket'} defaultMessage={'itemTypeIdTicket'} />
                          )}
                          {item.itemTypeId === TYPE_ID_TICKET_EVENT && (
                            <FormattedMessage id={'itemTypeIdTicketEvent'} defaultMessage={'itemTypeIdTicketEvent'} />
                          )}
                        </p>
                      ) : (
                        <p className="item-type-id"> - </p>
                      )}
                      {item.status === ACTIVE_STATUS ? (
                        <span className="active-status" title="Webhook Status">
                          <i className="fa-solid fa-cloud-check"></i>
                        </span>
                      ) : (
                        <span className="inactive-status" title="Webhook Status">
                          <i className="fa-solid fa-cloud-exclamation"></i>
                        </span>
                      )}
                      <Meta description="Webhook Status" />
                    </Card>
                  );
                })}
            </div>
          </Styled>
        </Core.Spinner>
      </Space>
    </>
  );
};
