import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../localization';
import { Selector } from './Selector';

const Styled = styled.div`
  width: fit-content;
`;

export const CurrentSelector = ({ config, options, onSelectedItemChange, selectedItems, onChange }) => {
  return (
    <Styled className={cn('selector')}>
      <Translate stringId={config?.stringId} data-testid={`${config?.stringId}-selector-label`} />
      <Selector
        config={config}
        options={options}
        onSelectedItemChange={onSelectedItemChange}
        selectedItems={selectedItems}
        onChange={onChange}
      />
    </Styled>
  );
};
