import React from 'react';
import { useArrayContext } from '../context/ArrayContext';
import { CustomListComponent } from './custom/CustomListComponent';
import { GridListComponent } from './grid/GridListComponent';
import { SimpleListComponent } from './simple/SimpleListComponent';

export const ListComponent = ({ simple, Component, hideWhenEmpty, ...props }) => {
  const { value } = useArrayContext();

  if (hideWhenEmpty && !value?.length) return null;

  if (Component) return <CustomListComponent Component={Component} {...props} />;

  return simple ? <SimpleListComponent {...props} /> : <GridListComponent {...props} />;
};
