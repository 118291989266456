import cn from 'classnames';
import { cloneDeep, find, kebabCase } from 'lodash';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Column } from '../../../../layout';
import { Translate } from '../../../../localization';
import { Switch } from '../../../switch/Switch';
import { useGridContext } from '../../context/context';
import { Button } from '../../../button/Button';
import { StrictModeDroppable } from './StrictModeDroppable';

const Styled = styled(Column)`
  overflow: hidden;
  grid-gap: 10px;

  .column-actions {
    margin-top: 10px;
    display: flex;

    .left {
      display: flex;
      grid-gap: 10px;

      flex: 1;
    }

    .right {
      text-align: right;
    }
  }

  .visible-columns,
  .hidden-columns {
    border: 1px solid var(--color-border-grid-preferences);
    background-color: var(--color-background-grid-preferences);
    //flex: 1;
    padding: 10px;
    overflow: auto;

    &.isDraggingOver {
      //background-color: #282828;
    }

    .column-header {
    }
  }

  .toggle-all {
    display: flex;
    grid-gap: 10px;
    justify-content: flex-end;
  }

  .column-card {
    height: 45px;
    border: 1px solid var(--color-border-grid-preferences-columnCard);
    display: flex;
    align-items: center;
    background-color: var(--color-background-grid-preferences-columnCard);
    margin-bottom: 10px;
    transition: opacity 0.3s, background-color 0.3s;
    &.isDragging {
      border: 1px solid var(--color-border-grid-preferences);
      background-color: transparent; //#434343;
      opacity: 0.45;
    }

    &.hidden {
      background-color: var(--color-background-grid-preferences-columnCard-hidden);
      //color: #888;
    }

    .card-content {
      flex: 1;
      padding: 10px;
      display: flex;

      .column-name {
        flex: 1;
      }

      .attributes {
        margin-right: 20px;

        .hidden-checkbox {
          margin-right: 5px;
        }
      }
    }

    .handle {
      font-size: 1.2em;
      padding-right: 10px;
      color: var(--color-text-grid-preferences-columnCard-dragHandle);
    }
  }
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnPreferences = ({ onDone }) => {
  const { columns, saveColumnPreferences, originalColumnDefinitions, gridName } = useGridContext();

  const originalItems = React.useRef(columns);
  const [items, setItems] = React.useState(cloneDeep(originalItems.current));

  const onDragEnd = React.useCallback(
    ({ source, destination }) => {
      if (!destination) {
        return;
      }
      const reorderedItems = reorder(items, source.index, destination.index);
      setItems(reorderedItems);
    },
    [items]
  );

  const handleHide = React.useCallback(({ checked, columnKey }) => {
    setItems(s => {
      const state = [...s];
      const target = find(state, { key: columnKey });
      if (target) {
        target.hidden = checked;
      }
      return state;
    });
  }, []);

  const handleSave = React.useCallback(() => {
    saveColumnPreferences(items);
    originalItems.current = cloneDeep(items);
    onDone?.();
  }, [items, onDone, saveColumnPreferences]);

  const handleUndo = React.useCallback(() => {
    setItems(cloneDeep(originalItems.current));
  }, []);

  const handleFactoryReset = React.useCallback(() => {
    setItems(originalColumnDefinitions);
  }, [originalColumnDefinitions]);

  const allHidden = React.useMemo(() => {
    return items.every(c => c.hidden);
  }, [items]);

  const allVisible = React.useMemo(() => {
    return items.every(c => !c.hidden);
  }, [items]);

  const hideAll = React.useCallback(() => {
    setItems(s => s.map(i => ({ ...i, hidden: true })));
  }, []);

  const showAll = React.useCallback(() => {
    setItems(s => s.map(i => ({ ...i, hidden: false })));
  }, []);

  return (
    <Styled>
      <div className="toggle-all">
        <Button
          size="small"
          onClick={showAll}
          disabled={allVisible}
          metricId={gridName ? `${kebabCase(gridName)}-preferences-show-all` : undefined}
          stringId="showAll"
          data-testid="show-all-button"
        />
        <Button
          size="small"
          onClick={hideAll}
          disabled={allHidden}
          metricId={gridName ? `${kebabCase(gridName)}-preferences-hide-all` : undefined}
          stringId="hideAll"
          data-testid="hide-all-button"
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="columns">
          {(provided, snapshot) => (
            <div
              className={cn('visible-columns', { isDraggingOver: snapshot.isDraggingOver })}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((column, index) => {
                return (
                  <Draggable key={column.key} draggableId={column.key} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={cn('column-card', { isDragging: snapshot.isDragging, hidden: column.hidden })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                      >
                        <div className="card-content">
                          <div className="column-name">
                            <Translate stringId={column.labelStringId} data-testid={`label-${column.testId}`} />
                          </div>
                          <div className="attributes">
                            <Switch
                              className="hidden-checkbox"
                              checkedStringId="visible"
                              uncheckedStringId="hidden"
                              onClick={checked => {
                                handleHide({ checked: !checked, columnKey: column.key });
                              }}
                              checked={!column.hidden}
                              data-testid={`toggle-${column.testId}`}
                            />
                          </div>
                        </div>
                        <div className="handle" {...provided.dragHandleProps} data-testid={`drag-handle-${column.testId}`}>
                          <i className="fal fa-bars" />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      <div className="column-actions">
        <div className="left">
          <Button
            type="primary"
            onClick={handleSave}
            disabled={allHidden}
            metricId={gridName ? `${kebabCase(gridName)}-preferences-save` : undefined}
            stringId="save"
            data-testid="save-preferences-button"
          />
          <Button
            onClick={handleUndo}
            metricId={gridName ? `${kebabCase(gridName)}-preferences-undo-changes` : undefined}
            stringId="undoChanges"
            data-testid="undo-preferences-button"
          />
        </div>
        <div className="right">
          <Button
            type="danger"
            onClick={handleFactoryReset}
            metricId={gridName ? `${kebabCase(gridName)}-preferences-reset-columns` : undefined}
            stringId="resetColumns"
            data-testid="reset-columns-button"
          />
        </div>
      </div>
    </Styled>
  );
};

export default ColumnPreferences;
