import React from 'react';
import { useNavigate } from 'react-router';
import { useDrawerContext } from '../../drawer';
import { useModalContext } from '../../modal';
import { useListAndDetailContext } from './ListAndDetailProvider';

export const useClickHandlers = () => {
  // const { config, Editor, detailType, detailOptions, onPick, updateFn, createFn, deleteFn } = useListAndDetailContext();

  const { Editor, updateQuery, createQuery, deleteQuery, ...context } = useListAndDetailContext();

  const { config, detailOptions, detailType, onPick } = context;

  const { closeDrawer, openDrawer } = useDrawerContext();
  const { closeModal, openModal } = useModalContext();

  const EditorComponent = React.useMemo(
    () => <Editor updateQuery={updateQuery} createQuery={createQuery} deleteQuery={deleteQuery} context={context} />,
    [context, createQuery, deleteQuery, updateQuery]
  );

  const navigate = useNavigate();
  const handlers = React.useMemo(() => {
    return {
      drawer_add: () => {
        openDrawer?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },
      drawer_edit: () => {
        openDrawer?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },
      full_width_drawer_add: ({ title }) => {
        openDrawer?.({
          titleStringId: title || `${config.name || config.path}Editor`,
          width: '100%',
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },
      full_width_drawer_edit: ({ title }) => {
        openDrawer?.({
          titleStringId: title || `${config.name || config.path}Editor`,
          width: '100%',
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },

      view_add: () => {
        navigate('detail');
      },
      view_edit: () => {
        navigate('detail');
      },
      modal_add: () => {
        openModal?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeModal }),
          ...detailOptions,
        });
      },
      modal_edit: () => {
        openModal?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeModal }),
          ...detailOptions,
        });
      },
    };
  }, [
    EditorComponent,
    closeDrawer,
    closeModal,
    config.name,
    config.path,
    detailOptions,
    navigate,
    openDrawer,
    openModal,
  ]);

  return {
    handleAddClick: createQuery ? handlers[`${detailType}_add`] : undefined,
    handleRowClick: onPick || handlers[`${detailType}_edit`],
  };
};
