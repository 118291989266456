import React from 'react';
import { useLocalizationContext } from './components/localization';
import { useThemeContext } from './components/theme';

export const AppListener = ({ children }) => {
  const { setThemeName } = useThemeContext();
  const { setUserLocale } = useLocalizationContext();

  const handleMessageEvent = React.useCallback(
    event => {
      if (event?.data?.theme) {
        setThemeName(event?.data?.theme);
      }

      if (event?.data?.language) {
        setUserLocale(event?.data?.language);
      }

      // if (event.origin === 'http://localhost:3101') {
      //   console.log('received', event);
      // }
    },
    [setThemeName, setUserLocale]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  return children;
};
