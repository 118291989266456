import { cloneDeep, get, isEmpty, isString, set } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useShortDate } from '../date-time';
import { useFormatterFunction } from './getFormatterFunction';
import { useFormatters } from './useFormatters';

/**
 *
 * @param objects
 * @param paths
 * @param formatter
 * @returns {null|*[]}
 */
export const translateObjects = (objects, paths, formatter) => {
  const objectArray = [].concat(cloneDeep(objects));
  const pathsToTranslate = [].concat(paths);

  if (!objectArray?.length || !pathsToTranslate?.length) return null;
  return objectArray.map(object => {
    pathsToTranslate.forEach(path => {
      const getPath = path?.getPath || path;
      const setPath = path?.setPath || path;

      set(object, setPath, formatter({ id: get(object, getPath) }));
    });
    return object;
  });
};

export const useTranslateObjects = () => {
  const intl = useIntl();
  const formatters = useFormatters();
  const getFormatterFunction = useFormatterFunction();

  const translateObjects = React.useCallback(
    (objects, options) => {
      if (!options) return null;

      const objectArray = [].concat(cloneDeep(objects));
      const optionsArray = [].concat(options);

      if (!objectArray?.length || !optionsArray?.length) return null;

      return objectArray.map(object => {
        optionsArray.forEach(option => {
          const getStringId = option?.getStringId || (value => value);
          const getPath = option?.getPath;
          const setPath = option?.setPath;
          const defaultMessagePath = option?.defaultMessagePath;

          const values = option?.valuesFrom?.reduce?.((acc, key) => {
            acc[key] = get(object, key);
            return acc;
          }, {});

          if (option.formatter) {
            // if argsGetter is omitted, use the value at getPath as the only argument.
            const args = option?.formatter?.argsGetter?.(object) || [get(object, getPath)];

            const formatterFn = getFormatterFunction(option);

            set(object, setPath, formatterFn(...args));
          } else {
            set(
              object,
              setPath,
              intl.formatMessage(
                { id: getStringId(get(object, getPath)), defaultMessage: get(object, defaultMessagePath) },
                isEmpty(values) ? undefined : values
              )
            );
          }
        });
        return object;
      });
    },
    [getFormatterFunction, intl]
  );

  return { translateObjects, formatters };
};
