import React from 'react';
import { useCurrency } from '../currency/Currency';
import { useDayOfWeek, useLongDate, useLongDateTime, useShortDate, useShortDateTime, useTime } from '../date-time';
import { useNumber } from '../number/Number';
import { useUom } from '../uom/Uom';

export const useFormatters = () => {
  const { getCurrency } = useCurrency();
  const { getDayOfWeek } = useDayOfWeek();
  const { getLongDate } = useLongDate();
  const { getLongDateTime } = useLongDateTime();
  const { getNumber } = useNumber();
  const { getShortDate } = useShortDate();
  const { getShortDateTime } = useShortDateTime();
  const { getTime } = useTime();
  const { getUom } = useUom();

  return React.useMemo(
    () => ({
      currency: getCurrency,
      dayOfWeek: getDayOfWeek,
      longDate: getLongDate,
      longDateTime: getLongDateTime,
      number: getNumber,
      shortDate: getShortDate,
      shortDateTime: getShortDateTime,
      time: getTime,
      uom: getUom,
    }),
    [
      getCurrency,
      getDayOfWeek,
      getLongDate,
      getLongDateTime,
      getNumber,
      getShortDate,
      getShortDateTime,
      getTime,
      getUom,
    ]
  );
};
