import { css } from 'styled-components';
import { calculateAppGridHeight } from '../style';

export default css`
  padding: 15px 20px;
  height: ${({ $apps }) => `${calculateAppGridHeight($apps)}px`};
  overflow: auto;
  .app-icons-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;

    .app-icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 60px;
      height: 60px;
      cursor: pointer;
      font-size: 10px;
      font-weight: 500;
      line-height: 10px;

      .app-name-default {
        position: absolute;
        color: white;
        top: 36px;
      }
    }
  }
`;
