import React from 'react';
import { Core } from 'connex-cds';
import { FormattedMessage } from 'react-intl';
import { Space, Tag } from 'antd';
import { filter, truncate } from 'lodash';

export const getCustomerColumns = removeCustomer => {
  return [
    {
      title: <FormattedMessage id={'name'} defaultMessage={'Name'} />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <FormattedMessage id={'projectsById'} defaultMessage={'Projects By ID'} />,
      key: 'projects',
      render: data => {
        const projects = filter(data?.projects, { type: 'projectId' });
        const projectsByRef = filter(data?.projects, { type: 'projectRef' });
        if (projects.length === 0 && projectsByRef.length === 0) {
          return (
            <Tag>
              <FormattedMessage id={'all'} defaultMessage={'ALL'} />
            </Tag>
          );
        }
        return projects?.map((proj, index) => (
          <Tag key={proj?.id} style={{ margin: 6 }}>
            {proj.id} | {truncate(proj.name, { length: 15 })}
          </Tag>
        ));
      },
    },
    // {
    //   title: <FormattedMessage id={'specificProjects'} defaultMessage={'Specific Projects'} />,
    //   key: 'projects',
    //   render: data => {
    //     const projects = filter(data?.projects, { type: 'projectRef' });
    //
    //     return (
    //       projects &&
    //       projects.map((proj, index) => {
    //         return (
    //           <Tag key={proj?.crn}>
    //             {proj?.id}: {proj?.address?.address1}
    //           </Tag>
    //         );
    //       })
    //     );
    //   },
    // },
    {
      title: <FormattedMessage id={'delete'} defaultMessage={'Delete'} />,
      render: data => <a onClick={() => removeCustomer(data.id)} className={'fa fa-icon fa-trash'} />,
    },
  ];
};

export const getProjectColumns = handleAddProject => {
  return [
    {
      title: <FormattedMessage id={'id'} defaultMessage={'ID'} />,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <FormattedMessage id={'name'} defaultMessage={'Name'} />,
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: <FormattedMessage id={'address'} defaultMessage={'Address'} />,
    //   dataIndex: 'address',
    //   render: data => <>{data?.address1}</>,
    // },
    {
      title: '',
      render: data => (
        <Space>
          <Core.Button
            onClick={() => handleAddProject({ projectCrn: data.crn, projectId: data.id, type: 'projectId' })}
            testId="add-project-by-id-button"
            stringId="addProject"
            type="primary"
            icon={<i className="fa fa-plus-circle" style={{ marginRight: 12 }} />}
          />
          {/*<Core.Button*/}
          {/*  onClick={() => handleAddProject({ projectCrn: data.crn, projectId: data.id, type: 'projectRef' })}*/}
          {/*  testId="add-project-by-ref-button"*/}
          {/*  stringId="addProjectByRef"*/}
          {/*  type="primary"*/}
          {/*  icon={<i className="fa fa-plus-circle" style={{ marginRight: 12 }} />}*/}
          {/*/>*/}
        </Space>
      ),
    },
  ];
};
