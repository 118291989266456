import { css } from '@emotion/react';

export const MuiGlobalStyles = ({ theme }) => {
  const sidebarColor1 = theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] });
  const sidebarColor2 = theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] });

  return css`
    * {
      scrollbar-color: ${sidebarColor1} ${sidebarColor2};
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${sidebarColor2};
        border-radius: 8px;
        border: none;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${sidebarColor1};
        border-radius: 8px;
      }
    }
  `;
};
