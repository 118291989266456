import { Timeline as AntTimeline } from 'antd';
import cn from 'classnames';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Time } from '../../localization';
import style from './style';

const Styled = styled.div`
  ${style}
`;

// TODO: colors (severity)

export const Timeline = ({ items, noData, desc }) => {
  if (!items?.length) return noData;

  const sortedItems = React.useMemo(() => {
    const sortedAsc = sortBy(items, ['isoDateString']);

    return desc ? sortedAsc.reverse() : sortedAsc;
  }, [desc, items]);

  return (
    <Styled className={cn('timeline')}>
      <AntTimeline mode="left">
        {sortedItems.map(item => (
          <AntTimeline.Item label={<Time date={item.isoDateString} hideTimeZone />}>
            {item.description}
          </AntTimeline.Item>
        ))}
      </AntTimeline>
    </Styled>
  );
};

Timeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isoDateString: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  noData: PropTypes.element,
};
