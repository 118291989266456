import React from 'react';

export const AuthContext = React.createContext();

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuthContext cannot be rendered outside of the AuthContext context provider`);
  }
  return context;
};
