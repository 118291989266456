import React from 'react';
import { FormProvider } from '../../context-provider/FormProvider';
import { useArrayContext } from '../context/ArrayContext';
import { ListComponent } from '../list-component/ListComponent';
import { CancelButton } from './form-controls/CancelButton';
import { DeleteButton } from './form-controls/DeleteButton';
import { ResetButton } from './form-controls/ResetButton';
import { SaveButton } from './form-controls/SaveButton';
import { useDeleteItem } from './useDeleteItem';

const components = { CancelButton, SaveButton, DeleteButton, ListComponent, ResetButton };

export const ArrayFormProvider = ({ children, ...props }) => {
  const arrayContext = useArrayContext();

  const deleteItem = useDeleteItem();

  return (
    <FormProvider
      fieldConfig={arrayContext?.fieldConfig}
      config={arrayContext?.fieldConfig?.arrayOf}
      disabled={!arrayContext?.mode}
      components={components}
      deleteItem={deleteItem}
      {...props}
    >
      {children}
    </FormProvider>
  );
};
