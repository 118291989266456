export default [
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
  },
  {
    countryCode: 'AD',
    countryName: 'Andorra',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia (Plurinational State of)',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei Darussalam',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
  },
  {
    countryCode: 'CV',
    countryName: 'Cabo Verde',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos (Keeling) Islands',
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
  },
  {
    countryCode: 'CW',
    countryName: 'Curaçao',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czechia',
  },
  {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands (Malvinas)',
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
  },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
  },
  {
    countryCode: 'IR',
    countryName: 'Iran (Islamic Republic of)',
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
  },
  {
    countryCode: 'KP',
    countryName: "Korea (Democratic People's Republic of)",
  },
  {
    countryCode: 'KR',
    countryName: 'Korea, Republic of',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia (Federated States of)',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova, Republic of',
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine, State of',
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
  },
  {
    countryCode: 'RE',
    countryName: 'Réunion',
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
  },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
  },
  {
    countryCode: 'SY',
    countryName: 'Syrian Arab Republic',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan, Province of China',
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
  },
  {
    countryCode: 'TL',
    countryName: 'Timor-Leste',
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela (Bolivarian Republic of)',
  },
  {
    countryCode: 'VN',
    countryName: 'Viet Nam',
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
  },
];
