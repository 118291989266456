import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { useNumberInput } from './useNumberInput';

const Styled = styled.span`
  ${style}
`;

export const NumberInput = ({ addonAfter, ...props }) => {
  const inputProps = useNumberInput(props);

  return (
    <Styled className={cn('number-input-wrapper', { addon: !!addonAfter })}>
      <input className={cn('number-input')} {...inputProps} />
      {addonAfter ? <span className="number-input-addon">{addonAfter}</span> : null}
    </Styled>
  );
};
