import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Column } from './Column';
import pkg from '../../../package.json';

const Styled = styled(Column)`
  transition: all 0.3s;
  background-color: var(--primary-background-color);
  font-family: 'Work Sans', serif;
  height: 100vh;
  width: 100%;
`;

export const RootContainer = ({ children, className, ...props }) => {
  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`, className)} {...props}>
      {children}
    </Styled>
  );
};
