import { find, reject } from 'lodash';

export const addProjectsToCustomer = (
  customerList,
  customerId,
  projectCrn,
  projectId,
  type,
  selectedCustomers,
  projectsList
) => {
  const customerFromList = find(customerList, { id: customerId });

  let customer = find(selectedCustomers, { id: customerId });
  if (!customer) {
    delete customerFromList.projects;
    customer = customerFromList;
  }
  let customersArray = reject(selectedCustomers, { id: customerId });

  const project = find(projectsList, { crn: projectCrn });

  delete project.type;

  project.type = type;

  if (!customer?.projects) {
    customer.projects = [];
  }

  if (customer?.projects && customer?.projects?.length < 1) {
    customer.projects = [project];
  } else {
    if (type === 'projectRef') {
      if (!find(customer.projects, { crn: projectCrn })) {
        customer.projects.push(project);
      }
    } else {
      if (!find(customer.projects, { id: projectId })) {
        customer.projects.push(project);
      }
    }
  }

  customersArray.push(customer);
  return customersArray;
};
