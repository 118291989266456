import { css } from 'styled-components';

export default css`
  .ant-modal-content {
    background-color: var(--color-background-drawer);
    color: var(--color-text-drawer);
    .ant-modal-header {
      background-color: var(--color-background-drawer);
      border-bottom: 1px solid var(--color-border-drawer-header);
      .ant-modal-header-title {
        background-color: var(--color-background-drawer);
        color: var(--color-text-drawer);
      }
      .ant-modal-title {
        color: var(--color-text-drawer);
      }
    }
    .ant-modal-close {
      color: var(--color-icon-drawer-close);
    }
  }
`;
