import { set } from 'lodash';
import timeZones from './time-zones';
import common from './common-translations';

export default messages => {
  const withTimeZones = Object.keys(messages).reduce((acc, key) => {
    acc[key] = { ...messages[key] };

    if (timeZones[key]) {
      set(acc, [key, 'timeZones'], timeZones[key]);
    }

    return acc;
  }, {});

  return Object.keys(messages).reduce((acc, key) => {
    acc[key] = { ...withTimeZones[key] };

    if (messages[key]) {
      set(acc, [key, 'cai'], common[key]);
    }

    return acc;
  }, {});
};
