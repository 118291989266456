const blue = {
  10: '#124E88',
  20: '#196EAA',
  30: '#1C7FBE',
  40: '#2191D2',
  50: '#249FE0',
  60: '#38ADE5',
  70: '#55BBE9',
  80: '#82CEF0',
  90: '#B3E1F6',
  95: '#E1F4FC',
  100: '#FFFFFF',
};

export default blue;
