import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
// import { usePostMessageListener } from '../../../util/usePostMessageListener';
import { CollapsibleSidebar } from './CollapsibleSidebar';
import { FixedSidebar } from './FixedSidebar';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Sidebar = ({ fixed, children, open }) => {
  const [isOpen, setIsOpen] = React.useState(!!open);

  const toggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const SidebarComponent = React.useMemo(() => {
    return fixed ? FixedSidebar : CollapsibleSidebar;
  }, [fixed]);

  return (
    <Styled className={cn('cx-sidebar', { open: isOpen })}>
      <SidebarComponent isOpen={isOpen} onTrigger={toggle} />
      <div className="content">{children}</div>
    </Styled>
  );
};
