import cn from 'classnames';
import { Core, Drawer, Form, Modal } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { find, omit, orderBy, reject } from 'lodash';
import { Col, Divider, Row, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import style from './style';
import { useListCustomers } from '../../query-hooks/customers';
import { useListRoles, useUpdateUserRole } from '../../query-hooks/roles';
import { getCustomerColumns, getProjectColumns } from './columns/columns';
import { getUserRbac, getUserRole } from '../../query-hooks/users';
import { Projects } from '../../api';
import UserSubscriptionModal from './user-subscription-modal/UserSubscriptionModal';
import { filterProjectList } from './util/filterProjects';
import { buildCustomerRBAC } from './util/buildCustomerRBAC';
import { addProjectsToCustomer } from './util/addProjectsToCustomer';
import { addOptionLabels } from './util/addOptionLabels';

const Styled = styled.div`
  ${style}
`;

export const UserEditor = () => {
  const { setFieldValue, values, Components } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { closeModal, openModal } = Modal.useModalContext();
  const [busy, setBusy] = React.useState(false);
  const [allRoles, setAllRoles] = React.useState([]);
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [projectsList, setProjectsList] = React.useState([]);
  const [projectsLoading, setProjectsLoading] = React.useState(false);
  const [showCustomers, setShowCustomers] = React.useState(false);
  const [isLoadingRBAC, setIsLoadingRBAC] = React.useState(false);

  const { entityRef } = useParams();
  const userInitialRole = getUserRole(values?.toRef);
  const rolesQuery = useListRoles();
  const customerQuery = useListCustomers();
  const userRbac = getUserRbac(values?.toRef);
  const updateUserRole = useUpdateUserRole(values?.toRef);

  // Show or hide customers dropdown based on selected role permissions
  React.useEffect(() => {
    // Check the selected role's permissions
    const cxpPerms = values?.role?.permissions?.cxp;
    const isInternalUserOrAdmin = cxpPerms?.includes('internal-user') || cxpPerms?.includes('*');

    if (isInternalUserOrAdmin) {
      setShowCustomers(false);
    } else {
      setShowCustomers(true);
    }
  }, [values?.role]);

  // Update project list when customer is changed
  React.useEffect(() => {
    // Call Projects API and update the projects list
    (async () => {
      if (values?.customer?.id) {
        setProjectsLoading(true);
        const filteredProjects = await filterProjectList(entityRef, values?.customer?.id);
        setProjectsLoading(false);
        setProjectsList(filteredProjects);
      }
    })();

    return () => {};
  }, [values?.customer, entityRef]);

  // Get the user's role set the default value
  React.useEffect(() => {
    if (userInitialRole?.data && rolesQuery?.data) {
      let currentRole;
      for (let userInitRole of userInitialRole?.data) {
        const foundRole = find(rolesQuery?.data, { crn: userInitRole?.crn }, null);
        if (foundRole) {
          currentRole = foundRole;
        }
      }
      setFieldValue('role', { ...omit(currentRole, 'value'), roleRef: currentRole?.crn });
    }
  }, [userInitialRole?.data, rolesQuery?.data, values.fromRef, setFieldValue]);

  // Add labels to the roles if they don't have them
  React.useEffect(() => {
    if (rolesQuery?.data) {
      setAllRoles(addOptionLabels(rolesQuery?.data));
    }
  }, [rolesQuery.data]);

  React.useEffect(() => {
    (async () => {
      const retCustomers = [];
      if (userRbac?.data?.customers) {
        let customerIds = [];
        for (let cust of userRbac?.data?.customers) {
          const foundCustomer = find(customerQuery.data, { crn: cust?.customerRef });
          if (foundCustomer) {
            customerIds.push(foundCustomer.id);
          }
        }
        const customerIdString = customerIds.length > 1 ? customerIds.join(',') : customerIds[0];
        if (customerIdString) {
          setIsLoadingRBAC(true);
          const projectList = await Projects.listProjects({ entityRef, customerId: customerIdString });
          for (let rbac of userRbac?.data?.customers) {
            const foundCustomer = find(customerQuery.data, { crn: rbac.customerRef });
            if (foundCustomer) {
              foundCustomer.projects = rbac?.projects?.map(project => {
                if (project?.projectRef) {
                  const newProject = find(projectList, { crn: project?.projectRef });
                  newProject.type = 'projectRef';
                  return newProject;
                }
                if (project?.projectId) {
                  const newProject = find(projectList, { id: project?.projectId });
                  newProject.type = 'projectId';
                  return newProject;
                }
              });
              retCustomers.push(foundCustomer);
            }
          }

          setSelectedCustomers(retCustomers);
          setIsLoadingRBAC(false);
        }
      }
    })();

    return () => {};
  }, [customerQuery.data, entityRef, userRbac?.data?.customers]);

  const handleClick = React.useCallback(async () => {
    setBusy(true);
    const customers = buildCustomerRBAC(selectedCustomers);

    const response = await updateUserRole({
      customers: { customers },
      entityRef: values?.fromRef,
      profileRef: values?.toRef,
      roleRef: values?.role?.roleRef,
    });
    setBusy(false);

    if (response?.success === 'ok') {
      closeDrawer();
    }
  }, [closeDrawer, selectedCustomers, updateUserRole, values?.fromRef, values?.role?.roleRef, values?.toRef]);

  const handleSelectCustomer = React.useCallback(async () => {
    if (!find(selectedCustomers, { id: values?.customer?.id })) {
      setSelectedCustomers(s => [...s, { ...values?.customer, projects: [] }]);
    }
  }, [selectedCustomers, values]);

  const removeCustomer = React.useCallback(
    async customerId => {
      const customersArray = reject(selectedCustomers, { id: customerId });
      setSelectedCustomers(customersArray);
    },
    [selectedCustomers]
  );

  // Add the projects to the selectedCustomers array
  const handleAddProject = React.useCallback(
    ({ projectCrn, projectId, type }) => {
      const customersArray = addProjectsToCustomer(
        customerQuery?.data,
        values?.customer?.id,
        projectCrn,
        projectId,
        type,
        selectedCustomers,
        projectsList
      );

      setSelectedCustomers(customersArray);
    },
    [customerQuery?.data, projectsList, selectedCustomers, values?.customer?.id]
  );

  const customerColumns = getCustomerColumns(removeCustomer);
  const projectColumns = getProjectColumns(handleAddProject);

  const modalClick = React.useCallback(() => {
    openModal({
      titleStringId: {
        stringId: 'subscriptionsForUser',
        values: { firstName: values?.firstName, lastName: values?.lastName },
      },
      component: <UserSubscriptionModal user={values} onCancel={closeModal} />,
      width: '800px',
    });
  }, [openModal, values, closeModal]);

  return (
    <Styled className={cn('user-editor')}>
      <Row justify="space-between" align="middle">
        <Col offset={20}>
          <Core.Button
            size="small"
            type="primary"
            stringId="userSubscriptions"
            data-testid="user-subscriptions-button"
            onClick={modalClick}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={12}>
        <Col span={5}>
          <Components.FirstName disabled />
        </Col>
        <Col span={5}>
          <Components.LastName disabled />
        </Col>
        <Col span={7}>
          <Components.Email disabled />
        </Col>
        <Col span={6}>
          <Components.Phone disabled />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Components.Role options={allRoles} loading={busy} />
        </Col>
      </Row>
      {showCustomers && (
        <>
          <Row gutter={12} style={{ marginTop: 24 }}>
            <Col span={6}>
              <Components.Customer
                options={orderBy(customerQuery.data, ['name'], ['asc'])}
                busy={customerQuery.isLoading}
              />
            </Col>
            <Col span={6} style={{ marginTop: 30 }}>
              <Core.Button onClick={handleSelectCustomer} testId="add-customer" stringId="addCustomer" type="primary" />
            </Col>
          </Row>
          <>
            <Divider orientation="left">
              <FormattedMessage id={'customers'} defaultMessage={'Customers'} />{' '}
            </Divider>
            <Table
              columns={customerColumns}
              dataSource={selectedCustomers}
              loading={isLoadingRBAC}
              rowKey={'customerRef'}
              style={{ margin: '14px 0' }}
            />
          </>
          {values?.customer && (
            <>
              <Divider orientation="left">
                <FormattedMessage id={'projectsFor'} defaultMessage={'Projects for'} /> {values?.customer?.name}
              </Divider>
              <Table
                columns={projectColumns}
                dataSource={projectsList}
                loading={projectsLoading}
                style={{ margin: '14px 0' }}
              />
            </>
          )}
        </>
      )}
      <div className="actions" style={{ marginTop: 28 }}>
        <Core.Button
          onClick={handleClick}
          testId="update-user-role"
          stringId="updateRole"
          disabled={!values?.role?.roleRef}
          type="primary"
          loading={busy}
        />
      </div>
    </Styled>
  );
};
