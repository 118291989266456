import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import style from './style';
import { useUpdateCancelReason } from '../../query-hooks/reasons';

const Styled = styled.div`
  ${style}
`;

export const CancelReasonEditor = ({ updateQuery }) => {
  const { values, Components } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const updateCancelReason = useUpdateCancelReason(values);

  const handleClick = React.useCallback(async () => {
    return updateQuery(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, updateCancelReason, values]);

  return (
    <Styled className={cn('cancel-reason-editor')}>
      <Row gutter={12}>
        <Col span={12}>
          <Components.Name disabled />
        </Col>
        <Col span={12}>
          <Components.Status disabled />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Components.Type disabled />
        </Col>
        <Col span={12}>
          <Components.PublicName />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Components.Visibility />
        </Col>
      </Row>
      <div className="actions">
        <Components.SaveButton onUpdate={handleClick} />
      </div>
    </Styled>
  );
};
