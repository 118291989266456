import { debounce } from 'lodash';
import { message } from '../core/message/message';
import { v4 as uuid } from 'uuid';

export const DEFAULT_API_KEY = 'ZcHVAa6oQ31uY7xcA4SC47Rq64gaJqN73N7xsO70';

let _config;
let _source;
let _loginToken;
let _appEntityToken;
let _apiKey;
let _apiKeys = {};
let _errorHandler = message.error;
let _listeners = [];
let _onNewVersion = [{ fn: () => {} }];
let _clientVersion = 'unknown';
let _onUnauthenticated = () => {};

export const initialize = (optionalServices = {}) => {
  _config = {
    ...optionalServices,
  };

  return Promise.resolve();
};

export const getConfig = apiName => {
  return _config[apiName];
};

const handleError = msg => _errorHandler?.(msg);

export const setErrorHandler = handler => {
  _errorHandler = handler;
};

export const setSource = source => {
  _source = source;
  _listeners.forEach(listener => listener({ source }));
};

export const getSource = () => _source;

export const setLoginToken = loginToken => {
  _loginToken = loginToken;
  _listeners.forEach(listener => listener({ loginToken }));
};

export const getLoginToken = () => _loginToken;

export const setAppEntityToken = appEntityToken => {
  _appEntityToken = appEntityToken;
  _listeners.forEach(listener => listener({ appEntityToken }));
};

export const getAppEntityToken = () => _appEntityToken;


export const setApiKey = apiKey => {
  _apiKey = apiKey;
  _listeners.forEach(listener => listener({ apiKey }));
};

export const getApiKey = id => _apiKeys?.[id] || _apiKey || DEFAULT_API_KEY;

export const setApiKeys = apiKeys => {
  _apiKeys = apiKeys;
  _listeners.forEach(listener => listener({ apiKeys }));
};

export const getApiKeys = () => {
  return _apiKeys;
};

export const addListener = listener => {
  _listeners.push(listener);
};

export const removeListener = listener => {
  _listeners = _listeners.filter(l => l !== listener);
};

export const setVersionHandler = ({
  clientVersion = 'unknown',
  callback = () => {},
  debounceInterval = 5000,
  leading = true,
  trailing = false,
  meta,
}) => {
  const id = uuid();
  _onNewVersion.push({ id, meta, fn: debounce(callback, debounceInterval, { leading, trailing }) });
  _clientVersion = clientVersion;

  return () => {
    _onNewVersion = _onNewVersion.filter(o => o.id !== id);
  };
};

export const getVersionHandler = () => {
  const handler = _onNewVersion?.[_onNewVersion.length - 1];
  return handler?.fn || (() => {});
};

export const getClientVersion = () => _clientVersion;

export const onUnauthenticated = () => _onUnauthenticated?.();

export const setOnUnauthenticated = fn => {
  _onUnauthenticated = fn;
};

export const API = {
  initialize,
  addListener,
  removeListener,
  getConfig,
  getSource,
  setSource,
  handleError,
  setErrorHandler,
  getLoginToken,
  setLoginToken,
  getAppEntityToken,
  setAppEntityToken,
  setApiKey,
  getApiKey,
  setApiKeys,
  getApiKeys,
  setVersionHandler,
  getVersionHandler,
  getClientVersion,
  onUnauthenticated,
  setOnUnauthenticated,
};
