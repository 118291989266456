import { get } from 'lodash';
import React from 'react';
import { useFormContext } from '../../context-provider/FormProvider';
import { useArrayContext } from '../context/ArrayContext';

export const useDeleteItem = () => {
  const { fieldConfig } = useArrayContext();
  const formContext = useFormContext();

  const deleteItem = React.useCallback(
    item => {
      const formValue = get(formContext.values, fieldConfig.path);

      const newValues = formValue.filter(existing => item[fieldConfig.keyPath] !== existing[fieldConfig.keyPath]);

      formContext.setFieldValue(fieldConfig.path, newValues);
    },
    [fieldConfig.keyPath, fieldConfig.path, formContext]
  );

  return deleteItem;
};
