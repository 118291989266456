import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { isUndefined } from 'lodash';

const Styled = styled.div`
  display: flex;
  gap: 10px;
  overflow: hidden;
  ${({ $flex }) => (isUndefined($flex) ? '' : `flex: ${$flex};`)}
  ${({ $height }) => (isUndefined($height) ? '' : `height: ${$height};`)}
  & > * {
    ${({ $stretchContent }) => (isUndefined($stretchContent) ? '' : `flex: 1;`)}
  }
`;

export const Row = ({ className, flex, height, style, children, stretchContent }) => {
  return (
    <Styled
      className={cn('row', className)}
      $flex={flex}
      $height={height}
      $stretchContent={stretchContent}
      style={style}
    >
      {children}
    </Styled>
  );
};
