import { css } from '@emotion/react';
import { Popover } from '@mui/material';
import styled from '@emotion/styled';

export const StyledPopover = styled(Popover)`
  ${() => css`
    .MuiPopover-paper {
      border-radius: 10px;
      max-height: calc(100% - 76px);
    }
  `}
`;
