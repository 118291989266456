import React from 'react';
import { Spinner } from '../../core';
import { FormProvider } from '../context-provider/FormProvider';
import { DefaultEditor } from './DefaultEditor';
import ListAndDetailProvider, { useListAndDetailContext } from './ListAndDetailProvider';
import { View } from './View';

export const DETAIL_TYPES = {
  DRAWER: 'drawer',
  VIEW: 'view',
  FULL_WIDTH_DRAWER: 'full_width_drawer',
  MODAL: 'modal',
};

const ListAndDetailForm = () => {
  const { config, data, isLoading, formConfig } = useListAndDetailContext();

  return (
    <Spinner spin={isLoading || isLoading === null}>
      <FormProvider config={formConfig} initialValues={{ [config.name || config.path || 'rows']: data }}>
        <View />
      </FormProvider>
    </Spinner>
  );
};

export const ListAndDetail = ({
  config,
  Editor = DefaultEditor,
  detailType,
  detailOptions,
  updateQuery,
  listQuery,
  createQuery,
  deleteQuery,
  pickerMode,
  context,
  onPick,
  showHeader,
  customActions,
}) => {
  return (
    <ListAndDetailProvider
      config={config}
      Editor={Editor}
      detailType={detailType}
      detailOptions={detailOptions}
      createQuery={createQuery}
      listQuery={listQuery}
      updateQuery={updateQuery}
      deleteQuery={deleteQuery}
      pickerMode={pickerMode}
      context={context}
      onPick={onPick}
      showHeader={showHeader}
      customActions={customActions}
    >
      <ListAndDetailForm />
    </ListAndDetailProvider>
  );
};
