import da from './da';
import de from './de';
import enUS from './en-US';
import esCO from './es-CO';
import fr from './fr-FR';
import itIt from './it-IT';
import nlNL from './nl-NL';
import noNO from './no-NO';
import plPL from './pl-PL';
import svSE from './sv-SE';
import pt from './pt';

export default {
  da,
  de,
  'en-US': enUS,
  'en-GB': enUS,
  'es-CO': esCO,
  'fr-FR': fr,
  'it-IT': itIt,
  'nl-NL': nlNL,
  'no-NO': noNO,
  'pl-PL': plPL,
  'sv-SE': svSE,
  pt
};
