import { find, isFunction } from 'lodash';
import React from 'react';

/**
 * Map options into a standard shape ({id: string, label: string})
 * Ensure existing value (if any) is in the list of options.
 *
 * @param options
 * @param fieldConfig
 * @param value
 * @returns {unknown}
 */
export const useOptions = ({ options, fieldConfig, value }) => {
  // Map options into a standard shape ({id: string, label: string})
  const shapedOptions = React.useMemo(() => {
    if (options) {
      return options.map(option => {
        if (isFunction(fieldConfig.listDisplayPath)) {
          return {
            id: option[fieldConfig?.listValuePath || 'id'],
            label: fieldConfig.listDisplayPath(option),
          };
        }

        const displayString = []
          .concat(fieldConfig.listDisplayPath || 'label')
          .map(path => {
            return option[path];
          })
          .join(' ');

        return {
          id: option[fieldConfig?.listValuePath || 'id'],
          label: displayString,
        };
      });
    }
  }, [options, fieldConfig]);

  // To prevent the GUID from being displayed, ensure existing value (if any) is in the list of options.
  const optionsWithValue = React.useMemo(() => {
    if (value?.[fieldConfig?.valuePath || 'id']) {
      if (find(shapedOptions, { id: value?.[fieldConfig?.valuePath || 'id'] })) {
        return shapedOptions;
      }

      return shapedOptions.concat({
        id: value?.[fieldConfig?.valuePath || 'id'],
        label: value?.[fieldConfig?.displayPath || 'name'],
      });
    }

    return shapedOptions;
  }, [fieldConfig?.displayPath, fieldConfig?.valuePath, shapedOptions, value]);

  return optionsWithValue;
};
