import { find, get, isUndefined } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import getPath from '../../../util/getPath';
import { TimeZonePicker as CoreTimeZonePicker } from '../../core';
import { useFormContext } from '../context-provider/FormProvider';
import { Label } from '../label/Label';
import { useIsFieldAllowed } from '../useIsFieldAllowed';
import { coerceValue } from '../util';
import { ValidationText } from '../validation-text/ValidationText';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const TimeZonePicker = React.forwardRef((props, ref) => {
  const formContext = useFormContext();
  const { isSubmitting, setFieldValue, disabled, handleBlur, values } = formContext;
  const { fieldConfig, isRequired, rows, multiline, validationText = true, ...otherProps } = props;
  const dataTestId = { 'data-testid': `${fieldConfig.testId}-form-field` };
  const isFieldAllowed = useIsFieldAllowed();

  const handleInputChange = React.useCallback(
    value => {
      const customValue = fieldConfig?.onChange?.(value);
      setFieldValue(fieldConfig.path, customValue || coerceValue({ type: fieldConfig.dataType, value }));
    },

    [fieldConfig, setFieldValue]
  );

  const value = React.useMemo(() => {
    const path = getPath(fieldConfig);
    return get(values, path);
  }, [fieldConfig, values]);

  if (!isFieldAllowed(fieldConfig)) return null;

  return (
    <Styled {...dataTestId}>
      <Label
        stringId={fieldConfig.labelStringId}
        data-testid={fieldConfig.testId}
        isRequired={isRequired && fieldConfig?.showRequiredIndicator !== false}
        disabled={disabled || isSubmitting}
      />
      <CoreTimeZonePicker
        name={fieldConfig.path}
        ref={ref}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        disabled={disabled || isSubmitting}
        testId={fieldConfig.testId}
        {...otherProps}
      />
      {validationText && <ValidationText path={fieldConfig.path} name={fieldConfig.name} />}
    </Styled>
  );
});
