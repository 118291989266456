import React from 'react';
import Lottie from 'lottie-react';
import cn from 'classnames';

import animation from '../../../assets/spinner.json';

import { SpinnerContainerStyled } from './styles';

const Spinner = ({ title, className, ...lottieProps }) => {
  return (
    <SpinnerContainerStyled className={cn(className)}>
      <>
        <Lottie className="new-spinner" animationData={animation} loop {...lottieProps} />
        {title ? <span>{title}</span> : null}
      </>
    </SpinnerContainerStyled>
  );
};

export default Spinner;
