import { css } from 'styled-components';

export default css`
  .ant-select {
    .ant-select-selector {
      border-color: #000;
      border-radius: 5px;
      height: 36px;
    }
  }

  .phone-number-label-section {
    display: flex;
    align-items: baseline;

    .country-code-text {
      margin-left: 5px;
    }

    .required-indicator {
      margin-left: 5px;
      color: red;
    }
  }
`;
