import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { Localization } from 'connex-cds';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '40%',
  },
  '& .new-reminder-modal-title': {
    textAlign: 'center',
  },
  '& .notificaitons-selects-container': {
    margin: '50px 0px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .notificaitons-selects-container .notification-select': {
    width: '70%',
    margin: '10px auto',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const CreateNewReminderModal = ({ open, handleCloseModal }) => {
  const translateMessage = Localization.useTranslateMessage();
  const [openModal, setOpenModal] = useState(false);
  const [notificationDeliveryMethod, setNotificationDeliveryMethod] = useState('');
  const [typeNotification, setTypeNotification] = useState('');
  const [timeFrame, setTimeFrame] = useState(['']);

  const notificationDeliveryMethodOptions = [translateMessage('notificationDeliverMethod')];
  const typeNotificationOptions = [translateMessage('notificationReminderType')];
  const timeFrameOptions = [
    translateMessage('notificationTimeFrame24'),
    translateMessage('notificationTimeFrame48'),
    translateMessage('notificationTimeFrame72'),
  ];

  useEffect(() => {
    if (open) {
      setOpenModal(true);
    }
  }, [open]);

  const handleClose = () => {
    setOpenModal(false);
    handleCloseModal(true);
  };

  const handleSelectsOnChange = (typeSelect, value) => {
    const stateFunctions = {
      notificationDeliveryMethod: setNotificationDeliveryMethod,
      typeNotification: setTypeNotification,
      timeFrame: setTimeFrame,
    };

    const setStateFunction = stateFunctions[typeSelect];
    if (setStateFunction) {
      setStateFunction(value);
    }
  };

  return (
    <BootstrapDialog className="new-reminder-modal" onClose={handleClose} open={openModal}>
      <DialogTitle sx={{ m: 0, p: 2 }} className="new-reminder-modal-title">
        <FormattedMessage
          id={'notificationsNewReminderModalTitle'}
          defaultMessage={'notificationsNewReminderModalTitle'}
        />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box className="notificaitons-selects-container">
          <FormControl className="notification-select">
            <InputLabel id="notification-delivery-method">
              <FormattedMessage
                id={'notificationDeliverMethodTitle'}
                defaultMessage={'notificationDeliverMethodTitle'}
              />
            </InputLabel>
            <Select
              value={notificationDeliveryMethod}
              label={translateMessage('notificationDeliverMethodTitle')}
              onChange={e => handleSelectsOnChange('notificationDeliveryMethod', e.target.value)}
            >
              {notificationDeliveryMethodOptions.map(ndm => (
                <MenuItem key={ndm} value={ndm}>
                  {ndm}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="notification-select">
            <InputLabel id="type-notification">
              <FormattedMessage id={'notificationReminderTypeTitle'} defaultMessage={'notificationReminderTypeTitle'} />
            </InputLabel>
            <Select
              value={typeNotification}
              label={translateMessage('notificationReminderTypeTitle')}
              onChange={e => handleSelectsOnChange('typeNotification', e.target.value)}
            >
              {typeNotificationOptions.map(typeNotification => (
                <MenuItem key={typeNotification} value={typeNotification}>
                  {typeNotification}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="notification-select">
            <InputLabel id="notification-time-fram">
              <FormattedMessage id={'notificationTimeFrameTitle'} defaultMessage={'notificationTimeFrameTitle'} />
            </InputLabel>
            <Select
              value={timeFrame}
              multiple
              label={translateMessage('notificationTimeFrameTitle')}
              onChange={e => handleSelectsOnChange('timeFrame', e.target.value)}
              input={<OutlinedInput label={translateMessage('notificationTimeFrameTitle')} />}
            >
              {timeFrameOptions.map(timeFrame => (
                <MenuItem key={timeFrame} value={timeFrame}>
                  {timeFrame}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id={'notificationModalCancelBtn'} defaultMessage={'notificationModalCancelBtn'} />
        </Button>
        <Button variant="contained" autoFocus onClick={handleClose}>
          <FormattedMessage id={'notificationModalSubmitBtn'} defaultMessage={'notificationModalSubmitBtn'} />
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
