import React, { useContext } from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { EntityContext } from './context';
import { usePostMessageListener } from './usePostMessageListener';

export const AppEntityContextProvider = ({ children }) => {
  const [_entityRef, _setEntityRef] = React.useState({});
  const [token, setToken] = React.useState();
  const { sendMessage } = usePostMessageContext();
  const [subscriberCount, setSubscriberCount] = React.useState(0);

  React.useEffect(() => {
    if (_entityRef?.value === token?.entityRef) {
      sendMessage({ type: 'cx-entity-change', entityRef: _entityRef?.value, token: token?.value });
    }
  }, [_entityRef, sendMessage, token?.entityRef, token?.value]);

  // React.useEffect(() => {
  //   sendMessage({ type: 'cx-app-entity-token', token });
  // }, [token, sendMessage]);

  const listener = React.useCallback(message => {
    if (message?.type === 'cx-entity-subscribe') {
      setSubscriberCount(s => s + 1);
      _setEntityRef(s => ({ ...s }));
    }
    if (message?.type === 'cx-entity-unsubscribe') {
      setSubscriberCount(s => s - 1);
    }
  }, []);

  usePostMessageListener(listener);

  const reset = React.useCallback(() => {
    setSubscriberCount(0);
  }, []);

  const setEntityRef = React.useCallback(value => {
    _setEntityRef({ value });
  }, []);

  return (
    <EntityContext.Provider
      value={{ enabled: subscriberCount > 0, setEntityRef, entityRef: _entityRef?.value, reset, token, setToken }}
    >
      {children}
    </EntityContext.Provider>
  );
};
