import { find, get, isEmpty, omit } from 'lodash';
import React, { useContext } from 'react';
import { Row } from 'react-data-grid';
import { defaultFilterFn, getComparator } from '../util';
import { useColumnDef } from './useColumnDef';

const GridContext = React.createContext();

export const useGridContext = () => {
  const context = useContext(GridContext);

  return context;
};

const GridContextProvider = props => {
  const {
    filterable,
    columns,
    showFiltersByDefault,
    defaultFilters = {},
    gridName,
    rows,
    rowKeyPath,
    onRowClick,
    rowKeyGetter,
    rowRenderer,
    children,
    loading,
    rowGrouper,
    groupBy,
    expandedGroupIds,
    onExpandedGroupIdsChange,
    allowExport = false,
  } = props;
  const [filters, setFilters] = React.useState(defaultFilters);
  const [filterEnabled, setFilterEnabled] = React.useState(filterable && showFiltersByDefault);
  const [sortColumns, setSortColumns] = React.useState([]);

  const { visibleColumns, userColumns, saveColumnPreferences } = useColumnDef(gridName, columns, rowKeyPath);

  const setFilter = React.useCallback((fieldName, value) => {
    if (!value) {
      setFilters(s => omit(s, fieldName));
    } else {
      setFilters(s => ({ ...s, [fieldName]: value }));
    }
  }, []);

  const clearFilters = React.useCallback(() => {
    setFilters({});
  }, []);

  const sortedRows = React.useMemo(() => {
    if (sortColumns.length === 0) return rows || [];

    const sortedRows = [...rows];

    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey, columns);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });

    return sortedRows;
  }, [columns, rows, sortColumns]);

  const filteredRows = React.useMemo(() => {
    if (isEmpty(filters)) return sortedRows;

    return Object.entries(filters).reduce(
      (acc, [key, rawFilterString]) => {
        const filterStrings = []
          .concat(rawFilterString.split(','))
          .map(x => x.trim?.())
          .filter(x => !!x);

        if (!filterStrings.length) return acc;

        const columnDef = find(columns, { key });

        const filterFn = columnDef?.filterFn || defaultFilterFn;

        acc = acc.filter(row => {
          if (columnDef?.filterKey) {
            const filterKeys = [].concat(columnDef.filterKey);
            return filterKeys.some(filterKey => filterFn(row[filterKey], filterStrings, columnDef.filterStrategy));
          }
          return filterFn(columnDef?.getValue?.(row) || get(row, key), filterStrings, columnDef.filterStrategy);
        });

        return acc;
      },
      [...sortedRows]
    );
  }, [columns, filters, sortedRows]);

  React.useEffect(() => {
    if (showFiltersByDefault) setFilterEnabled(filterable);
  }, [filterable, showFiltersByDefault]);

  const defaultRowKeyGetter = React.useCallback(row => get(row, rowKeyPath), [rowKeyPath]);

  const defaultRowRenderer = React.useMemo(
    () => props => {
      const { row } = props;
      return (
        <div className="grid-row" onClick={() => onRowClick?.(row)}>
          <Row {...props} />
        </div>
      );
    },
    [onRowClick]
  );

  const reactDataGridProps = React.useMemo(
    () => ({
      className: 'filtered-data-grid',
      rowKeyGetter: rowKeyGetter || defaultRowKeyGetter,
      columns: visibleColumns,
      rows: filterEnabled ? filteredRows : sortedRows,
      rowRenderer: rowRenderer || defaultRowRenderer,
      headerRowHeight: filterEnabled ? 63 : 33,
      rowHeight: 30,
      defaultColumnOptions: { sortable: true, resizable: true },
      onSortColumnsChange: setSortColumns,
      sortColumns: sortColumns,
      rowGrouper,
      groupBy,
      expandedGroupIds,
      onExpandedGroupIdsChange,
      allowExport,
    }),
    [
      rowKeyGetter,
      defaultRowKeyGetter,
      visibleColumns,
      filterEnabled,
      filteredRows,
      sortedRows,
      rowRenderer,
      defaultRowRenderer,
      sortColumns,
      rowGrouper,
      groupBy,
      expandedGroupIds,
      onExpandedGroupIdsChange,
      allowExport,
    ]
  );

  return (
    <GridContext.Provider
      value={{
        ...props,
        allowExport,
        filters,
        setFilter,
        clearFilters,
        setFilterEnabled,
        filterEnabled,
        sortColumns,
        setSortColumns,
        visibleColumns,
        columns: userColumns,
        rows: filterEnabled ? filteredRows : sortedRows,
        originalColumnDefinitions: columns,
        totalRowCount: rows?.length || 0,
        saveColumnPreferences,
        reactDataGridProps,
        loading,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

export default GridContextProvider;
