import { Switch as AntSwitch } from 'antd';
import React from 'react';
import styled from 'styled-components';
import requireOneOfTheFollowing from '../../../util/validation/requireOneOfTheFollowing';
import { Translate } from '../../localization';
import { Helper } from '../../typography';
import style from './style';

const StyledAntSwitch = styled(AntSwitch)`
  ${style}
`;

export const Switch = ({
  innerRef,
  value,
  'data-testid': dataTestId,
  testId: testIdDeprecated,
  checkedStringId,
  uncheckedStringId,
  onChange,
  ...props
}) => {
  requireOneOfTheFollowing({ testId: testIdDeprecated, dataTestId });

  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  const dataAttributes = React.useMemo(() => {
    return { 'data-testid': testId, 'data-stringid': value ? checkedStringId : uncheckedStringId };
  }, [checkedStringId, testId, uncheckedStringId, value]);

  const handleClick = React.useCallback(
    checked => {
      onChange(checked);
    },
    [onChange]
  );

  return (
    <StyledAntSwitch
      checkedChildren={
        checkedStringId ? (
          <Helper>
            <Translate stringId={checkedStringId} data-testid="test-id" />
          </Helper>
        ) : undefined
      }
      unCheckedChildren={
        uncheckedStringId ? (
          <Helper>
            <Translate stringId={uncheckedStringId} data-testid="test-id" />
          </Helper>
        ) : undefined
      }
      className={`cds-switch`}
      onClick={handleClick}
      checked={value}
      {...props}
      {...dataAttributes}
    />
  );
};
