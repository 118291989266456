import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppHeader } from '../AppHeader';
import NewSidebar from './new-sidebar';

export const HeaderAndSidebar = () => {
  return (
    <NewSidebar>
      <AppHeader />
      <Outlet />
    </NewSidebar>
  );
};
