import { http } from 'connex-cds';

export default {
  getCancelReasons: entityRef => http.get({ apiName: 'cxp', path: `/${entityRef}/cancel-reasons` }),
  updateCancelReason: ({ cancelReason, entityRef }) =>
    http.patch({
      apiName: 'cxp',
      path: `/${entityRef}/cancel-reasons/${cancelReason.crn}`,
      data: {
        name: cancelReason.name,
        publicName: cancelReason.publicName,
        visibility: cancelReason.visibility,
        status: cancelReason.status,
        type: cancelReason.type,
      },
    }),
};
