import React from 'react';
import { API } from './components/api';
import { getApiConfig } from './components/http/http';
import { usePostMessageContext } from './post-message/PostMessage';
import { isProduction } from './util/isProduction';

export const useAppProductionWarning = () => {
  const apiConfig = getApiConfig(API.getSource());

  const { isProductionApp, isLocalhostApp, productionOverrides } = React.useMemo(() => {
    const { hostname } = window.location;

    const apiHostnamePrefix = 'REACT_APP_ENDPOINT_';

    const apiHostnameOverrides = Object.keys(process.env)
      .filter(key => key.startsWith(apiHostnamePrefix))
      .map(key => ({
        service: key.replace(apiHostnamePrefix, ''),
        url: process.env[key],
      }))
      .map(override => {
        return {
          ...override,
          isProduction: isProduction(override.url),
        };
      });

    const isLocalhostApp = hostname === 'localhost';
    const isProductionApp = isProduction();

    return { isProductionApp, isLocalhostApp, productionOverrides: apiHostnameOverrides?.some?.(o => o.isProduction) };
  }, []);

  const { sendMessage } = usePostMessageContext();

  sendMessage({ type: 'api-info', service: apiConfig.id, isProductionApp, isLocalhostApp, productionOverrides });
};
