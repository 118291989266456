import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Roles } from '../api';

export const useListRoles = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['roles', entityRef],
    queryFn: () => Roles.listRoles({ entityRef }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useUpdateUserRole = profileRef => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const userRbacKey = ['userRbac', entityRef, profileRef];
  const userRolesKey = ['userRoles', entityRef, profileRef];

  const mutation = useMutation({
    mutationFn: body => Roles.assignRole(body),
    onSettled: () => {
      queryClient.invalidateQueries({ userRbacKey });
      queryClient.invalidateQueries({ userRolesKey });
    },
  });

  return mutation.mutateAsync;
};
