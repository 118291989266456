import React from 'react';

import { getInitials } from '../../../util/strings.js';

import { ProfileStyled } from './styles.js';

export const Profile = ({ onClick, user }) => {
  return (
    <ProfileStyled
      alt={`${user?.firstName} ${user?.lastName}`}
      className="profile"
      onClick={onClick}
      src={user?.profileImage}
    >
      {getInitials(user?.firstName, user?.lastName)}
    </ProfileStyled>
  );
};
