import { css } from 'styled-components';

export default css`
  &.cx-sidebar {
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
    & > .content {
      background-color: #fafafa;
      flex: 1;
    }
  }

  .collapsible-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 282px;
    transition: width 0.3s;
    //overflow: hidden;
    margin-right: 30px;

    .trigger {
      position: absolute;
      bottom: 106px;
      right: -15px;
      box-shadow: 0px 4px 20px rgba(101, 119, 136, 0.2);
      height: 50px;
      width: 50px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        transition: transform 0.5s;
        font-size: 18px;
      }
    }

    &:not(.open) {
      width: 118px;
      .trigger {
        .icon {
          transform: rotate(180deg);
        }
      }
      .outer {
        width: 108px;
      }
    }
    .outer {
      padding: 10px;
      background-color: #fafafa;
      flex: 1;
      width: 272px;
      overflow: hidden;
      transition: width 0.3s;
      .inner {
        width: 262px;
      }
      box-shadow: 4px 0 8px rgba(101, 119, 136, 0.2);
      border-radius: 0 10px 10px 0;
    }
  }
`;
