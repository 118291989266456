// Norwegian

export default {
  newVersionAvailable: 'Ny versjon tilgjengelig',
  versionInfo: 'En ny versjon av {appName} er tilgjengelig. Vennligst last inn siden på nytt for å oppdatere til versjon {version}.',
  reload: 'Last inn på nytt',
  later: 'Senere',
  intl_addr_country: 'Land',
  intl_addr_address1: 'Adresse',
  intl_addr_address2: 'Adresse 2',
  intl_addr_address3: 'Adresse 3',
  intl_addr_address4: 'Adresse 4',
  intl_addr_city: 'By',
  intl_addr_state: 'Stat',
  intl_addr_zipCode: 'Postnummer',
  intl_addr_longitude: 'Lengdegrad',
  intl_addr_latitude: 'Breddegrad',
  intl_addr_department: 'Avdeling',
  intl_addr_suburb: 'Forstad',
  intl_addr_postalCode: 'Postnummer',
  intl_addr_province: 'Provins',
  intl_addr_neighborhood: 'Nabolag',
};
