import React from 'react';
import { Core } from 'connex-cds';
import { WarningFilled } from '@ant-design/icons';
import { Button, Modal, Result, Space, Table, Tag } from 'antd';
import { useDeleteUserSubscription, useGetUserSubscriptions } from '../../../query-hooks/users';
import { useIntl, FormattedMessage } from 'react-intl';
const { confirm } = Modal;

const UserSubscriptionModal = ({ user, onCancel }) => {
  const intl = useIntl();
  const { toRef, fromRef } = { ...user };
  const { isFetching, isError, data } = useGetUserSubscriptions(toRef, fromRef);
  const deleteSubscriptions = useDeleteUserSubscription();

  const confirmDelete = React.useCallback(async subsData => {
    if (subsData?.crn) {
      return await deleteSubscriptions({
        subscriptionRef: subsData?.crn,
        profileRef: toRef,
        entityRef: fromRef,
      });
    }
    await deleteSubscriptions({ profileRef: toRef, entityRef: fromRef });
  }, []);

  const translateNotificationType = notificationType => {
    if (notificationType === 'allLoads') {
      return <FormattedMessage id={'notificationTypeAllLoads'} defaultMessage={'notificationTypeAllLoads'} />;
    }

    return <FormattedMessage id={'notificationTypeFirstLoad'} defaultMessage={'notificationTypeFirstLoad'} />;
  };

  const showDeleteConfirm = React.useCallback(async subsData => {
    const titleText = subsData?.crn
      ? intl.formatMessage({ id: 'deleteOneSubscriptionPopupTitle' })
      : intl.formatMessage({ id: 'deleteAllSubscriptionPopupTitle' });

    confirm({
      title: titleText,
      icon: <WarningFilled />,
      okText: intl.formatMessage({ id: 'deletePopupYes' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'deletePopupNo' }),
      onOk() {
        confirmDelete(subsData);
      },
      onCancel() {
        return;
      },
    });
  }, []);

  const columns = [
    {
      title: <FormattedMessage id={'notificationType'} defaultMessage={'notificationType'} />,
      dataIndex: 'notificationType',
      key: 'notificationType',
    },
    {
      title: <FormattedMessage id={'projectId'} defaultMessage={'projectId'} />,
      dataIndex: 'projectId',
      key: 'projectId',
    },
    {
      title: <FormattedMessage id={'subscriptionType'} defaultMessage={'subscriptionType'} />,
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
    },
    {
      title: <FormattedMessage id={'customerId'} defaultMessage={'customerId'} />,
      dataIndex: 'customerId',
      key: 'customerId',
      render: customerId => (
        <span>
          {customerId?.length > 0 && (
            <Tag color="green" key={customerId}>
              {customerId}
            </Tag>
          )}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'operation',
      render: crn => {
        return (
          <Button type="primary" danger onClick={() => showDeleteConfirm(crn)}>
            <i class="fa-solid fa-trash-can"></i>
          </Button>
        );
      },
    },
  ];

  const dataTable = [];

  if (!isError && !isFetching && data) {
    for (const subscription of data?.items) {
      dataTable.push({
        key: subscription.crn,
        notificationType: translateNotificationType(subscription.notificationType),
        projectId: subscription.projectId,
        subscriptionType: subscription.subscriptionType,
        customerId: subscription.customerId,
        crn: subscription.crn,
      });
    }
  }

  return (
    <>
      <Core.Spinner spin={isFetching}>
        <Table columns={columns} dataSource={dataTable} />
        {isError && (
          <Result
            status="error"
            title={<FormattedMessage id={'error'} defaultMessage={'Error'} />}
            subTitle={<FormattedMessage id={'compSetupErrorMessage'} defaultMessage={'Error has occurred'} />}
          />
        )}
        <Space>
          <Button disabled={data?.items?.length === 0} key="deleteAll" danger onClick={() => showDeleteConfirm(null)}>
            <FormattedMessage id={'subscriptionModalDeleteAllBtn'} defaultMessage={'Delete all'} />
          </Button>
          <Button key="close" type="primary" onClick={() => onCancel()}>
            <FormattedMessage id={'subscriptionModalCloseBtn'} defaultMessage={'Close'} />
          </Button>
        </Space>
      </Core.Spinner>
    </>
  );
};

export default UserSubscriptionModal;
