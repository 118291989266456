import { Alert as AntAlert } from 'antd';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import style from './style';
export { SEVERITY } from './severity';

const Styled = styled(AntAlert)`
  ${style}
`;

export const Alert = ({ severity, title, message, closable, showIcon }) => {
  const intl = useIntl();
  const dataStringId = { 'data-stringid': [title, message] };

  const translatedMessage = React.useMemo(() => {
    return message ? intl.formatMessage({ id: message }) : undefined;
  }, [intl, message]);

  const translatedTitle = React.useMemo(() => {
    return title ? intl.formatMessage({ id: title }) : undefined;
  }, [intl, title]);

  return (
    <Styled
      className={cn('alert')}
      message={translatedTitle}
      description={translatedMessage}
      type={severity.type}
      closable={closable}
      showIcon={showIcon}
      icon={severity.icon}
      closeIcon={<i className="fa-solid fa-xmark" />}
      {...dataStringId}
    />
  );
};
