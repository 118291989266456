// https://observablehq.com/@mbostock/localized-number-parsing

export const parse = (locale, string) => {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
  const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
  const index = new Map(numerals.map((d, i) => [d, i]));
  const _group = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g');
  const _decimal = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`);
  const _numeral = new RegExp(`[${numerals.join('')}]`, 'g');
  const _index = d => index.get(d);

  return (string = string.toString().trim().replace(_group, '').replace(_decimal, '.').replace(_numeral, _index)) ? +string : NaN;
};
