import React, { useState } from 'react';
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
} from '@mui/material';
import { styled, css, createTheme, ThemeProvider } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage } from 'react-intl';
import { CreateNewReminderModal } from './CreateNewReminderModal';
import { useCallback } from 'react';

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    success: {
      dark: '#009688',
    },
  },
});

const StyledPopperDiv = styled('div')(
  ({ theme }) => css`
    > .notifications-card-option-container {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 14px;
    }

    > .notifications-card-option-container:hover {
      background-color: #f2f2f2;
    }
  `
);

export const NotificationsListTable = ({ columns, rows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const id = open ? 'simple-popover' : undefined;

  const openOptions = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeOptions = event => {
    setAnchorEl(null);
  };

  const openCreateNewReminder = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickOpenDeleteConfirmation = () => {
    setOpenConfirmDelete(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenConfirmDelete(false);
  };

  return (
    <TableContainer className="notifications-card-table" component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="notifications-card-table-columns-name">
            {columns.map(column => (
              <TableCell key={column}>{column}</TableCell>
            ))}
            <TableCell align="center">Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {row.data.map(cell => (
                <TableCell key={cell} component="th" scope="row">
                  {cell}
                </TableCell>
              ))}
              <TableCell align="center">
                <MoreHorizIcon className="notifications-card-items-options" onClick={openOptions} />
                <ThemeProvider theme={theme}>
                  <Popover
                    sx={{ bgcolor: 'transparent' }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closeOptions}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="notifications-card-items-popover"
                  >
                    <StyledPopperDiv>
                      <Box className="notifications-card-option-container" onClick={openCreateNewReminder}>
                        <EditIcon fontSize="small" />
                        <FormattedMessage id={'notificationOptionEdit'} defaultMessage={'notificationOptionEdit'} />
                      </Box>
                      <Box className="notifications-card-option-container" onClick={handleClickOpenDeleteConfirmation}>
                        <DeleteIcon fontSize="small" sx={{ marginRight: '10px' }} />
                        <FormattedMessage id={'notificationOptionDelete'} defaultMessage={'notificationOptionDelete'} />
                      </Box>
                    </StyledPopperDiv>
                  </Popover>
                </ThemeProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CreateNewReminderModal key="reminderModal" open={openModal} handleCloseModal={handleClose} />
      <Dialog open={openConfirmDelete} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          <FormattedMessage
            id={'notificationDeleteConfirmationTitle'}
            defaultMessage={'notificationDeleteConfirmationTitle'}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id={'notificationDeleteConfirmationContent'}
              defaultMessage={'notificationDeleteConfirmationContent'}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeleteConfirmation}>
            <FormattedMessage id={'notificationModalCancelBtn'} defaultMessage={'notificationModalCancelBtn'} />
          </Button>
          <Button onClick={handleCloseDeleteConfirmation} autoFocus color="error">
            <FormattedMessage
              id={'notificationDeleteConfirmationOkButton'}
              defaultMessage={'notificationDeleteConfirmationOkButton'}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};
