import neutral from './neutral';

const surface = {
  light: {
    0: '#FCFBFF',
    1: `linear-gradient(0deg, rgba(0, 43, 84, 0.05), rgba(0, 43, 84, 0.05)), ${neutral[99]}`,
    2: `linear-gradient(0deg, rgba(0, 43, 84, 0.08), rgba(0, 43, 84, 0.08)), ${neutral[99]}`,
    3: `linear-gradient(0deg, rgba(0, 43, 84, 0.11), rgba(0, 43, 84, 0.11)), ${neutral[99]}`,
    4: `linear-gradient(0deg, rgba(0, 43, 84, 0.12), rgba(0, 43, 84, 0.12)), ${neutral[99]}`,
    5: `linear-gradient(0deg, rgba(0, 43, 84, 0.14), rgba(0, 43, 84, 0.14)), ${neutral[99]}`,
  },
  dark: {
    0: neutral[10],
    1: `linear-gradient(0deg, rgba(85, 187, 233, 0.05), rgba(85, 187, 233, 0.05)), ${neutral[10]}`,
    2: `linear-gradient(0deg, rgba(85, 187, 233, 0.08), rgba(85, 187, 233, 0.08)), ${neutral[10]}`,
    3: `linear-gradient(0deg, rgba(85, 187, 233, 0.11), rgba(85, 187, 233, 0.11)), ${neutral[10]}`,
    4: `linear-gradient(0deg, rgba(85, 187, 233, 0.12), rgba(85, 187, 233, 0.12)), ${neutral[10]}`,
    5: `linear-gradient(0deg, rgba(85, 187, 233, 0.14), rgba(85, 187, 233, 0.14)), ${neutral[10]}`,
  },
};

export default surface;
