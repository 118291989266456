import React from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { cacheItem, getCachedItem } from '../../util/cache';
import { usePostMessageHandler } from '../../util/usePostMessageHandler';
import { UserContext } from './UserContext';

export const USER_KEY = '73804c78-c9d6-4673-8ad0-b96d1ef08ff2';
export const USER_APPS_KEY = '8342ad70-475b-4d89-9b58-b2e88c018520';

export const ConnexUserProvider = ({ children }) => {
  const cachedUser = getCachedItem(USER_KEY);
  const cachedUserApps = getCachedItem(USER_APPS_KEY);
  const { sendMessage } = usePostMessageContext();

  const [user, _setUser] = React.useState(cachedUser ? JSON.parse(cachedUser) : null);
  const [userApps, _setUserApps] = React.useState(cachedUserApps ? JSON.parse(cachedUserApps) : []);
  const [userAppsIsLoading, setUserAppsIsLoading] = React.useState(false);
  const [role, setRole] = React.useState();

  const sendUser = React.useCallback(() => {
    sendMessage({ user, role });
  }, [role, sendMessage, user]);

  React.useEffect(() => {
    sendMessage({ user, role });
  }, [role, sendMessage, user]);

  const handleMessageEvent = React.useCallback(
    event => {
      const message = event?.data;

      if (message?.type === 'user-rq') {
        sendUser();
      }
    },
    [sendUser]
  );

  usePostMessageHandler(handleMessageEvent);

  const setUser = React.useCallback(user => {
    if (user) {
      cacheItem(USER_KEY, JSON.stringify(user));
      _setUser(user);
    }
  }, []);

  const setUserApps = React.useCallback((apps = []) => {
    cacheItem(USER_APPS_KEY, JSON.stringify(apps));
    _setUserApps(apps);
  }, []);

  const hasPermission = React.useCallback(
    permission => {
      return role?.permissions?.[permission] || false;
    },
    [role?.permissions]
  );

  const permissions = React.useMemo(
    () => Object.keys(role?.permissions || {}).filter(key => !!role?.permissions?.[key]),
    [role?.permissions]
  );

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userApps,
        setUserApps,
        userAppsIsLoading,
        setUserAppsIsLoading,
        role,
        setRole,
        hasPermission,
        permissions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
