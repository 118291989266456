import React, { useCallback, useEffect, useState } from 'react';
import { Core, Localization, useNotification } from 'connex-cds';
import { Box, Paper, Typography, Switch } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import style from './style';
import { useSaveWillCallNotifications, useGetOrderManagement } from '../../../../query-hooks/setup';

export const WillCallConfirmationNotifications = () => {
  const [checked, setChecked] = useState(true);
  const [enable, setEnable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const notify = useNotification();
  const saveWillCallNotifications = useSaveWillCallNotifications();
  const translateMessage = Localization.useTranslateMessage();
  const { isError, data } = useGetOrderManagement();

  const Styled = styled.div`
    ${style}
  `;

  useEffect(() => {
    if (data) {
      data?.willCallNotifications ? setEnable(data?.willCallNotifications) : setEnable();
      if (data?.willCallNotifications) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setIsLoading(false);
    }
  }, [data, isError]);

  useEffect(() => {
    if (checked) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [checked]);

  const handleWillCallNotificationsChange = useCallback(
    async event => {
      setIsLoading(true);
      setChecked(event.target.checked);
      const savedWillCallNotifications = await saveWillCallNotifications(event.target.checked);

      if (savedWillCallNotifications && savedWillCallNotifications?.success) {
        setIsLoading(false);
        notify.success(translateMessage('orderManagementSavedSuccess'));
      } else {
        notify.error(translateMessage('orderManagementSaveError'));
      }
    },
    [notify, saveWillCallNotifications, translateMessage]
  );


  return (
    <>
    <Core.Spinner spin={isLoading}></Core.Spinner>
      {!isLoading && !isError && (
      <Styled>
        <Paper elevation={3} className="notification-will-call-confirmation-notification-toggle">
          <Box className="notification-will-call-left-box">
            <Typography variant="h6">
              <FormattedMessage
                id={'willCallConfirmationNotification'}
                defaultMessage={'willCallConfirmationNotification'}
              />
            </Typography>
            <Typography>{enable}</Typography>
          </Box>
          <Box className="notification-will-call-right-box">
            <Switch checked={checked} onChange={handleWillCallNotificationsChange} inputProps={{ 'aria-label': 'controlled' }} />
          </Box>
        </Paper>
      </Styled>
      )}
    </>
  );
};
