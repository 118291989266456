const red = {
  10: '#A62416',
  20: '#B52D21',
  30: '#C33427',
  40: '#D43D2D',
  50: '#E2462E',
  60: '#E05448',
  70: '#D9716C',
  80: '#E69894',
  90: '#FACBCE',
  95: '#FDEAEC',
  100: '#FFFFFF',
};

export default red;
