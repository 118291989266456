import { Drawer, Form } from 'connex-cds';
import React from 'react';
import { reasons } from '../../query-hooks';
import config from './config';
import { CancelReasonEditor } from './CancelReasonEditor';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const View = () => {
  const listCancelReasons = reasons.useListCancelReasons();
  const updateCancelReason = reasons.useUpdateCancelReason();

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.DRAWER}
      Editor={CancelReasonEditor}
      showHeader={false}
      config={config}
      listQuery={listCancelReasons}
      updateQuery={updateCancelReason}
    />
  );
};
