import React from 'react';
import { Drawer, Form } from 'connex-cds';
import { useCreateProfile, useListUsers, useUpdateUser } from '../../query-hooks';
import config from './config';
import { UserEditor } from './UserEditor';
import { View } from './View';



export const Users = () => {


  return (
    <Drawer.DrawerProvider>
      <View />
    </Drawer.DrawerProvider>
  );
};
