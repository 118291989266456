import cn from 'classnames';
import { get, set } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { CurrentSelector } from './CurrentSelector';
import { useCurrent } from './useCurrent';
import { useNext } from './useNext';
import { useSelection } from './useSelection';

const Styled = styled.div`
  width: fit-content;
  display: flex;
  gap: 20px;
`;

const RecursiveNestedSelector = ({ config, options, value, onChange }) => {
  const { selectedItem, handleSelectedItemChange, selectedItems } = useSelection({ config, options, value });

  const { currentConfig, currentOptions, currentValue } = useCurrent({ config, options, value });

  const { nextConfig, nextOptions, nextValue } = useNext({
    config,
    previousSelectedItem: selectedItem,
    value: currentValue,
  });

  const handleChange = React.useCallback(
    val => {
      onChange(val);
    },
    [onChange]
  );

  const handleNextChange = React.useCallback(
    val => {
      const parentId = selectedItem?.id;

      const newValue = get(value, currentConfig.path, [])?.map?.(v => {
        return v.id === parentId ? { ...v, [nextConfig?.[0]?.path]: val } : v;
      });

      onChange(newValue);
    },
    [currentConfig, nextConfig, onChange, selectedItem?.id, value]
  );

  return (
    <Styled className={cn('nested-selector')}>
      <CurrentSelector
        config={currentConfig}
        options={currentOptions}
        onSelectedItemChange={handleSelectedItemChange}
        selectedItems={selectedItems}
        onChange={handleChange}
      />
      {nextConfig?.length ? (
        <RecursiveNestedSelector
          config={nextConfig}
          options={nextOptions}
          value={nextValue}
          onChange={handleNextChange}
        />
      ) : null}
    </Styled>
  );
};

export const NestedSelector = ({ config = [], options, onChange, value }) => {
  const handleChange = React.useCallback(
    val => {
      onChange(set({}, config?.[0]?.path, val));
    },
    [config, onChange]
  );

  return <RecursiveNestedSelector config={config} options={options} onChange={handleChange} value={value} />;
};
