import React, { useContext } from 'react';

export const AppContext = React.createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext cannot be rendered outside of the AppContext context provider`);
  }
  return context;
};
