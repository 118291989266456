import { Button as AntButton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import requireOneOfTheFollowing from '../../../util/validation/requireOneOfTheFollowing';
import TranslateString from '../../localization/util/translate-string-id/TranslateString';
import style from './style';

const StyledAntButton = styled(AntButton)`
  ${style}
`;

/**
 *
 * @param innerRef
 * @param children
 * @param stringId
 * @param testId
 * @param data-testid
 * @param size
 * @param type
 * @param disabled
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const Button = ({
  innerRef,
  children,
  stringId,
  testId: testIdDeprecated,
  'data-testid': dataTestId,
  size = 'large',
  type = 'secondary',
  disabled = false,
  suppressDisabledStyling,
  ...props
}) => {
  requireOneOfTheFollowing({ testId: testIdDeprecated, dataTestId });

  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  const dataAttributes = React.useMemo(() => {
    return { 'data-stringid': stringId, 'data-testid': testId };
  }, [stringId, testId]);

  return (
    <StyledAntButton
      size={size}
      type={type}
      disabled={disabled}
      $suppressDisabledStyling={suppressDisabledStyling}
      {...props}
      {...dataAttributes}
    >
      {stringId ? <TranslateString stringId={stringId} /> : children}
    </StyledAntButton>
  );
};
