import { isUndefined } from 'lodash';
import React from 'react';
import { useLocalizationContext } from '../../localization';
import { parse } from './parse';

function sanitize(str, decimalSeparator) {
  if (!str) return '';

  const regex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');

  // Ensure only numbers and decimal separator
  const sanitized = str.replace(regex, '');

  // Remove all but first decimal separator
  return sanitized.split(decimalSeparator).reduce((prev, curr, i) => prev + (i === 1 ? decimalSeparator : '') + curr);
}

export const useNumberInput = ({
  addOnAfter,
  value,
  onFocus,
  onBlur,
  onChange,
  testId,
  minValue,
  maxValue,
  addonAfter,
  decimalPlaces,
  ...props
}) => {
  const [_value, setValue] = React.useState(value || '');
  const [formattedValue, setFormattedValue] = React.useState('');
  const { userLocale } = useLocalizationContext();
  const [mode, setMode] = React.useState('read');

  const decimalSeparator = React.useMemo(() => {
    const number = 1.1;
    const separator = Intl.NumberFormat(userLocale)
      .formatToParts(number)
      .find(part => part.type === 'decimal').value;

    return separator;
  }, [userLocale]);

  React.useEffect(() => {
    if (mode === 'edit') return;

    const options = isUndefined(decimalPlaces)
      ? {}
      : { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces };

    const formatted = Intl.NumberFormat(userLocale, options).format(value);

    if (formatted === 'NaN') {
      setValue('');
      setFormattedValue('');
      return;
    }

    const sanitizedValue = sanitize(formatted, decimalSeparator);

    const formattedValue = Intl.NumberFormat(userLocale, options).format(sanitizedValue.replace(decimalSeparator, '.'));

    setValue(sanitizedValue);
    setFormattedValue(formattedValue);
  }, [decimalPlaces, decimalSeparator, mode, props.formatOptions, userLocale, value]);

  const handleChange = React.useCallback(
    e => {
      const strValue = e.target.value;

      const number = parse(userLocale, strValue);

      if (number > maxValue) {
        e.preventDefault();
        return;
      }

      if (strValue?.split?.(decimalSeparator)?.[1]?.length > decimalPlaces) {
        e.preventDefault();
        return;
      }

      const sanitizedValue = sanitize(strValue, decimalSeparator);

      setValue(sanitizedValue);

      const lastCharacter = strValue?.slice?.(sanitizedValue.length - 1);
      if (lastCharacter !== decimalSeparator) {
        onChange?.(parse(userLocale, sanitizedValue));
      }
    },
    [decimalPlaces, decimalSeparator, maxValue, onChange, userLocale]
  );

  const handleFocus = React.useCallback(
    e => {
      setMode('edit');
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = React.useCallback(
    e => {
      setMode('read');

      onChange?.(parse(userLocale, _value));
      setTimeout(() => {
        onBlur?.(e);
      }, 100);
    },
    [_value, onBlur, onChange, userLocale]
  );

  const displayValue = React.useMemo(() => {
    if (mode === 'edit') {
      return _value;
    }

    return formattedValue;
  }, [_value, formattedValue, mode]);

  return {
    lang: userLocale,
    onChange: handleChange,
    value: displayValue,
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...props,
  };
};
