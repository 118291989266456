import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../core';
import { useKeypad } from '../useKeypad';
import { useKeypress } from './useKeypress';

const Styled = styled.div`
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  .value-label {
    width: 290px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 35px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    font-size: 1.4em;
    font-weight: bold;
    padding: 0 10px;
    .value {
      flex: 1;
    }
    .label {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .addonAfter {
    display: flex;
    justify-content: center;
    margin: 3px;
    .addonAfter-inner {
      border: 1px solid #ccc;
      height: 16px;
      border-radius: 8px;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
    }
  }

  .keys {
    width: 300px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto auto;
    .key {
      user-select: none;
      border: 1px solid var(--keypad-color-border);
      border-radius: 4px;
      margin: 5px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;
      font-weight: bold;
      width: 90px;
      color: var(--colors.color_gray_700);
      background-color: var(--keypad-color-background);

      &:not(.number) {
        background-color: var(--keypad-alt-color-background);
        border-color: var(--keypad-alt-color-border);
      }

      &.backspace {
        font-size: 1.4em;
      }
    }
  }
  .actions {
    margin: 20px 5px;
    display: flex;
    & > * {
      &:first-child {
        flex: 1;
      }
    }
  }
`;
export const Keypad = ({ value = 0, onChange, addonAfter }) => {
  const { displayValue, decimalSeparator, handleChange, handleSave, handleCancel } = useKeypad({ value, onChange });

  const handleKeypress = React.useCallback(
    e => {
      let key = e.key;
      const valid = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', '.', ',', 'Enter'];

      if (!valid.includes(e.key)) return;

      if (key === 'Enter') {
        handleSave();
        return;
      }

      if ('.,'.includes(key)) {
        key = 'decimalSeparator';
      }
      handleChange(key);
    },
    [handleChange, handleSave]
  );

  useKeypress(handleKeypress);

  return (
    <Styled className={cn('keypad')}>
      <div className="value-label">
        <div className="value">{displayValue}</div>
        <div className="label">{addonAfter}</div>
      </div>

      <div className="keys">
        <button data-testid="seven-button" className="key number" onClick={() => handleChange('7')}>
          7
        </button>
        <button data-testid="eight-button" className="key number" onClick={() => handleChange('8')}>
          8
        </button>
        <button data-testid="nine-button" className="key number" onClick={() => handleChange('9')}>
          9
        </button>
        <button data-testid="four-button" className="key number" onClick={() => handleChange('4')}>
          4
        </button>
        <button data-testid="five-button" className="key number" onClick={() => handleChange('5')}>
          5
        </button>
        <button data-testid="six-button" className="key number" onClick={() => handleChange('6')}>
          6
        </button>
        <button data-testid="one-button" className="key number" onClick={() => handleChange('1')}>
          1
        </button>
        <button data-testid="two-button" className="key number" onClick={() => handleChange('2')}>
          2
        </button>
        <button data-testid="three-button" className="key number" onClick={() => handleChange('3')}>
          3
        </button>
        <button data-testid="backspace-button" className="key backspace" onClick={() => handleChange('Backspace')}>
          <i className="fa-regular fa-delete-left"></i>
        </button>
        <button data-testid="zero-button" className="key number" onClick={() => handleChange('0')}>
          0
        </button>
        <button data-testid="decimal-button" className="key decimal" onClick={() => handleChange('decimalSeparator')}>
          {decimalSeparator}
        </button>
      </div>
      <div className="actions">
        <div>
          <Button stringId="cancel" data-testid="cancel-button" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div>
          <Button type="primary" stringId="save" data-testid="save-button" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Styled>
  );
};
