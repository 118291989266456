import { http } from 'connex-cds';

export default {
  setupCompany: ({ entityRef }) => http.post({ apiName: 'cxp', path: `/admin/${entityRef}/setup` }),
  webhooks: ({ entityRef }) => http.get({ apiName: 'cxp', path: `/${entityRef}/hooks?activeOnly=false` }),
  getOrderManagement: ({ entityRef }) => http.get({ apiName: 'cxp', path: `/admin/${entityRef}/service-settings` }),
  saveOrderManagement: ({ entityRef, orderManagement }) =>
    http.post({
      apiName: 'cxp',
      path: `/admin/${entityRef}/service-settings`,
      data: {
        orderManagement,
      },
    }),
  saveHideBatchWeights: ({ entityRef, hideBatchWeights }) =>
    http.post({
      apiName: 'cxp',
      path: `/admin/${entityRef}/service-settings`,
      data: {
        hideBatchWeights,
      },
    }),
  saveDispatchSystem: ({ entityRef, dispatchSystems }) =>
    http.post({
      apiName: 'cxp',
      path: `/admin/${entityRef}/service-settings`,
      data: {
        dispatchSystems,
      },
    }),
  saveWillCallNotifications: ({ entityRef, willCallNotifications }) =>
      http.post({
        apiName: 'cxp',
        path: `/admin/${entityRef}/service-settings`,
        data: {
          willCallNotifications,
        },
      }),
  
};
