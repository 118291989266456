import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 5px;

  
  
`;
