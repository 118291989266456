import React from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { cacheItem, getCachedItem } from './cache';

export const useDefaultRoute = (defaultRoute, isEntityRoute = true) => {
  const lastKnownRoute = React.useRef(getCachedItem('lastKnownRoute'));
  const location = useLocation();

  React.useEffect(() => {
    cacheItem('lastKnownRoute', location?.pathname);
  }, [location?.pathname]);

  if (!lastKnownRoute.current) {
    return defaultRoute;
  }

  const matched = matchPath(isEntityRoute ? '/:entityRef/*' : '*', lastKnownRoute.current);

  const matchedParams = matched?.params?.['*'];

  return matchedParams || defaultRoute;
};
