import { Input as AntInput, InputNumber as AntInputNumber } from 'antd';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const { TextArea } = AntInput;

const StyledAntInput = styled(AntInput)`
  ${style}
`;
const StyledAntInputNumber = styled(AntInputNumber)`
  ${style}
`;

const StyledAntTextArea = styled(TextArea)`
  ${style}
`;

export const Input = React.forwardRef(({ onChange, testId, numeric, multiline, ...props }, ref) => {
  const dataTestId = { 'data-testid': testId };
  const handleChange = React.useCallback(
    e => {
      onChange(e?.target?.value);
    },
    [onChange]
  );

  const handleChangeNumber = React.useCallback(
    e => {
      onChange(e.target.value?.replace(/[^0-9.,]/g, ''));
    },
    [onChange]
  );

  const numericOnly = React.useCallback(e => {
    const other = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Shift', 'Meta', 'Alt', 'Control'];
    const valid = '1234567890.';

    if (!other.includes(e.key) && !valid.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  if (multiline) {
    return (
      <StyledAntTextArea ref={ref} rows={4} {...props} onChange={handleChange} {...dataTestId} $multiline={multiline} />
    );
  }

  return numeric ? (
    <StyledAntInput ref={ref} {...props} onChange={handleChangeNumber} {...dataTestId} onKeyDownCapture={numericOnly} className="adsf" />
  ) : (
    <StyledAntInput ref={ref} {...props} onChange={handleChange} {...dataTestId} />
  );
});
