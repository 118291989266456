import React from 'react';
import { usePostMessageContext } from '../../post-message/PostMessage';

export const useNotification = () => {
  const { sendMessage } = usePostMessageContext();

  const send = React.useCallback(
    ({ severity, notificationType = 'toast', message, options }) => {
      sendMessage({
        type: 'notification',
        payload: {
          severity,
          notificationType,
          messageText: message,
          options,
        },
      });
    },
    [sendMessage]
  );

  const success = React.useCallback(
    (message, options) => {
      send({ severity: 'success', message, options });
    },
    [send]
  );

  const error = React.useCallback(
    (message, options) => {
      send({ severity: 'error', message, options });
    },
    [send]
  );

  const info = React.useCallback(
    (message, options) => {
      send({ severity: 'info', message, options });
    },
    [send]
  );

  return { success, error, info };
};
