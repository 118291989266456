export const PRODUCTION_REGIONS = ['us', 'eu'];

const parseRegion = hostname => {
  const split = hostname.split('.');
  return split[0].startsWith('localhost') ? 'localhost' : split[1];
};

export const isProduction = (url = window?.location?.href) => {
  const protocolSplit = url.split('//');
  const region = parseRegion(protocolSplit[1]);
  return PRODUCTION_REGIONS.includes(region);
};
