import * as React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, Input, InputAdornment, ListItemIcon } from '@mui/material';
import { CompanyProfile } from '../../../company-profile/CompanyProfile';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { ListItemStyled, ListStyled } from './styles';
import { useTranslateMessage } from '../../../../../components/localization';

const EntityList = ({ entities, onSearch, searchTerm, onEntityChange, onClearSearch, selectedEntity }) => {
  const translateMessage = useTranslateMessage();
  return (
    <ListStyled aria-label="contacts">
      <ListItem>
        <Input
          inputProps={{ autofocus: true }}
          value={searchTerm}
          onChange={onSearch}
          placeholder={translateMessage('search')}
          fullWidth
          autoFocus
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {searchTerm?.length > 0 && (
                <IconButton aria-label="clear search" onClick={onClearSearch}>
                  <ClearOutlined />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </ListItem>
      {entities
        .filter(item => item.id !== selectedEntity.id)
        .map(({ id, label }) => (
          <ListItemStyled key={id} disablePadding>
            <ListItemButton onClick={() => onEntityChange(id)}>
              <ListItemIcon>
                <CompanyProfile size={30} />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItemStyled>
        ))}
    </ListStyled>
  );
};

export default EntityList;
