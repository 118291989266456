import React from 'react';

export const useClickOutside = handler => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleEvent = event => {
      if (!ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleEvent);

    return () => {
      document.removeEventListener('mousedown', handleEvent);
    };
  }, [handler]);


  return ref;
};
