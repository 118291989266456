import { css } from 'styled-components';

export default css`
  flex: 1;
  margin-top: 20px;
  .buttons {
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      display: flex;
      gap: 10px;
    }
  }
`;
