export const buildCustomerRBAC = selectedCustomers => {
  const customers = [];
  for (let cust of selectedCustomers) {
    customers.push({
      customerRef: cust?.customerRef || cust?.crn,
      projects: cust?.projects?.map(project => {
        if (project?.type === 'projectRef') {
          return { projectId: project.id, projectRef: project?.crn };
        }
        return { projectId: project.id };
      }),
    });
  }
  return customers;
};
