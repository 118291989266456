import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Setup } from '../api';

export const useCompanySetup = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['setup', entityRef],
    queryFn: () => Setup.setupCompany({ entityRef }),
    staleTime: Infinity,
    enabled: false,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

/**
 * Hook for get the order-management settings
 * @returns A query with the order-management settings
 */
export const useGetOrderManagement = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['getOrderManagement', entityRef],
    queryFn: () => Setup.getOrderManagement({ entityRef }),
    staleTime: Infinity,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  // Validating if the order-management settings exist in the current office
  if (query.isError && query.error.status === 404) {
    return {
      data: {
        orderManagement: false,
      },
    };
  }

  return query;
};

export const useGetWillCallNotifications = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['getWillCallNotifications', entityRef],
    queryFn: () => Setup.getWillCallNotifications({ entityRef }),
    staleTime: Infinity,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  // Validating if the order-management settings exist in the current office
  if (query.isError && query.error.status === 404) {
    return {
      data: {
        willCallNotifications: false,
      },
    };
  }

  return query;
};

export const useSaveOrderManagement = orderManagement => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['saveOrderManagement', entityRef];

  const mutation = useMutation({
    mutationFn: orderManagement => {
      return Setup.saveOrderManagement({ entityRef, orderManagement });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['getOrderManagement'] });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};

export const useSaveHideBatchWeights = hideBatchWeights => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['hideBatchWeights', entityRef];

  const mutation = useMutation({
    mutationFn: hideBatchWeights => {
      return Setup.saveHideBatchWeights({ entityRef, hideBatchWeights });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['getOrderManagement'] });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};

export const useSaveWillCallNotifications = willCallNotifications => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['willCallNotifications', entityRef];

  const mutation = useMutation({
    mutationFn: willCallNotifications => {
      return Setup.saveWillCallNotifications({ entityRef, willCallNotifications });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['getOrderManagement'] });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};

export const useSaveDispatchSystem = dispatchSystem => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['saveDispatchSystem', entityRef];

  const mutation = useMutation({
    mutationFn: dispatchSystems => {
      return Setup.saveDispatchSystem({ entityRef, dispatchSystems });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['getOrderManagement'] });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};
