export default {
    newVersionAvailable: 'Nova versão disponível',
    versionInfo: 'Uma nova versão de {appName} está disponível. Por favor, recarregue a página para atualizar para a versão {version}.',
    reload: 'Recarregar',
    later: 'Mais tarde',
    intl_addr_country: 'País',
    intl_addr_address1: 'Endereço',
    intl_addr_address2: 'Endereço 2',
    intl_addr_address3: 'Endereço 3',
    intl_addr_address4: 'Endereço 4',
    intl_addr_city: 'Cidade',
    intl_addr_state: 'Estado',
    intl_addr_zipCode: 'Código Postal',
    intl_addr_longitude: 'Longitude',
    intl_addr_latitude: 'Latitude',
    intl_addr_department: 'Departmento',
    intl_addr_suburb: 'Subúrbio',
    intl_addr_postalCode: 'Código Postal',
    intl_addr_province: 'Província',
    intl_addr_neighborhood: 'Bairro',
  };