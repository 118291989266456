export const EQUAL_TO = 0;
export const GREATER_THAN = 1;
export const LESS_THAN = -1;

const compareVersions = (v1, v2) => {
  // Split the version strings into parts
  const parts1 = v1.split('.').map(Number);
  const parts2 = v2.split('.').map(Number);

  // loop through version with the most parts
  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    // If one version is missing a part, consider it as 0
    const num1 = i < parts1.length ? parts1[i] : 0;
    const num2 = i < parts2.length ? parts2[i] : 0;

    // Compare each part and return the result if not equal
    if (num1 > num2) return 1;
    if (num2 > num1) return -1;
  }

  // If all parts are equal, return 0
  return 0;
};

export default (v1, operator, v2) => {
  const result = compareVersions(v1, v2);

  if (operator === EQUAL_TO) return result === 0;
  if (operator === GREATER_THAN) return result === 1;
  if (operator === LESS_THAN) return result === -1;

  return false;
};
