import React from 'react';
import { get } from 'lodash';
import HeaderRenderer from '../HeaderRenderer';

export const getHeaderRenderer = (filterable, col) => props =>
  <HeaderRenderer filterable={filterable} column={col} headerProps={props} />;

export const getFormatter = (col, rowKeyPath) => props => {
  return (
    <div
      className="cell"
      data-testclass={`cell-column-${col.key}`}
      data-testid={`row-${props.row[rowKeyPath]}-column-${col.key}`}
    >
      {col.getValue?.(props.row) || get(props.row, col.key, '')}
    </div>
  );
};
