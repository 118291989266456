import { omit } from 'lodash';
import React from 'react';
import { FIELD_TYPES } from '../fieldTypes';

export const useListConfig = ({ listQuery, config }) => {
  const formConfig = React.useMemo(() => {
    // We're leveraging the DynamicForm stuff to generate the grid and other components, but the
    // incoming config is a simplified version of what's required by the FormProvider.  This transforms
    // that incoming config to meet those requirements.
    return {
      fields: [
        {
          ...omit(config, 'fields'),
          dataType: FIELD_TYPES.array,
          arrayOf: {
            fields: config.fields,
          },
        },
      ],
    };
  }, [config]);

  return {
    formConfig,
    ...listQuery,
  };
};
