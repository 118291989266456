import { css } from 'styled-components';

export default css`
  width: 100%;
  padding: 0;

  .layout-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;

    > .company-setup-card {
      margin: 10px auto;
      text-align: center;
      width: 40%;
      cursor: default;
      height: 280px;
      border-radius: 20px;

      > .setup-card-header {
        border-bottom: 1px solid #ccc;
      }

      > .setup-card-content {
        margin: 50px auto;
      }
    }

    > .webhooks-card {
      margin: 10px auto;
      text-align: center;
      width: 300px;
      cursor: default;
    }

    .item-type-id {
      font-size: 1.3em;
      font-weight: 600;
    }

    .active-status {
      font-size: 3em;
      color: #04b431;
    }

    .inactive-status {
      font-size: 3em;
      color: #df0101;
    }
  }

  .notifications-card {
    border-radius: 20px;
    box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 0px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 20px auto;
    padding: 40px 20px;
    width: 95%;

    > .notifications-card-header-container {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      > .notifications-card-header .MuiCardHeader-content .MuiCardHeader-title {
        font-weight: 500;
      }
    }

    > .MuiCardContent-root .notifications-card-table {
      border: 1px solid #ccc;
      border-radius: 15px;

      > table thead .notifications-card-table-columns-name th {
        font-weight: bold;
      }

      > table tbody td .notifications-card-items-options {
        cursor: pointer;
      }

      > table tbody td .notifications-card-option-container {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }

  .notification-will-call-confirmation-notification-toggle {
    border-radius: 15px;
    display: flex;
    margin: auto;
    padding: 25px 10px;
    width: 50%;
  }
  }
`;
