import { http } from 'connex-cds';

export default {
  listProjects: ({ entityRef, customerId }) =>
    customerId
      ? http
          .get({ apiName: 'cxp', path: `/${entityRef}/projects?customerIds=${customerId}` })
          .then(response => response?.items)
      : http.get({ apiName: 'cxp', path: `/${entityRef}/projects` }).then(response => response?.items),
};
