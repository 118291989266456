import React from 'react';
import required from '../../../util/validation/required';
import { Button } from '../../core';
import { useNotification } from '../../notification';
import { useFormContext } from './FormProvider';

export const SubmitButton = ({
  stringId,
  testId: testIdDeprecated,
  'data-testid': dataTestId,
  suppressDisabledStyling,
  disabledWhenNotDirty = true,
  onClick,
  ...props
}) => {
  required({ stringId });
  const formContext = useFormContext();
  const { isValid, isSubmitting, dirty, resetForm, values, onSubmit, setSubmitting } = formContext;

  const notify = useNotification();

  const _handleSubmit = React.useCallback(() => {
    // Not using formik's 'handleSubmit' because the onSubmit handler
    // would need to know about and call resetForm().

    // Might need to emulate the formik form submission process (setting all fields to touched, etc)

    const submitResponse = onSubmit(values, formContext);

    if (submitResponse instanceof Promise) {
      setSubmitting(true);
      submitResponse
        .then(response => {
          resetForm({ values });
          onClick?.(response);
        })
        .catch(() => {
          notify.error('An error has occurred.');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      onClick?.(submitResponse);
    }
  }, [formContext, onClick, onSubmit, resetForm, setSubmitting, values]);

  const testId = React.useMemo(() => {
    return dataTestId || testIdDeprecated;
  }, [dataTestId, testIdDeprecated]);

  return (
    <Button
      type="primary"
      size="large"
      stringId={stringId}
      onClick={_handleSubmit}
      disabled={!isValid || (!dirty && disabledWhenNotDirty)}
      loading={isSubmitting}
      data-testid={testId || 'submit-button'}
      suppressDisabledStyling={suppressDisabledStyling}
      {...props}
    />
  );
};
