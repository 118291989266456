const green = {
  10: '#005D29',
  20: '#067D3D',
  30: '#138E48',
  40: '#1EA054',
  50: '#27AF5D',
  60: '#4FBB75',
  70: '#71C78D',
  80: '#9BD6AD',
  90: '#C2E6CD',
  95: '#E6F5EB',
  100: '#FFFFFF',
};

export default green;
