import styled from '@emotion/styled';
import { Button, Dialog as MaterialDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { Translate } from '../../components/localization';
import { cacheItem } from '../../util/cache';
import style from './style';

const Dialog = styled(MaterialDialog)`
  ${style}
`;

export const VersionDialog = ({ versionInfo, ...props }) => {
  const { onClose } = props;

  const handleReload = React.useCallback(() => {
    cacheItem(
      'version',
      JSON.stringify({ ...versionInfo, timestamp: DateTime.now().toUTC().toISO(), action: 'reload' })
    );
    window?.location?.reload?.();
  }, [versionInfo]);

  const handleLater = React.useCallback(() => {
    cacheItem(
      'version',
      JSON.stringify({ ...versionInfo, timestamp: DateTime.now().toUTC().toISO(), action: 'later' })
    );
    onClose();
  }, [onClose, versionInfo]);

  return (
    <Dialog className={cn('version-dialog')} {...props}>
      <DialogTitle>
        <Translate stringId="cai.newVersionAvailable" data-testid="new-version-available" />
      </DialogTitle>
      <DialogContent>
        <Translate
          stringId="cai.versionInfo"
          data-testid="version-info"
          values={{ version: versionInfo?.version, appName: 'Deliveries' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLater}>
          <Translate stringId="cai.later" data-testid="later-button" />
        </Button>
        <Button variant="contained" onClick={handleReload}>
          <Translate stringId="cai.reload" data-testid="reload-button" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
