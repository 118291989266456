import { css } from '@emotion/react';
import { List, ListItem } from '@mui/material';
import styled from '@emotion/styled';

export const ListStyled = styled(List)`
  ${({ theme }) => css`
    width: 100%;
    min-width: 310px;
    background-color: ${theme.validateMode({
      light: theme.colorsCA.neutral[100],
      dark: '#36323E',
    })};
  `}
`;

export const ListItemStyled = styled(ListItem)`
  ${({ theme }) => css`
    .MuiListItemIcon-root {
      min-width: 46px;
    }
  `}
`;
