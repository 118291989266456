import React, { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { Global } from '@emotion/react';

import { useThemeContext } from '../../components/theme';
import getDesignConfig from '../../mui-theme/modes/config';
import { MuiGlobalStyles } from './MuiGlobalStyles';

const MuiThemeProvider = ({ children }) => {
  const { themeName } = useThemeContext();

  const theme = useMemo(() => createTheme(getDesignConfig(themeName)), [themeName]);
  const globalStyles = MuiGlobalStyles({ theme: theme });

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
