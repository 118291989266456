import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

export const CompanyProfileStyled = styled(Avatar)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${theme.colorsCA.darkBlue[60]};
    cursor: pointer;
    color: ${theme.colorsCA.neutral[100]};
    text-align: center;
    font-size: 16px;
    font-weight: 600;

    .MuiSvgIcon-root {
      color: ${theme.validateMode({
        light: theme.colorsCA.darkBlue[20],
        dark: theme.colorsCA.darkBlue[80],
      })};
    }
  `}
`;
