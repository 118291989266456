import React from 'react';
import { Tabs as CoreTabs } from '../../core/tabs/Tabs';
import { useIsFieldAllowed } from '../useIsFieldAllowed';

export const Tabs = props => {
  const isFieldAllowed = useIsFieldAllowed();

  const { config, ...otherProps } = props;

  const allowedTabsConfig = React.useMemo(() => {
    // We can filter out not-allowed tabs.
    // const tabs = config?.tabs?.filter?.(tab => isFieldAllowed(tab));

    // Or disabled them
    const tabs = config?.tabs?.map?.(tab => (isFieldAllowed(tab) ? tab : { ...tab, disabled: true }));
    return { ...config, tabs };
  }, [config, isFieldAllowed]);

  return <CoreTabs config={allowedTabsConfig} {...otherProps} />;
};
