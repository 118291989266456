// Dutch

export default {
  newVersionAvailable: 'Nieuwe versie beschikbaar',
  versionInfo: 'Er is een nieuwe versie van {appName} beschikbaar. Herlaad de pagina om de nieuwe versie {version} te gebruiken.',
  reload: 'Herlaad',
  later: 'Later',
  intl_addr_country: 'Land',
  intl_addr_address1: 'Adres',
  intl_addr_address2: 'Adres 2',
  intl_addr_address3: 'Adres 3',
  intl_addr_address4: 'Adres 4',
  intl_addr_city: 'Stad',
  intl_addr_state: 'Staat',
  intl_addr_zipCode: 'Postcode',
  intl_addr_longitude: 'Lengtegraad',
  intl_addr_latitude: 'Breedtegraad',
  intl_addr_department: 'Afdeling',
  intl_addr_suburb: 'Voorstad',
  intl_addr_postalCode: 'Postcode',
  intl_addr_province: 'Provincie',
  intl_addr_neighborhood: 'Buurt',
};
