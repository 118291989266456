import { Core } from 'connex-cds';
import { Divider, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import style from './style';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import { NotificationsListTable } from './notifications-components/NotificationsListTable';
import { CreateNewReminderModal } from './notifications-components/CreateNewReminderModal';
import { WillCallConfirmationNotifications } from './notifications-components/WillCallConfirmationNotifications';

export const Notifications = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const Styled = styled.div`
    ${style}
  `;

  const goBack = useCallback(() => {
    navigate('..');
  }, [navigate]);

  const openCreateNewReminder = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  // DUMMY DATA
  const columns = ['Customer', 'Delivery', 'Type', 'Time Frame'];
  const rows = [
    {
      id: '1t2yt2y212s1fsdfdghsdh',
      data: ['customerA', 'push, SMS', 'Order Confirmation', '72hr, 24hr'],
    },
    {
      id: '5koi95ifj059jfr889',
      data: ['customerA', 'push, SMS', 'Order Confirmation', '72hr, 24hr'],
    },
    {
      id: 'kasfo9fkfmkmfk32650',
      data: ['customerA', 'push, SMS', 'Order Confirmation', '72hr, 24hr'],
    },
    {
      id: 'kasfo9fkf85332k000',
      data: ['customerA', 'push, SMS', 'Order Confirmation', '72hr, 24hr'],
    },
    {
      id: 'kasfo9fkfmkgtt800',
      data: ['customerA', 'push, SMS', 'Order Confirmation', '72hr, 24hr'],
    },
    {
      id: 'kasfo9fkfm2355hgfr',
      data: ['customerA', 'push, SMS', 'Order Confirmation', '72hr, 24hr'],
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: '100%', padding: '20px 0px' }}>
        <Core.Button size="small" type="primary" stringId="back" data-testid="back-button" onClick={goBack} />
        <Divider orientation="left">
          <FormattedMessage id={'notificationPreferences'} defaultMessage={'notificationPreferences'} />
        </Divider>

        <WillCallConfirmationNotifications />

        {/* Commented until the team decide */}
        {/* <Styled>
          <Card className="notifications-card" hoverable="true">
            <Box className="notifications-card-header-container">
              <CardHeader
                className="notifications-card-header"
                title={<FormattedMessage id={'notificationsMainTitle'} defaultMessage={'notificationsMainTitle'} />}
              />
              <Button variant="contained" style={{ marginRight: '20px' }} onClick={openCreateNewReminder}>
                <FormattedMessage id={'notificationsCreateNewButton'} defaultMessage={'notificationsCreateNewButton'} />
              </Button>
            </Box>
            <CardContent>
              <NotificationsListTable key="notificationList" columns={columns} rows={rows} />
            </CardContent>
          </Card>
          <CreateNewReminderModal key="reminderModal" open={openModal} handleCloseModal={handleClose} />
        </Styled> */}
      </Space>
    </>
  );
};
