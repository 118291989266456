import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useLocalizationContext } from '../../LocalizationProvider';

const Styled = styled.div`
  display: inline;
  position: relative;

  .inner-container {
    cursor: pointer;
    border: 1px dashed #999;
    border-radius: 4px;
    padding-top: 0;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: -3px;
    margin-top: -1px;
    background-color: #ddd;
    color: #000;
    &:hover {
      color: var(--color-text-button-primary);
      border-color: var(--color-background-button-primary);
      border-style: solid;
      background-color: var(--color-background-button-primary);
    }
  }
`;

const TranslateString = ({ stringId, values, defaultMessage }) => {
  const { editMode } = useLocalizationContext();

  const handleClick = React.useCallback(e => {
    if (!e.shiftKey) {
      e.stopPropagation();
    }
  }, []);

  if (!editMode) return <FormattedMessage id={stringId} values={values} />;

  return (
    <Styled onClick={handleClick}>
      <span className="inner-container">
        <FormattedMessage id={stringId} values={values} defaultMessage={defaultMessage} />
      </span>
    </Styled>
  );
};

export default TranslateString;
