import React from 'react';

import { usePostMessageContext } from '../../post-message/PostMessage';
import { getCachedItem } from '../../util/cache';
import { usePostMessageListener } from '../../util/entity-provider/usePostMessageListener';
import { API } from '../api';
import { UserContext } from './UserContext';

export const USER_KEY = '73804c78-c9d6-4673-8ad0-b96d1ef08ff2';

export const UserProvider = ({ children }) => {
  const cachedUser = getCachedItem(USER_KEY);
  const [user, setUser] = React.useState(cachedUser ? JSON.parse(cachedUser) : null);
  const [role, setRole] = React.useState();
  const [userApps, setUserApps] = React.useState();
  const [menuConfig, setMenuConfig] = React.useState();
  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    sendMessage({ type: 'req-user-context' });
  }, [sendMessage]);

  const handleMessageEvent = React.useCallback(
    message => {
      if (message.type === 'user-context') {
        const { user, role, userApps } = message;
        setUser(user);
        setRole(role);
        setUserApps(userApps);
        API.setLoginToken(message?.user?.access_token);
      }
      if (message.type === 'user-apps') {
        setUserApps(message.userApps);
      }
    },
    [setUserApps]
  );

  usePostMessageListener(handleMessageEvent);

  const hasPermission = React.useCallback(
    permission => {
      return role?.permissions?.[permission] || false;
    },
    [role?.permissions]
  );

  const permissions = React.useMemo(() => {
    return Object.keys(role?.permissions || {}).filter(key => !!role?.permissions?.[key]);
  }, [role?.permissions]);

  return (
    <UserContext.Provider
      value={{ user, role, userApps, setRole, hasPermission, permissions, menuConfig, setMenuConfig }}
    >
      {children}
    </UserContext.Provider>
  );
};
