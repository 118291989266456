import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'users',
  labelStringId: 'users',
  testId: 'users',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    username: Yup.string().required('required'),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    phone: Yup.number().required('required'),
  }),

  fields: [
    {
      path: 'username',
      labelStringId: 'emailOrPhoneNumber',
      hidden: true,
      allowedModes: Form.MODES.CREATE,
      testId: 'username',
      dataType: Form.FIELD_TYPES.string,
    },
    { path: 'lastName', labelStringId: 'family_name', testId: 'family_name', dataType: Form.FIELD_TYPES.string },
    { path: 'firstName', labelStringId: 'given_name', testId: 'given_name', dataType: Form.FIELD_TYPES.string },
    { path: 'email', labelStringId: 'email', testId: 'email', dataType: Form.FIELD_TYPES.string },
    {
      path: 'phone',
      labelStringId: 'invitePhoneNumber',
      testId: 'phone',
      dataType: Form.FIELD_TYPES.number,
      hidden: true,
      maxLength: 13,
    },
    {
      path: 'role',
      valuePath: 'roleRef',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'label',
      labelStringId: 'role',
      testId: 'role',
    },
    {
      path: 'customer',
      hidden: true,
      valuePath: 'customerRef',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'customer',
      testId: 'customer',
    },
    {
      path: 'project',
      hidden: true,
      valuePath: 'projectRef',
      displayPath: 'description',
      dataType: Form.FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'crn',
      listDisplayPath: 'name',
      labelStringId: 'project',
      testId: 'project',
    },
  ],
};
