import styled from '@emotion/styled';
import { css } from 'styled-components';

export const HeaderContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    transition: height 0.3s ease;
    .header {
      display: flex;
      padding: 20px 20px 20px 10px;
      color: var(--color-text-page); //#605d62; //

      .left {
        .feature-name {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          font-size: clamp(1.25rem, 0.625rem + 2.5vw, 2.5rem);
          font-style: normal;
          line-height: normal;
        }

        .feature-icon {
          width: 36px;
          height: 36px;
        }
      }

      & > .right {
        flex: 1;
        text-align: right;
        .center-aligned {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;
          .mobile-menu-icon {
            display: none;
          }
        }
      }
    }
    &.is-mobile {
      @media screen and (max-width: 1200px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100vw;
        background: ${theme.validateMode({
          light: `#F8FDFF`,
          dark: `linear-gradient(0deg, rgba(208, 188, 255, 0.11) 0%, rgba(208, 188, 255, 0.11) 100%), #1C1B1F`,
        })} !important;
        border-bottom: 1px solid var(--Surfaces-Dark-sys-light-outline-variant, #cac4d0);
        box-shadow: 0px 2px 4px 0px rgba(101, 119, 136, 0.2);
        .header {
          flex-direction: column-reverse;
          padding: 16px 24px;
          .left {
            padding-top: 14px;
            .feature-name {
              gap: 6px;
              padding-left: 8px;
              font-size: clamp(1.25rem, 0.625rem + 2.5vw, 2rem);
            }

            .feature-icon {
              width: 32px;
              height: 32px;
            }
          }
          .entity-picker {
            display: none;
          }
          .header-divider {
            height: 1px;
            background-color: #cac4d0;
            border: none;
            margin: 0 -30px 0 -30px;
            width: calc(100% + 60px);
          }
          & > .right {
            flex: 1;
            padding-bottom: 16px;
            .center-aligned {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .mobile-menu-icon {
                display: flex;
              }
            }
          }
        }
      }
      @media screen and (max-width: 768px) {
        .header {
          padding: 0.875rem 0.625rem 0.625rem 0.625rem;
          .left {
            padding-top: 12px;
            .feature-icon {
              width: 28px;
              height: 28px;
            }
          }
          .header-divider {
            margin: 0 -0.625rem 0 -0.625rem;
            width: calc(100% + 1.25rem);
          }
          & > .right {
            padding-bottom: 10px;
            .center-aligned {
            }
          }
        }
      }
      @media screen and (max-width: 430px) {
        .header {
          .left {
            .feature-icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      &.disable-mobile-sidebar {
        border-bottom: unset;
        box-shadow: unset;
        @media screen and (max-width: 1200px) {
          height: unset;
          .header {
            & > .right {
              .center-aligned {
                justify-content: end;
              }
            }
          }
        }
      }
    }
  `}
`;
