import { padStart } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { NumberInput } from '../number-input/NumberInput';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const MIN_MINUTE = 0;
const MAX_MINUTE = 59;

export const Minute = ({ minute, onChange, testId }) => {
  const [isEmptyString, setIsEmptyString] = React.useState(false);

  const displayMinute = React.useMemo(() => {
    return padStart(minute, 2, '0');
  }, [minute]);

  const handleIncrement = React.useCallback(() => {
    const newMinute = minute + 1;

    if (newMinute > MAX_MINUTE) {
      onChange(MIN_MINUTE, { rolledMaximum: true });
    } else {
      onChange(newMinute);
    }
  }, [minute, onChange]);

  const handleDecrement = React.useCallback(() => {
    const newMinute = minute - 1;

    if (newMinute < MIN_MINUTE) {
      onChange(MAX_MINUTE, { rolledMinimum: true });
    } else {
      onChange(newMinute);
    }
  }, [minute, onChange]);

  const handleChange = React.useCallback(
    e => {
      const val = e.target.value;

      if (val === '') {
        setIsEmptyString(true);
        onChange('EMPTY');
        return;
      }

      const numberValue = Number(val);
      if (numberValue >= MIN_MINUTE && numberValue <= MAX_MINUTE) {
        onChange(numberValue);
      }
      setIsEmptyString(false);
    },
    [onChange]
  );

  return (
    <Styled className={cn('time-part minute')}>
      <button data-testid={`${testId}-decrement-minute-button`} onClick={handleDecrement}>
        -
      </button>
      <div>
        <input
          data-testid={`${testId}-minute-input`}
          inputMode="numeric"
          value={isEmptyString ? '' : displayMinute}
          onChange={handleChange}
        />
      </div>
      <button data-testid={`${testId}-increment-minute-button`} onClick={handleIncrement}>
        +
      </button>
    </Styled>
  );
};
