import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import required from '../../../../../util/validation/required';
import { ConnexDataGrid } from '../../../../core';
import { useFormContext } from '../../../context-provider/FormProvider';
import { MODES } from '../../Array';
import { useArrayContext } from '../../context/ArrayContext';
import prepareConfig from './prepareConfig';

const Styled = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const GridListComponent = ({ onClick, gridName, queryObserver, queryData, rowKeyGetter }) => {
  required({ gridName });
  const [queryResult, setQueryResult] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = queryObserver?.subscribe?.(setQueryResult);
    return unsubscribe;
  }, [queryObserver]);

  React.useEffect(() => {
    setQueryResult(queryData);
  }, [queryData]);

  const { config, resetForm } = useFormContext();
  const { setMode, value, setActiveRowId, fieldConfig } = useArrayContext();

  const columns = React.useMemo(() => {
    return prepareConfig(config);
  }, [config]);

  const handleRowClick = React.useCallback(
    row => {
      setMode(MODES.EDIT);
      resetForm({ values: row });
      onClick?.(row);
      setActiveRowId(row?.___id);
    },
    [setMode, resetForm, onClick, setActiveRowId]
  );

  return (
    <Styled className={cn('grid-list-component')}>
      <ConnexDataGrid
        showFiltersByDefault
        loading={false}
        gridName={gridName}
        columns={columns}
        rows={queryResult?.data || queryResult || value}
        rowKeyPath={fieldConfig?.keyPath || 'id'}
        onRowClick={handleRowClick}
        filterable
        rowClickable
        autoSizeColumns
        rowKeyGetter={rowKeyGetter}
      />
    </Styled>
  );
};

/*

 */
