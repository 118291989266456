import { find } from 'lodash';
import React from 'react';
import { useLocalizationContext } from '../../../localization';

const sampleDate = new Date(2023, 1, 1, 15, 0, 0, 0);

export const usePickerOptions = () => {
  const { userLocale } = useLocalizationContext();

  const formatter = React.useMemo(
    () =>
      new Intl.DateTimeFormat(userLocale, {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
      }),
    [userLocale]
  );

  const is24Hour = React.useMemo(() => {
    const dateParts = formatter.formatToParts(sampleDate);

    const hour = find(dateParts, { type: 'hour' });

    return hour?.value === '15';
  }, [formatter]);

  const isDayPeriod = React.useMemo(() => {
    const dateParts = formatter.formatToParts(sampleDate);

    const dayPeriod = find(dateParts, { type: 'dayPeriod' });

    return !!dayPeriod;
  }, [formatter]);

  return { is24Hour, isDayPeriod };
};
