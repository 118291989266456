import { get } from 'lodash';
import React from 'react';

export const useCurrent = ({ config, options, value }) => {
  const current = React.useMemo(() => {
    const currentConfig = config?.[0];

    if (!currentConfig) {
      return [null, null];
    }

    const myPath = currentConfig?.path;

    const currentOptions = get(options, myPath);
    const currentValue = get(value, myPath, []);

    return { currentConfig, currentOptions, currentValue };
  }, [config, options, value]);

  return current;
};
