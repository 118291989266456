import React from 'react';
import { useViewport } from '../../hooks/useViewport';
import { useAppData } from '../app-data/AppData';

export const Responsive = ({ children, breakpoints: breakpointOverrides }) => {
  const { breakpoints: defaultBreakpoints } = useAppData();

  const childrenArray = React.useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  const dimensions = useViewport();

  const targetChildIndex = React.useMemo(() => {
    const breakpoints = [0, ...(breakpointOverrides || defaultBreakpoints)];

    const sortedBreakpoints = [...breakpoints].reverse().sort((a, b) => b - a);

    let targetBreakpoint;

    for (const breakpoint of sortedBreakpoints) {
      if (dimensions.width >= breakpoint) {
        targetBreakpoint = breakpoint;
        break;
      }
    }

    return breakpoints.indexOf(targetBreakpoint);
  }, [breakpointOverrides, defaultBreakpoints, dimensions.width]);

  return childrenArray[targetChildIndex];
};
