const orange = {
  10: '#DF4E10',
  20: '#E96811',
  30: '#F07812',
  40: '#F68714',
  50: '#FA9315',
  60: '#FBA22D',
  70: '#FCB350',
  80: '#FDC981',
  90: '#FEDEB3',
  95: '#FFF2E0',
  100: '#FFFFFF',
};

export default orange;
