import cn from 'classnames';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';
import { TimeZone, useLocalizationContext } from '../../localization';
import { Button } from '../button/Button';
import { DayPeriod } from './DayPeriod';
import { Hour } from './Hour';
import { Minute } from './Minute';
import { usePickerOptions } from './stories/usePickerOptions';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const TouchTimePicker = ({ value, defaultTimeZone, onChange, testId, rolloverDays }) => {
  const { timeZone } = useLocalizationContext();

  const initialDateTimeRef = React.useRef(DateTime.fromISO(value).setZone(defaultTimeZone || timeZone));
  const [dateTime] = React.useState(DateTime.fromISO(value).setZone(defaultTimeZone || timeZone));

  const [hour, setHour] = React.useState(dateTime.hour);
  const [minute, setMinute] = React.useState(dateTime.minute);
  const [dayPeriod, setDayPeriod] = React.useState(hour >= 12 ? 'PM' : 'AM');
  const [date, setDate] = React.useState(dateTime.startOf('day'));
  const [isHourValid, setIsHourValid] = React.useState(true);
  const [isMinuteValid, setIsMinuteValid] = React.useState(true);

  const daysDiff = React.useMemo(() => {
    return date.diff(initialDateTimeRef.current.startOf('day'), 'days');
  }, [date]);

  const { is24Hour, isDayPeriod } = usePickerOptions();
  const { userLocale } = useLocalizationContext();

  const isValid = React.useMemo(() => {
    return isHourValid && isMinuteValid;
  }, [isHourValid, isMinuteValid]);

  const incrementDay = React.useCallback(() => {
    if (!isUndefined(rolloverDays)) {
      setDate(s => s.plus({ days: 1 }));
    }
  }, [rolloverDays]);

  const decrementDay = React.useCallback(() => {
    if (!isUndefined(rolloverDays)) {
      setDate(s => s.minus({ days: 1 }));
    }
  }, [rolloverDays]);

  const handleHourChange = React.useCallback(
    (val, options) => {
      if (val !== 'EMPTY') {
        setHour(val);
        setIsHourValid(true);

        if (val < 12) {
          setDayPeriod('AM');
        } else {
          setDayPeriod('PM');
        }

        if (options?.rolledMaximum) {
          incrementDay();
        }
        if (options?.rolledMinimum) {
          decrementDay();
        }
      } else {
        setIsHourValid(false);
      }
    },
    [decrementDay, incrementDay]
  );

  const incrementHour = React.useCallback(() => {
    const MIN_HOUR = 0;
    const MAX_HOUR = 23;
    const newHour = hour + 1;

    if (newHour > MAX_HOUR) {
      handleHourChange(MIN_HOUR, { rolledMaximum: true });
    } else {
      handleHourChange(newHour, undefined);
    }
  }, [handleHourChange, hour]);

  const decrementHour = React.useCallback(() => {
    const MIN_HOUR = 0;
    const MAX_HOUR = 23;
    const newHour = hour - 1;

    if (newHour < MIN_HOUR) {
      handleHourChange(MAX_HOUR, { rolledMinimum: true });
    } else {
      handleHourChange(newHour, undefined);
    }
  }, [handleHourChange, hour]);

  const handleMinuteChange = React.useCallback(
    (val, options) => {
      if (val !== 'EMPTY') {
        setMinute(val);
        setIsMinuteValid(true);
        if (options?.rolledMaximum) {
          incrementHour();
        }
        if (options?.rolledMinimum) {
          decrementHour();
        }
      } else {
        setIsMinuteValid(false);
      }
    },
    [decrementHour, incrementHour]
  );

  const handleDayPeriodChange = React.useCallback(val => {
    setDayPeriod(val);
    setHour(h => {
      if (val === 'AM') {
        return h < 12 ? h : h - 12;
      }

      return h >= 12 ? h : h + 12;
    });
  }, []);

  const handleChange = React.useCallback(() => {
    const newDateTime = dateTime.set({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: hour,
      minute: minute,
    });
    onChange(newDateTime.toUTC().toISO());
  }, [date.day, date.month, date.year, dateTime, hour, minute, onChange]);

  return (
    <Styled className={cn('touch-time-picker', { wide: !!isDayPeriod })}>
      {isUndefined(rolloverDays) ? null : (
        <div data-testid="localized-date" className={cn('day', { changed: daysDiff.days !== 0 })}>
          {date.setLocale(userLocale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </div>
      )}
      <div className="time-entry">
        <Hour hour={hour} is24Hour={is24Hour} dayPeriod={dayPeriod} onChange={handleHourChange} testId={testId} />
        <div className="colon">
          <i className="fa-solid fa-colon" />
        </div>
        <Minute minute={minute} onChange={handleMinuteChange} testId={testId} />
        <DayPeriod
          value={dayPeriod}
          hour={hour}
          isDayPeriod={isDayPeriod}
          onChange={handleDayPeriodChange}
          testId={testId}
        />
      </div>
      <div className={cn('actions')}>
        <Button type="primary" onClick={handleChange} stringId="Submit" data-testid="ok-button" disabled={!isValid} />
      </div>
      <div className="time-zone">
        <span className="pill">
          <i className="icon fa-light fa-globe"></i>
          <span className="time-zone-text" data-testid="time-zone">
            <TimeZone date={dateTime} />
          </span>
        </span>
      </div>
    </Styled>
  );
};
