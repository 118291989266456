import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div``;

export const CollapsibleSidebar = ({ isOpen, onTrigger }) => {
  const handleToggle = React.useCallback(() => {
    onTrigger();
  }, [onTrigger]);
  return (
    <Styled className={cn('collapsible-sidebar', { open: isOpen })} onClick={handleToggle}>
      <div className="outer">
        <div className="inner">Collapsible Sidebar</div>
      </div>
      <div className="trigger">
        <i className="icon fa-solid fa-chevron-left" />
      </div>
    </Styled>
  );
};
