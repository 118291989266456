import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../localization';
import { Helper } from '../../../../typography';
import { useGridContext } from '../../context/context';

const Styled = styled.div`
  text-align: center;
`;

const GridCounts = props => {
  const { rows, totalRowCount } = useGridContext();
  return (
    <Styled className={cn('grid-counts')} {...props}>
      <Helper>
        <Translate stringId="displayRowCount" values={{ count: rows?.length }} data-testid="row-count" />
      </Helper>
      {/*{`Displaying ${rows?.length < totalRowCount ? `${rows?.length || 0} of ` : ''} ${totalRowCount} Rows`}*/}
    </Styled>
  );
};

export default GridCounts;
