const filter = {
  light: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  },
  dark: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  },
};

export default filter;
