import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { find } from 'lodash';
import { IntlProvider } from 'react-intl';

import { usePostMessageContext } from '../../post-message/PostMessage';
import mergeTimeZones from './mergeTimeZones';
import { useMergeMessages } from './useMergeMessages';
import { consolidateMessages } from './util/consolidateMessages';

const DEFAULT_LANGUAGES = ['en-GB', 'en-US', 'es-CO', 'fr-FR', 'pt', 'de'];
const DEFAULT_LANGUAGE = 'en-US';

export const LocalizationContext = React.createContext();

export const useLocalizationContext = (defaultValue = null) => {
  const context = useContext(LocalizationContext);
  if (!context && !defaultValue) {
    throw new Error('useLocalizationContext cannot be called outside of the LocalizationContext context provider');
  }
  return context || defaultValue;
};

const cachedLanguageId = localStorage.getItem('language');
console.log('cachedLanguageId', cachedLanguageId);

const browserLanguageId = navigator?.languages?.[0] || navigator?.language || DEFAULT_LANGUAGE;
console.log('browserLanguageId', browserLanguageId);

export const Localization = ({
  initialLocale,
  children,
  messages: msgs,
  isIframe,
  initialTimeZone = 'America/Chicago',
}) => {
  const [editMode, setEditMode] = useState(false);
  const [timeZone, setTimeZone] = useState(initialTimeZone);
  const [supportedLanguages, setSupportedLanguages] = useState(DEFAULT_LANGUAGES);
  const { messages: parentAppMessages } = useLocalizationContext({});
  const { sendMessage } = usePostMessageContext();

  const mergedAppMessages = useMemo(() => {
    const parentAppMessagesConslidated = consolidateMessages({ messages: parentAppMessages });
    const appMessagesConsolidated = consolidateMessages({ messages: msgs });

    const mergedMessagesConslidated = {};

    for (const locale in parentAppMessagesConslidated) {
      mergedMessagesConslidated[locale] = {
        ...appMessagesConsolidated[locale],
        ...parentAppMessagesConslidated[locale],
      };
    }

    for (const locale in appMessagesConsolidated) {
      if (!mergedMessagesConslidated[locale]) {
        mergedMessagesConslidated[locale] = appMessagesConsolidated[locale];
      }
    }

    return mergedMessagesConslidated;
  }, [msgs, parentAppMessages]);

  const messagesWithTimeZones = useMemo(() => mergeTimeZones(mergedAppMessages), [mergedAppMessages]);
  const mergedMessages = useMergeMessages(messagesWithTimeZones);

  const initialUserLocale = useMemo(() => {
    const supportedCachedLanguageId =
      (cachedLanguageId && find(supportedLanguages, l => l.toLowerCase() === cachedLanguageId.toLowerCase())) || null;

    const supportedBrowserLanguageId =
      find(supportedLanguages, l => l.toLowerCase() === browserLanguageId.toLowerCase()) || DEFAULT_LANGUAGE;

    return initialLocale || supportedCachedLanguageId || supportedBrowserLanguageId;
  }, []);

  const [userLocale, _setUserLocale] = useState(initialUserLocale);

  const [consolidatedMessages, setConsolidatedMessages] = useState(
    consolidateMessages({ messages: mergedMessages })[userLocale]
  );

  const setUserLocale = useCallback((locale, persist = true) => {
    if (locale && persist) {
      localStorage.setItem('language', locale);
    }
    _setUserLocale(locale);
  }, []);

  useEffect(() => {
    try {
      setConsolidatedMessages(consolidateMessages({ messages: mergedMessages })[userLocale]);
      if (!isIframe) {
        sendMessage({ language: userLocale });
      }
    } catch (e) {
      console.log('ue', e.message);
    }
  }, [isIframe, mergedMessages, sendMessage, userLocale]);

  const resetLanguageDefaults = useCallback(() => {
    setUserLocale(DEFAULT_LANGUAGE);
    setSupportedLanguages(DEFAULT_LANGUAGES);
  }, [setUserLocale, setSupportedLanguages]);

  return (
    <LocalizationContext.Provider
      value={{
        userLocale: userLocale || DEFAULT_LANGUAGE,
        setUserLocale,
        editMode,
        setEditMode,
        timeZone,
        setTimeZone,
        supportedLanguages,
        setSupportedLanguages,
        supportedDefaultLanguages: DEFAULT_LANGUAGES,
        resetLanguageDefaults,
        messages: mergedMessages,
      }}
    >
      <IntlProvider locale={userLocale || DEFAULT_LANGUAGE} messages={consolidatedMessages}>
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
};
