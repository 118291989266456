import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { CollapsibleSidebar } from './CollapsibleSidebar';
import { FixedSidebar } from './FixedSidebar';
import style from './style';

const Styled = styled.div`
  ${style}
`;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Option 1', '1', <PieChartOutlined />),
  getItem('Option 2', '2', <DesktopOutlined />),
  getItem('User', 'sub1', <UserOutlined />, [getItem('Tom', '3'), getItem('Bill', '4'), getItem('Alex', '5')]),
  getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  getItem('Files', '9', <FileOutlined />),
];

export const Sidebar = ({ fixed, children, open }) => {
  const [isOpen, setIsOpen] = React.useState(!!open);

  const toggle = React.useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  const SidebarComponent = React.useMemo(() => {
    return fixed ? FixedSidebar : CollapsibleSidebar;
  }, [fixed]);

  return (
    <Styled className={cn('cx-sidebar')}>
      <SidebarComponent isOpen={isOpen} onTrigger={toggle} />
      <div className="content">{children}</div>
    </Styled>
  );
};
