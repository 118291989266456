import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const DayPeriod = ({ value, isDayPeriod, onChange, testId }) => {
  if (!isDayPeriod) return null;

  const handleAmClick = React.useCallback(() => {
    onChange('AM');
  }, [onChange]);

  const handlePmClick = React.useCallback(() => {
    onChange('PM');
  }, [onChange]);

  return (
    <Styled className={cn('day-period')}>
      <div className="frame" />
      <div className={cn('selector', { am: value === 'AM' })}>
        <div
          data-testId={`${testId}-dayPeriod-am-button`}
          onClick={handleAmClick}
          className={cn('ampm', { active: value === 'AM' })}
        >
          AM
        </div>
        <div
          data-testId={`${testId}-dayPeriod-pm-button`}
          onClick={handlePmClick}
          className={cn('ampm', { active: value === 'PM' })}
        >
          PM
        </div>
      </div>
    </Styled>
  );
};
