import React from 'react';

export const UserContext = React.createContext();

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext cannot be rendered outside of the UserContext context provider`);
  }
  return context;
};
