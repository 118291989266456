import React from 'react';
import { initializeValue, useFormContext } from '../context-provider/FormProvider';
import { ArrayContextProvider, useArrayContext } from './context/ArrayContext';
import { ArrayFormProvider } from './form/ArrayFormProvider';

export const MODES = {
  EDIT: 'edit',
  CREATE: 'create',
  VIEW: 'view',
};

export const DEFAULT_MODE = MODES.CREATE;

const ModeMonitor = ({ children }) => {
  const { mode, fieldConfig } = useArrayContext();
  const { resetForm } = useFormContext();

  React.useEffect(() => {
    if (!mode) {
      resetForm({ values: initializeValue({ config: fieldConfig?.arrayOf }) });
    }
  }, [fieldConfig?.arrayOf, mode, resetForm]);

  return children;
};

export const Array = React.forwardRef((_props, ref) => {
  const { children, onSave, isRequired, ...props } = _props;
  const [mode, setMode] = React.useState(DEFAULT_MODE);

  const resetMode = React.useCallback(() => {
    setMode(DEFAULT_MODE);
  }, []);

  return (
    <ArrayContextProvider value={{ mode, setMode, ...props }}>
      <ArrayFormProvider onSubmit={onSave} resetMode={resetMode} isRequired={isRequired} mode={mode} setMode={setMode}>
        <ModeMonitor>{children}</ModeMonitor>
      </ArrayFormProvider>
    </ArrayContextProvider>
  );
});
