import React, { useCallback, useEffect, useState } from 'react';
import { Core, Localization, useNotification } from 'connex-cds';
import { useNavigate } from 'react-router-dom';
import { Divider, Result, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import style from './style';
import {
  useGetOrderManagement,
  useSaveDispatchSystem,
  useSaveOrderManagement,
  useSaveHideBatchWeights,
} from '../../../query-hooks/setup';
import { useCompanySetup } from '../../../query-hooks/setup';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

export const CompanySetup = () => {
  const Styled = styled.div`
    ${style}
  `;

  const navigate = useNavigate();
  const [isEnable, setIsEnable] = useState(false);
  const [hideBatchWeights, setHideBatchWeights] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isError, data } = useGetOrderManagement();
  const saveOrderManagement = useSaveOrderManagement();
  const saveDispatchSystem = useSaveDispatchSystem();
  const saveHideBatchWeights = useSaveHideBatchWeights();
  const companySetup = useCompanySetup();
  const notify = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const [dispatchSystem, setDispatchSystem] = useState('');
  const [enableToggleBtn, setEnableToggleBtn] = useState(false);

  const dispatchSystemOptions = [
    {
      name: 'Apex',
      id: 'Apex',
    },
    {
      name: 'Dispatch 1.0',
      id: 'Dispatch',
    },
    {
      name: 'Dispatch 2.0',
      id: 'Dispatch 2.0',
    },
    {
      name: 'COMMANDseries',
      id: 'COMMANDseries',
    },
    {
      name: 'COMMANDoptimize',
      id: 'COMMANDoptimize',
    },
    {
      name: 'Integra',
      id: 'Integra',
    },
  ];

  useEffect(() => {
    if (data) {
      data?.dispatchSystems ? setDispatchSystem(data?.dispatchSystems[0]) : setDispatchSystem('');
      data?.orderManagement ? setIsEnable(data?.orderManagement) : setIsEnable();
      data?.hideBatchWeights ? setHideBatchWeights(data?.hideBatchWeights) : setHideBatchWeights();
      setIsLoading(false);
    }
  }, [data, isError]);

  useEffect(() => {
    if (
      dispatchSystem &&
      (dispatchSystem === 'COMMANDseries' || dispatchSystem === 'Dispatch' || dispatchSystem === 'COMMANDoptimize')
    ) {
      setEnableToggleBtn(true);
    } else {
      setEnableToggleBtn(false);
    }
  }, [dispatchSystem]);

  const handleChange = useCallback(
    async event => {
      setDispatchSystem(event.target.value);
      setIsLoading(true);
      const dispSystemsArray = [];
      dispSystemsArray.push(event.target.value);
      const savedDispatchSystem = await saveDispatchSystem(dispSystemsArray);

      if (savedDispatchSystem && savedDispatchSystem?.success) {
        setIsLoading(false);
        notify.success(translateMessage('orderManagementSavedSuccess'));
      } else {
        notify.error(translateMessage('orderManagementSaveError'));
      }
    },
    [notify, saveDispatchSystem, translateMessage]
  );

  const handleEnableOrderManagementSettings = useCallback(
    async event => {
      setIsEnable(event.target.checked);
      setIsLoading(true);
      const savedOrderMngmt = await saveOrderManagement(event.target.checked);

      if (savedOrderMngmt && savedOrderMngmt?.success) {
        setIsLoading(false);
        notify.success(translateMessage('orderManagementSavedSuccess'));
      } else {
        notify.error(translateMessage('orderManagementSaveError'));
      }
    },
    [notify, saveOrderManagement, translateMessage]
  );

  const handleHideBatchWeightsSettings = useCallback(
    async event => {
      setHideBatchWeights(event.target.checked);
      setIsLoading(true);
      const savedHideBatchWeights = await saveHideBatchWeights(event.target.checked);
      setIsLoading(false);

      if (savedHideBatchWeights && savedHideBatchWeights?.success) {
        notify.success(translateMessage('hideBatchWeightsSaveSuccess'));
      } else {
        notify.error(translateMessage('hideBatchWeightsSaveError'));
      }
    },
    [notify, saveHideBatchWeights, translateMessage]
  );

  const handleRunCompanySetup = useCallback(async () => {
    setIsLoading(true);
    const resp = await companySetup.refetch();

    if (resp.status === 'success') {
      setIsLoading(false);
      notify.success(translateMessage('orderManagementSavedSuccess'));
    } else {
      notify.error(translateMessage('orderManagementSaveError'));
    }
  }, [companySetup, notify, translateMessage]);

  const goBack = React.useCallback(() => {
    navigate('..');
  }, [navigate]);

  return (
    <>
      <Core.Spinner spin={isLoading}></Core.Spinner>
      {!isLoading && !isError && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Core.Button size="small" type="primary" stringId="back" data-testid="back-button" onClick={goBack} />
          <Divider orientation="left">
            <FormattedMessage id={'companySetup'} defaultMessage={'Company Setup'} />
          </Divider>

          <Box sx={{ maxWidth: '20%' }}>
            <FormControl fullWidth>
              <InputLabel id="dispatch-system">
                <FormattedMessage id={'dispatchSolution'} defaultMessage={'dispatchSolution'} />
              </InputLabel>
              <Select
                labelId="dispatch-system"
                id="dispatch-system-select"
                value={dispatchSystem}
                label={<FormattedMessage id={'dispatchSolution'} defaultMessage={'dispatchSolution'} />}
                onChange={handleChange}
              >
                {dispatchSystemOptions.map(dispatchOption => {
                  return (
                    <MenuItem key={dispatchOption.id} value={dispatchOption.id}>
                      {dispatchOption.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Styled style={{ margin: '30px auto' }}>
            <div className="layout-grid">
              {/* Enable order management toggle button */}
              {dispatchSystem && enableToggleBtn && (
                <>
                  <Card className="company-setup-card" hoverable="true">
                    <CardHeader
                      className="setup-card-header"
                      title={<FormattedMessage id={'companySetupToggleTitle'} defaultMessage={'Description'} />}
                    />
                    <CardContent className="setup-card-content">
                      <Stack direction="row" spacing={1} alignItems="center" style={{ display: 'inline-flex' }}>
                        <Typography>
                          <FormattedMessage id={'disabledLabel'} defaultMessage={'disabledLabel'} />
                        </Typography>
                        <Switch
                          className="setup-toggle-button"
                          onChange={handleEnableOrderManagementSettings}
                          checked={isEnable}
                        />
                        <Typography>
                          <FormattedMessage id={'enabledLabel'} defaultMessage={'enabledLabel'} />
                        </Typography>
                      </Stack>
                      <Typography variant="body2" marginTop="20px">
                        <FormattedMessage id={'companySetupToggleDescr'} defaultMessage={'Description'} />
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card className="company-setup-card" hoverable="true">
                    <CardHeader
                      className="setup-card-header"
                      title={
                        <FormattedMessage
                          id={'companySetupToggleTitleHideBatchWeights'}
                          defaultMessage={'Description'}
                        />
                      }
                    />
                    <CardContent className="setup-card-content">
                      <Stack direction="row" spacing={1} alignItems="center" style={{ display: 'inline-flex' }}>
                        <Typography>
                          <FormattedMessage id={'showBatchWeightsLabel'} defaultMessage={'disabledLabel'} />
                        </Typography>
                        <Switch
                          className="setup-toggle-button"
                          onChange={handleHideBatchWeightsSettings}
                          checked={hideBatchWeights}
                        />
                        <Typography>
                          <FormattedMessage id={'hideBatchWeightsLabel'} defaultMessage={'enabledLabel'} />
                        </Typography>
                      </Stack>
                      <Typography variant="body2" marginTop="20px">
                        <FormattedMessage
                          id={'companySetupToggleDescrHideBatchWeights'}
                          defaultMessage={'Description'}
                        />
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              )}

              {/* Run setup buton */}
              <Card className="company-setup-card" hoverable="true">
                <CardHeader
                  className="setup-card-header"
                  title={<FormattedMessage id={'companySetupInit'} defaultMessage={'companySetupInit'} />}
                />
                <CardContent className="setup-card-content">
                  <Typography variant="body2" marginBottom="20px">
                    <FormattedMessage id={'companySetupDesc'} defaultMessage={'Description'} />
                  </Typography>
                  <Button variant="contained" onClick={handleRunCompanySetup} style={{ width: '40%' }}>
                    <FormattedMessage id={'runSetup'} defaultMessage={'Description'} />
                  </Button>
                </CardContent>
              </Card>
            </div>
          </Styled>
        </Space>
      )}

      {isError && (
        <Result
          status="error"
          title={<FormattedMessage id={'error'} defaultMessage={'Error'} />}
          subTitle={<FormattedMessage id={'compSetupErrorMessage'} defaultMessage={'Error has occurred'} />}
        />
      )}

      {companySetup.data && companySetup.data.error && (
        <Result
          status="error"
          title={<FormattedMessage id={'error'} defaultMessage={'Error'} />}
          subTitle={<FormattedMessage id={'compSetupErrorMessage'} defaultMessage={'Error has occurred'} />}
        />
      )}
    </>
  );
};
