// Spanish
export default {
  newVersionAvailable: 'Nueva versión disponible',
  versionInfo:
    'Una nueva versión de {appName} está disponible. Por favor, recargue la página para actualizar a la versión {version}.',
  reload: 'Recargar',
  later: 'Más tarde',
  intl_addr_country: 'País',
  intl_addr_address1: 'Dirección',
  intl_addr_address2: 'Dirección 2',
  intl_addr_address3: 'Dirección 3',
  intl_addr_address4: 'Dirección 4',
  intl_addr_city: 'Ciudad',
  intl_addr_state: 'Estado',
  intl_addr_zipCode: 'Código postal',
  intl_addr_longitude: 'Longitud',
  intl_addr_latitude: 'Latitud',
  intl_addr_department: 'Departamento',
  intl_addr_suburb: 'Suburbio',
  intl_addr_postalCode: 'Código postal',
  intl_addr_province: 'Provincia',
  intl_addr_neighborhood: 'Barrio',
};
