import { css } from 'styled-components';

export default css`
  .ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: var(--color-background-dropdown);
      .ant-picker-panel {
        border: 1px solid var(--color-border-dropdown);

        .ant-picker-time-panel {
          .ant-picker-time-panel-column {
            &:not(:first-child) {
              border-left: 1px solid var(--color-border-dropdown);
            }

            & > li {
              &.ant-picker-time-panel-cell {
                .ant-picker-time-panel-cell-inner {
                  color: var(--color-text-dropdown);

                  &:hover {
                    background-color: var(--color-background-dropdown-hover);
                    color: var(--color-text-dropdown-hover);
                  }
                }

                &.ant-picker-time-panel-cell-selected {
                  .ant-picker-time-panel-cell-inner {
                    color: var(--color-text-dropdown-selected);
                    background-color: var(--color-background-dropdown-selected);
                  }
                }
              }
            }
          }
        }
        .ant-picker-footer {
          border-top: 1px solid var(--color-border-dropdown);
        }
      }
    }
  }
`;
