import { startCase } from 'lodash';

export const addOptionLabels = optionsList => {
  if (optionsList) {
    const optionsWithLabels = optionsList?.map(role => {
      if (!role.label) {
        role.label = startCase(role.name);
      }
      role.roleRef = role.crn;
      return role;
    });

    return optionsWithLabels;
  }
};
