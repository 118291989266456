import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'cancel-reasons',
  name: 'Cancel Reason ',
  labelStringId: 'cancelReasons',
  testId: 'cancelReasons',
  keyPath: 'name',
  validationSchema: Yup.object().shape({
    name: Yup.string(),
    publicName: Yup.string(),
    visibility: Yup.string(),
    status: Yup.string(),
    type: Yup.string(),
  }),

  fields: [
    {
      path: 'name',
      labelStringId: 'name',
      testId: 'name',
      dataType: Form.FIELD_TYPES.string,
    },
    {
      path: 'publicName',
      labelStringId: 'publicName',
      testId: 'publicName',
      dataType: Form.FIELD_TYPES.string,
    },
    {
      path: 'visibility',
      dataType: Form.FIELD_TYPES.string,
      listValues: [
        { id: 'internal', label: 'Internal' },
        { id: 'public', label: 'Public' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      labelStringId: 'visibility',
      testId: 'visibility',
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: Form.FIELD_TYPES.string,
    },
    {
      path: 'type',
      labelStringId: 'type',
      testId: 'type',
      dataType: Form.FIELD_TYPES.string,
    },
  ],
};
