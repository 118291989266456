import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Customers } from '../api';

export const useListCustomers = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['customers', entityRef],
    queryFn: () => Customers.listCustomers({ entityRef }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};
