import React from 'react';
import { useIntl } from 'react-intl';
import { useGetTimeZone } from './date-time';
import { useLocalizationContext } from './LocalizationProvider';
import toDateTime from './util/toDateTime';

export const useFormat = () => {
  const intl = useIntl();
  const { timeZone } = useLocalizationContext();

  const dateOptions = React.useMemo(
    () => ({
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'short',
      timeZone,
    }),
    [timeZone]
  );

  const timeOptions = React.useMemo(
    () => ({
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
    }),
    [timeZone]
  );
  const getTimeZone = useGetTimeZone();

  const formatLongDateTime = React.useCallback(
    (isoDateString, timeZoneOverride) => {
      try {
        const dateTime = toDateTime(isoDateString, timeZoneOverride || timeZone);

        const date = intl.formatDate(dateTime.toJSDate(), dateOptions);
        const time = intl.formatDate(dateTime.toJSDate(), timeOptions);

        const tz = getTimeZone({ date: dateTime });

        return `${date} ${time} (${tz})`;
      } catch (e) {
        console.error(e);
        return '';
      }
    },
    [dateOptions, getTimeZone, intl, timeOptions, timeZone]
  );

  return {
    formatLongDateTime,
  };
};
