import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  &.spinner {
    text-align: center;
    margin-top: 10%;
    .fas {
      font-size: 3rem;
      opacity: 0.3;
    }
  }
`;

export const Spinner = ({ className, spin, children, text }) => {
  return spin ? (
    <Styled className={cn('spinner', className)} data-testid="busySpinner">
      <i className="fas fa-spin fa-circle-notch " />
      {text ? <div className="text">{text}</div> : null}
    </Styled>
  ) : (
    children
  );
};
