import React, { useContext } from 'react';

export const EntityContext = React.createContext();

export const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error(`useEntityContext cannot be rendered outside of the EntityContext context provider`);
  }
  return context;
};
