import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 75px;


  &.isOpen {
    .dynamic-menu-item {
      width: 275px;
      .icon {
        font-size: 24px;
      }
    }
  }

  .dynamic-menu-item {
    transition: background-color 0.3s, color 0.3s, width 0.3s;
    cursor: pointer;
    width: 74px;
    height: 74px;
    border-radius: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    color: var(--sidebar-menu-icon-color);
    overflow: hidden;
    .icon {
      font-size: 35px;
      transition: font-size 0.3s;
    }
    .label {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }
    &.active {
      background-color: var(--sidebar-menu-icon-background-color-active);
      box-shadow: var(--sidebar-menu-icon-background-color-active-box-shadow);
      color: var(--sidebar-menu-icon-color-active);
    }
    &:hover {
      background-color: var(--sidebar-menu-icon-background-color-hover);
      color: var(--sidebar-menu-icon-color-hover);
    }
  }
`;
