import React from 'react';
import { FormattedNumber } from 'react-intl';

export const Counter = ({ text, maxLength = 0, ...props }) => {
  if (maxLength < 1) return null;

  return (
    <span {...props}>
      <FormattedNumber value={text?.length || 0} /> / <FormattedNumber value={maxLength} />
    </span>
  );
};
