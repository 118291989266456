const aqua = {
  10: '#1B5D58',
  20: '#208080',
  30: '#229497',
  40: '#24A9AF',
  50: '#26B9C1',
  60: '#3AC4C7',
  70: '#57CECF',
  80: '#84DDDC',
  90: '#B3EAE9',
  95: '#E0F7F6',
  100: '#FFFFFF',
};

export default aqua;
