// Danish
export default {
  newVersionAvailable: 'Ny version tilgængelig',
  versionInfo: 'En ny version af {appName} er tilgængelig. Genindlæs venligst siden for at opdatere til version {version}.',
  reload: 'Genindlæs',
  later: 'Senere',
  intl_addr_country: 'Land',
  intl_addr_address1: 'Adresse',
  intl_addr_address2: 'Adresse 2',
  intl_addr_address3: 'Adresse 3',
  intl_addr_address4: 'Adresse 4',
  intl_addr_city: 'By',
  intl_addr_state: 'Stat',
  intl_addr_zipCode: 'Postnummer',
  intl_addr_longitude: 'Længdegrad',
  intl_addr_latitude: 'Breddegrad',
  intl_addr_department: 'Afdeling',
  intl_addr_suburb: 'Forstad',
  intl_addr_postalCode: 'Postnummer',
  intl_addr_province: 'Provins',
}
