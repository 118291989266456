import { Button, Space, Layout, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
const { Content } = Layout;

const WebhookDataModal = ({ data, onCancel }) => {
  return (
    <>
      <Content>
        <code style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} language="JSON">
          {JSON.stringify(data, null, '  ')}
        </code>
      </Content>
      <Divider></Divider>
      <Space>
        <Button key="close" type="primary" onClick={() => onCancel()}>
          <FormattedMessage id={'subscriptionModalCloseBtn'} defaultMessage={'Close'} />
        </Button>
      </Space>
    </>
  );
};

export default WebhookDataModal;
