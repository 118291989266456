import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SpinnerContainerStyled = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #605d62;
    font-size: 24px;
    font-weight: 600;

    .new-spinner {
      height: 222px;
    }
  `}
`;
