import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/cai_logo_2c_white.svg';
import { Translate } from '../../components/localization';
import { useAppData } from '../../util/app-data/AppData';
import { DynamicMenu } from './dynamic-menu/DynamicMenu';

const Styled = styled.div`
  .svg-logo {
    img {
      position: absolute;
      top: 20px;
      left: 30px;
      height: 30px;
    }
  }
`;

export const CollapsibleSidebar = ({ isOpen, onTrigger }) => {
  const handleToggle = React.useCallback(() => {
    onTrigger();
  }, [onTrigger]);

  const appData = useAppData();

  return (
    <Styled className={cn('sidebar collapsible-sidebar', { visible: !!appData?.appId })}>
      <div className="outer">
        <div className="inner">
          <div className="logo-title">
            <div className="svg-logo">
              <img src={logo} />
            </div>
            <div className="title">
              <div className="title-inner">
                <Translate stringId={appData?.appId} data-testid="app-name" />
              </div>
            </div>
          </div>

          <div className="dynamic-menu-section">
            <DynamicMenu isOpen={isOpen} />
          </div>
        </div>
        <div className="toggle" onClick={handleToggle} data-testid="sidebar-toggle">
          <i className="icon fa-solid fa-chevron-left" />
        </div>
      </div>
    </Styled>
  );
};
