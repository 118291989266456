import cn from 'classnames';
import { find, isString } from 'lodash';
import React from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { Translate } from '../../../components/localization';
import { useAppContext } from '../../../util/app-provider/context';
import { useEntityContext } from '../../../util/entity-provider/context';
import { isProduction } from '../../../util/isProduction';
import { DynamicMenuItem } from './old/DynamicMenuItem';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const useConfig = () => {
  return React.useCallback(({ config, entityRef, enabled }) => {
    if (!config) {
      return undefined;
    }
    return {
      ...config,
      icon: isString(config?.icon) ? <i className={config.icon || ''} /> : config.icon || undefined,
      label: <Translate stringId={config.labelStringId} data-testid={`menu-item-${config.id}`} />,
      disabled: enabled && !entityRef,
    };
  }, []);
};

export const DynamicMenu = ({ isOpen }) => {
  const { entityRef, enabled } = useEntityContext();
  const { menuConfig, currentFeature, setCurrentFeature } = useAppContext();

  const getConfig = useConfig();

  const preparedConfig = React.useMemo(() => {
    if (!menuConfig) {
      return [];
    }
    const isProd = isProduction();

    return menuConfig
      ?.map?.(configItem => getConfig({ config: configItem, entityRef, enabled }))
      ?.filter?.(configItem => (isProd ? !configItem.hideInProduction : true));
  }, [menuConfig, enabled, entityRef, getConfig]);

  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuItemClick = React.useCallback(
    id => {
      const targetItem = find(menuConfig, { id });

      setCurrentFeature(targetItem);

      const destination = `${entityRef ? `/${entityRef}` : ''}${targetItem.path}`;
      if (destination !== location.pathname) {
        navigate(destination);
      }
    },
    [menuConfig, setCurrentFeature, entityRef, location.pathname, navigate]
  );

  const { appConfig } = useAppContext();

  React.useEffect(() => {
    const matched = matchPath(
      { path: `${appConfig?.isEntitySpecific ? '/:entityRef' : ''}/:menuConfigId/*` },
      location.pathname
    );

    const targetId = matched?.params?.menuConfigId;

    if (targetId) {
      const targetItem = find(menuConfig, { id: targetId });
      setCurrentFeature(targetItem);
    } else {
      setCurrentFeature(undefined);
    }
  }, [menuConfig, location, location.pathname, appConfig, setCurrentFeature]);

  return preparedConfig ? (
    <Styled className={cn('dynamic-menu-items', { isOpen })}>
      {preparedConfig.map(item => (
        <DynamicMenuItem
          item={item}
          onClick={handleMenuItemClick}
          className={cn({ active: currentFeature?.id === item.id })}
        />
      ))}
    </Styled>
  ) : null;
};
