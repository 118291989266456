/**
 *
 * @type {{font_weight: string, color_success_400: string, color_success_500: string, color_action_500: string, color_success_100: string, color_success_200: string, font_size: string, color_success_300: string, color_action_100: string, color_action_200: string, color_action_300: string, color_action_400: string, color_error_200: string, color_1: string, color_error_100: string, color_2: string, font_weight_bold: string, color_text: string, radius_large: string, font_size_large: string, font_size_small: string, color_text_inverted: string, radius_small: string, font_weight_light: string, color_highlight_100: string, color_gray_400: string, color_error_500: string, color_gray_500: string, color_error_400: string, color_gray_600: string, color_brand_secondary: string, color_error_300: string, color_gray_700: string, color_gray_100: string, color_gray_200: string, color_gray_300: string, color_warning_100: string, color_warning_200: string, color_transparent: string, color_warning_400: string, color_gray_800: string, color_brand_primary: string, color_warning_300: string, color_gray_900: string, color_warning_500: string}}
 */
import { common } from './common';

export default {
  ...common,

  cx_gray_100: '#ffffff',
  cx_gray_500: '#FAFAFA',
  cx_gray_bf: '#bfbfbf',
  cx_gray_60: '#605d62',
  cx_gray_cc: '#CCCCCC',
  cx_ff4f02: '#ff4f02',

  cx_black: '#8B8B8B',
  cx_special_text: '#002b54',

  color_brand_primary: '#DF4E39',
  color_brand_secondary: '#C4C4C4',

  color_highlight_100: '#bcdaf5',

  color_action_100: '#bcdaf5',
  color_action_200: '#83baec',
  color_action_300: '#489ae3',
  color_action_400: '#1677d1',
  color_action_500: '#115a9e',
  color_success_100: '#b1e39e',
  color_success_200: '#6eca49',
  color_success_300: '#4ca827',
  color_success_400: '#3c861f',
  color_success_500: '#2e6618',
  color_warning_100: '#fcd072',
  color_warning_200: '#f2a600',
  color_warning_300: '#c78900',
  color_warning_400: '#9e6c00',
  color_warning_500: '#775200',
  color_error_100: '#facacb',
  color_error_200: '#f69da0',
  color_error_300: '#f1696d',
  color_error_400: '#e81a20',
  color_error_500: '#b11418',
  color_gray_100: '#FDFDFD',
  color_gray_200: '#F2F2F2',
  color_gray_300: '#E8E8E8',
  color_gray_400: '#D6D6D6',
  color_gray_500: '#B5B5B5',
  color_gray_600: '#767676',
  color_gray_700: '#4C4C4C',
  color_gray_800: '#3D3D3D',
  color_gray_900: '#282828',

  color_text: '#605d62',
  color_text_inverted: '#F3F3F3',
  color_transparent: 'transparent',

  radius_small: '4px',
  radius_large: '8px',
};
