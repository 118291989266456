// German
export default {
  newVersionAvailable: 'Neue Version verfügbar',
  versionInfo: 'Eine neue Version von {appName} ist verfügbar. Bitte laden Sie die Seite neu, um auf Version {version} zu aktualisieren.',
  reload: 'Neu laden',
  later: 'Später',
  intl_addr_country: 'Land',
  intl_addr_address1: 'Adresse',
  intl_addr_address2: 'Adresse 2',
  intl_addr_address3: 'Adresse 3',
  intl_addr_address4: 'Adresse 4',
  intl_addr_city: 'BStadt',
  intl_addr_state: 'Bundesland',
  intl_addr_zipCode: 'Postleitzahl',
  intl_addr_longitude: 'Längengrad',
  intl_addr_latitude: 'Breitengrad',
  intl_addr_department: 'Abteilung',
  intl_addr_suburb: 'Vorort',
  intl_addr_postalCode: 'Postleitzahl',
  intl_addr_province: 'Provinz',
  intl_addr_neighborhood: 'Nachbarschaft',
};
