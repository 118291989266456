import React from 'react';
import { set, kebabCase } from 'lodash';
import { Tabs } from 'antd';
import { camelCase, toUpper } from 'lodash';
import getFieldComponents from './getFieldComponents';

// TODO move up / reuse
const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

const { TabPane } = Tabs;

export default (config, setFieldValue) => {
  const { groups } = config;

  const components = groups.reduce((acc, group) => {
    const fieldComponents = getFieldComponents({
      config: { validationSchema: config.validationSchema, ...group },
      setFieldValue,
    });

    const GroupComponent = ({ children, ...props }) => {
      return (
        <TabPane forceRender {...props}>
          {children}
        </TabPane>
      );
    };

    Object.keys(fieldComponents).forEach(key => {
      set(GroupComponent, ['Components', key], fieldComponents[key]);
    });

    GroupComponent.__key = group.groupId;
    GroupComponent.__tab = <span data-testid={`tab-${group.groupId}`}>{group.groupName}</span>;

    acc[pascalCase(group.groupId)] = GroupComponent;

    return acc;
  }, {});

  return components;
};
