import { find, omit } from 'lodash';

const prepareValues = ({ options, fieldConfig, value }) => {
  const selectedOption = find(options, { [fieldConfig?.listValuePath]: value });

  const transformedSelection = fieldConfig?.valuePath
    ? {
        ...omit(selectedOption, fieldConfig?.listValuePath),
        [fieldConfig?.valuePath]: value,
      }
    : value;

  const customValue = fieldConfig?.onChange?.(transformedSelection);

  return customValue || transformedSelection;
};

export const handleMultiSelectChange = ({ value, options, fieldConfig, setFieldValue }) => {
  const values = value?.map?.(val => prepareValues({ options, fieldConfig, value: val }));
  setFieldValue(fieldConfig.path, values);
};

export const handleStandardSelectChange = ({ value, options, fieldConfig, setFieldValue }) => {
  const values = prepareValues({ options, fieldConfig, value });
  setFieldValue(fieldConfig.path, values);
};
