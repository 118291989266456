import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../localization';
import { Helper } from '../../../../typography';
import { Switch } from '../../../switch/Switch';
import { useGridContext } from '../../context/context';

const Styled = styled.div`
  display: flex;
  align-items: center;
`;
const FilterSwitch = props => {
  const { filterable, filterEnabled, setFilterEnabled } = useGridContext();

  return (
    <Styled className={cn('filter-switch')} {...props}>
      {filterable && (
        <span>
          <Switch size="small" checked={filterEnabled} onChange={setFilterEnabled} data-testid="filter-toggle" />{' '}
          <Helper>
            <Translate stringId="columnFilters" data-testid="filter-toggle-label" />
          </Helper>
        </span>
      )}
    </Styled>
  );
};

export default FilterSwitch;
