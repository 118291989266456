import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  .label {
    margin-bottom: 8px;
  }
  .controls {
    display: flex;
    flex-direction: column;
    .ant-modal {
      top: 50px;
    }
  }
`;
