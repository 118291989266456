import React from 'react';
import { useIntl } from 'react-intl';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';
import { ShortDate } from './ShortDate';

const longDate = ({ date, formatDate, timeZone, withDayOfWeek = false }) => {
  if (!date) return '';

  const dateTime = toDateTime(date, timeZone);

  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone,
    weekday: withDayOfWeek ? 'long' : undefined,
  };
  return formatDate(dateTime, options);
};

export const LongDate = ({ date, withDayOfWeek = false }) => {
  const { formatDate } = useIntl();
  const { timeZone } = useLocalizationContext();

  return longDate({ date, formatDate, timeZone, withDayOfWeek });
};

export const useLongDate = () => {
  const { formatDate } = useIntl();
  const { timeZone } = useLocalizationContext();

  const getLongDate = React.useCallback(
    (date, withDayOfWeek = false) => {
      return longDate({ date, formatDate, timeZone, withDayOfWeek });
    },
    [formatDate, timeZone]
  );

  return { getLongDate, ShortDate };
};
