import { find, pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Container } from '../../layout';

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .available-options {
    display: flex;
    gap: 10px;
    select {
      width: 200px;
      height: 28px;
    }
    button: {
      height: 28px;
    }
  }
  .selected-items {
    width: 250px;
    height: 300px;
    border: 1px solid #aaa;
    border-radius: 4px;
    .selected-item {
      cursor: pointer;
      display: flex;
      padding: 0 5px;
      &.active {
        font-weight: bold;
      }
      .label {
        flex: 1;
      }
    }
  }
  .container {
    padding: 10px;
    gap: 10px;
  }
`;
export const Selector = ({ config, options, onSelectedItemChange, onChange, selectedItems = [] }) => {
  const [selectedOptionId, setSelectedOptionId] = React.useState('');
  const [activeItemId, setActiveItemId] = React.useState();

  React.useEffect(() => {
    setActiveItemId(undefined);
  }, [options]);

  React.useEffect(() => {
    if (!activeItemId && selectedItems?.length) {
      setActiveItemId(selectedItems?.[0]?.id);
    }
  }, [activeItemId, selectedItems]);

  React.useEffect(() => {
    const target = find(options, { id: activeItemId });
    onSelectedItemChange?.({ option: target });
  }, [activeItemId, config, onSelectedItemChange, options]);

  const filteredOptions = React.useMemo(() => {
    const selectedIds = selectedItems?.map?.(selectedItem => selectedItem.id);
    return options?.filter?.(option => {
      return !selectedIds?.includes?.(option.id);
    });
  }, [options, selectedItems]);

  const handleOptionChange = React.useCallback(e => {
    setSelectedOptionId(e.target.value || '');
  }, []);

  const handleAddClick = React.useCallback(() => {
    const target = find(options, { id: selectedOptionId });

    const newSelectedItem = pick(target, ['id', 'name']);
    const newSelectedItems = [...selectedItems, newSelectedItem];

    onChange(newSelectedItems);
    setActiveItemId(selectedOptionId);
    setSelectedOptionId('');
  }, [onChange, options, selectedItems, selectedOptionId]);

  const handleDeleteClick = React.useCallback(
    itemId => {
      const newSelectedItems = selectedItems.filter(item => item.id !== itemId);

      onChange(newSelectedItems);
      setActiveItemId(undefined);
    },
    [onChange, selectedItems]
  );

  const handleSelectedItemClick = React.useCallback(id => {
    setActiveItemId(id);
  }, []);

  return (
    <Styled className={cn('selector')}>
      <Container>
        <div className="available-options">
          <select value={selectedOptionId} onChange={handleOptionChange} disabled={!options?.length}>
            <option value="">Select...</option>
            {filteredOptions?.map?.(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <button onClick={handleAddClick}>Add</button>
        </div>

        <div className="selected-items">
          {selectedItems?.map?.(item => {
            return (
              <div key={item.id} className={cn('selected-item', { active: item.id === activeItemId })}>
                <div className="label" onClick={() => handleSelectedItemClick(item.id)}>
                  {item.name}
                </div>
                {item.id === activeItemId ? (
                  <div className="delete-icon" onClick={() => handleDeleteClick(item.id)}>
                    <i className="fa-light fa-trash" />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </Container>
    </Styled>
  );
};
