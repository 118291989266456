import React from 'react';
import { useIntl } from 'react-intl';
import { useLocalizationContext } from '../LocalizationProvider';
import toDateTime from '../util/toDateTime';

const shortDate = ({ date, formatDate, timeZone, withDayOfWeek = false }) => {
  if (!date) return '';

  const dateTime = toDateTime(date, timeZone);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
    weekday: withDayOfWeek ? 'short' : undefined,
  };
  return formatDate(dateTime, options);
};

export const ShortDate = ({ date, withDayOfWeek = false }) => {
  const { formatDate } = useIntl();
  const { timeZone } = useLocalizationContext();

  return shortDate({ date, formatDate, timeZone, withDayOfWeek });
};

export const useShortDate = () => {
  const { formatDate } = useIntl();
  const { timeZone } = useLocalizationContext();

  const getShortDate = React.useCallback(
    (date, withDayOfWeek = false) => {
      return shortDate({ date, formatDate, timeZone, withDayOfWeek });
    },
    [formatDate, timeZone]
  );

  return { getShortDate, ShortDate };
};
