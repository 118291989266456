const cet = { standard: 'SET', dst: 'SEST' };

export default {
  Europe: {
    Amsterdam: cet,
    Berlin: cet,
    Brussels: cet,
    Copenhagen: cet,
    Oslo: cet,
    Paris: cet,
    Stockholm: cet,
  },
};
