import React from 'react';
import { Select } from '../../select/Select';
import countries from './countries';

const options = countries.map(option => {
  return {
    id: option.countryCode,
    label: option.countryName,
  };
});

export const Country = props => {
  return (
    <div className="country">
      <Select {...props} displayPath="countryName" valuePath="countryCode" options={options} />
    </div>
  );
};

export default Country;
