import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Reasons } from '../api';

export const useListCancelReasons = () => {
  const { entityRef } = useParams();

  const queryKey = ['getCancelReasons', entityRef];

  const query = useQuery({
    queryKey,
    queryFn: () => Reasons.getCancelReasons(entityRef),
    staleTime: Infinity,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

export const useUpdateCancelReason = values => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['updateCancelReason', entityRef];
  const cancelReasonsQueryKey = ['getCancelReasons', entityRef];

  const mutation = useMutation({
    mutationFn: cancelReason => {
      return Reasons.updateCancelReason({ cancelReason, entityRef });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ cancelReasonsQueryKey });
      }, 1000);
    },
  });

  return mutation.mutateAsync;
};
