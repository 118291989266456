import { padStart } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { NumberInput } from '../number-input/NumberInput';
import style from './style';

const Styled = styled.div`
  ${style}
`;
const MIN_HOUR = 0;
const MAX_HOUR = 23;

export const Hour = ({ hour, is24Hour, onChange, dayPeriod, testId }) => {
  const displayHour = React.useMemo(() => {
    let retVal;
    if (hour <= 12) {
      if (is24Hour) {
        retVal = hour;
      } else {
        retVal = hour === 0 ? 12 : hour;
      }
    } else {
      retVal = is24Hour ? hour : hour - 12;
    }

    return is24Hour ? padStart(retVal, 2, '0') : retVal;
  }, [hour, is24Hour]);

  const handleIncrement = React.useCallback(() => {
    const newHour = hour + 1;

    if (newHour > MAX_HOUR) {
      onChange(MIN_HOUR, { rolledMaximum: true });
    } else {
      onChange(newHour);
    }
  }, [hour, onChange]);

  const handleDecrement = React.useCallback(() => {
    const newHour = hour - 1;

    if (newHour < MIN_HOUR) {
      onChange(MAX_HOUR, { rolledMinimum: true });
    } else {
      onChange(newHour);
    }
  }, [hour, onChange]);

  const [isEmptyString, setIsEmptyString] = React.useState(false);

  const handleChange = React.useCallback(
    e => {
      const val = e.target.value;

      if (val === '') {
        setIsEmptyString(true);
        onChange('EMPTY');
        return;
      }

      const numberValue = Number(val);

      if (is24Hour && numberValue >= MIN_HOUR && numberValue <= MAX_HOUR) {
        onChange(numberValue);
      } else if (!is24Hour && numberValue >= 1 && numberValue <= 12) {
        let val24;
        if (dayPeriod === 'AM') {
          val24 = numberValue === 12 ? 0 : numberValue;
        } else {
          val24 = numberValue === 12 ? 12 : numberValue + 12;
        }
        onChange(val24);
      }
      setIsEmptyString(false);
    },
    [dayPeriod, is24Hour, onChange]
  );

  return (
    <Styled className={cn('time-part hour')}>
      <button data-testid={`${testId}-decrement-hour-button`} onClick={handleDecrement}>
        -
      </button>
      <div>
        <input
          data-testid={`${testId}-hour-input`}
          inputMode="numeric"
          value={isEmptyString ? '' : displayHour}
          onChange={handleChange}
        />
      </div>
      <button data-testid={`${testId}-increment-hour-button`} onClick={handleIncrement}>
        +
      </button>
    </Styled>
  );
};
